@import '../../../../../assets/styles/variable';

.split-slot-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .slot-duration {
    line-height: 1.3125rem;
    color: $text-color-1;
    font-weight: $weight-400;
    font-size: 0.875rem;
  }

  .split-slot-option-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .no-flex {
      flex: none;
    }
    .css-28olzy-singleValue {
      display: flex;
      justify-content: center;
    }
  }
}
