@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.booked-slots {
  flex: 1;
  position: relative;
  height: 574px;
  background: $white;
  padding: 1.5rem;
  box-sizing: border-box;
  box-shadow: $add-slot-card-box-shadow;
  border-radius: 0.5rem;

  &-title {
    font-family: $Montserrat;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tech-select {
      min-width: 150px;
      box-shadow: none;
      border: none;
    }
  }

  &-container {
    display: flex;
    background: $white;
    box-shadow: $slot-level-card-box-shadow;
    border-radius: 0.5rem;
    border: 1px solid $calendar-gray;
    min-height: max-content;
    max-height: none;
    margin: 1.5rem 0 0 0;
    height: calc(100% - 4.5rem);
    box-sizing: border-box;
    position: relative;

    .tab-container {
      gap: 0;
    }

    .request-flag {
      display: flex;
      position: absolute;
      top: 1.25rem;
      right: 0;
      gap: 0.5rem;
      align-items: center;
      padding: 0 1.5rem 1.5rem 1.5rem;

      .yellow-circle {
        background-color: $warning;
        width: 16px;
        height: 16px;
        border-radius: 50%;
      }
    }

    .no-slots-found {
      display: flex;
      height: calc(100% - 32px);
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 1.5rem;
      }
      .no-slot-message {
        color: $gray-500;
        text-align: center;
        font-weight: $weight-600;
        font-family: $Roboto;
      }
      .slot-btn {
        width: 160px;
        font-size: 1rem;
      }
    }

    .tab-header {
      padding: 1.5rem 1.5rem 0 1.5rem;
    }

    .tab-data {
      overflow-y: scroll;
      max-height: 42.5rem;
    }

    .slot-details {
      padding: 1rem;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      overflow-y: auto;
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .loading-spinner {
        width: 56px;
        height: 56px;
        animation: spinner 1.5s linear infinite;
        margin: auto;
      }
      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }

      .no-slots-available {
        display: flex;
        height: calc(100% - 32px);
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        img {
          margin-bottom: 1.5rem;
        }
        .no-slot-message {
          color: $gray-500;
          text-align: center;
          font-weight: $weight-600;
          font-family: $Roboto;
        }
      }
    }
    .levels {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}
