@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';

.tab-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;
  .no-border {
    border-bottom: 0 solid transparent !important;
  }
  .tab-header {
    display: flex;
    gap: 1rem;
    border-bottom: 0.0625rem solid $calendar-gray;
    justify-content: space-between;
    .tabs {
      display: flex;
      gap: 1rem;
      overflow-x: overlay;

      &:hover {
        &::-webkit-scrollbar {
          display: block;
        }
      }
      &::-webkit-scrollbar {
        width: 0.125rem;
        height: 0.125rem;
        display: none;
      }
    }
    .view-all {
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-700;
      font-size: 1rem;
      line-height: 1.5rem;
      color: $primary;
      cursor: pointer;
    }

    .tab-title {
      padding: 0 0 0.5rem 0;
      font-weight: $weight-600;
      line-height: 1.5rem;
      font-family: $Montserrat;
      font-size: 1rem;
      white-space: nowrap;
      color: $gray-500;

      &:hover {
        cursor: pointer;
      }
    }
    .selected {
      color: $primary;
      border-bottom: 0.125rem solid $primary;
    }
  }
  .tab-data {
    height: 100%;
    overflow-y: scroll;
    position: relative;
  }
}
@media screen and (max-width: $sm-max) {
  .tab-container {
    .tab-header {
      padding: 0.5rem 0.5rem 0 0.5rem !important;
    }
  }
}
