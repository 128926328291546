@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';

.slot-request-card {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  border: $tech-item-border;
  margin: 0 1.5rem;
  width: 33rem;
  .radio-label {
    img {
      height: 2.5rem;
      width: 2.5rem;
      border-radius: 50%;
    }
  }
  .title {
    color: $gray-500;
    padding: 1rem 0 0 1.5rem;
  }

  .slot-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21.375rem;
    border-radius: 0.5rem;
    z-index: 100;
    background-color: $white;

    .loading-spinner {
      width: 3.5rem;
      height: 3.5rem;
      animation: spinner 1.5s linear infinite;
    }
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .slot-request-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem !important;
    padding-top: 1.5rem;
    gap: 0.75rem;
    background-color: $background-color;
    border-radius: 0.5rem 0.5rem 0 0;

    &.slot-request-card-header {
      background-color: $white !important;
      border-bottom: 0.0625rem solid $separator;
    }

    .available-slot-icon {
      height: 2.5rem;
      aspect-ratio: 1;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $secondary;
    }

    .slot-delete-btn {
      padding: 0.5rem;
      height: 1.5rem;

      &:hover {
        cursor: pointer;
        border-radius: 0.5rem;
        background-color: $list-hover-color;
      }
    }
    .slot-details {
      display: flex;
      gap: 0.25rem;
      flex-direction: column;
      flex: 1;

      .slot-details-header {
        font-weight: $weight-400;
        font-size: 0.75rem;
        color: $text-color-2;
      }

      .slot-details-headline {
        font-weight: $weight-700;
      }

      .single-tag {
        display: flex;
        background-color: $level-tag-color;
        padding: 0.375rem 0.5rem;
        color: $primary;
        font-size: 0.75rem;
        font-family: $Roboto;
        font-weight: $weight-600;
        border-radius: 0.5rem;
        justify-content: center;
        align-items: center;
        width: fit-content;
        line-height: 0.75rem;
        border: none;
        min-width: auto;
        min-height: auto;
        flex-grow: 0;
      }
    }

    .actions-container {
      display: flex;
      gap: 0.25rem;
      align-self: flex-start;
    }
  }

  .no-request-response {
    display: flex;
    height: 17.5625rem;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0.3125rem;

    .no-result-text {
      font-size: 0.875rem;
      line-height: 1.3125rem;
      color: $gray-500;
      font-family: $Montserrat;
      font-weight: $weight-600;
    }
  }

  .divider {
    height: 0.0625rem;
    margin: 0 1.5rem !important;
    background-color: $calendar-gray;
  }
  .form-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem 1.5rem 0 1.5rem;

    .name-input-field {
      display: flex;
      gap: 0.5rem;
      align-items: center;
    }
  }
  .slot-btn {
    display: flex;
    justify-content: flex-end;
    margin: 1.5rem 1rem 1rem 1rem;

    .mark-booked-button {
      width: 9.4375rem;
      height: 3rem;
      font-weight: $weight-700;
      letter-spacing: 0.0125rem;
      line-height: 1.5rem;
      font-size: $font-size-14px;
    }
  }
}
.slot-request-drawer {
  width: unset;
}
