@import '../../../../assets/styles/theme';

.cm-delete-modal {
  width: auto;
  padding: 1.5rem;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $modal-box-shadow;
  z-index: 10;
  margin: 1rem;
  width: 480px;
  box-sizing: border-box;
  line-height: 1.8rem;

  .heading {
    font-weight: $weight-600;
    line-height: 2.25rem;
    font-family: $Montserrat;
    color: $gray-700;
    margin-bottom: 1rem;
  }
}

.cm-delete-header {
  font-family: $Montserrat;
  font-weight: $weight-600;
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
  color: $gray-900;
}

.cm-delete-modal-backdrop {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99;
  background-color: $modal-backdrop-background;
}

.cm-delete-notice-text {
  color: $gray-500;
  line-height: 1.5rem;
}

.cm-delete-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 1.5rem;
  margin-top: 1.5rem;

  .cm-button {
    display: flex;
    min-width: 120px;

    .custom-button {
      flex: 1;
    }
  }
}

.cm-delete-cancel-button {
  border-color: $primary;
  color: $primary;
  font-weight: $weight-700;
  height: 3rem;
}

.cm-delete-button {
  background-color: $primary;
  color: $white;
  font-weight: $weight-700;
  height: 3rem;
}
