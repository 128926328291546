@import '../../../../../assets/styles/theme';

.slot-cancel-card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 450px;
  height: max-content;
  border-radius: 8px;
  padding: 1.5rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: relative;
  gap: 0.5rem;
  background-color: $white;
  box-sizing: border-box;

  .component-loading-wrapper {
    min-height: 320px;
  }

  .radio-input-field {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    font-family: $Roboto;
    .radio-outer {
      padding: 0.25rem 0;
      .radio-label {
        margin-left: 0.5rem;
      }
    }
  }

  .slot-cancel-card-header {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    .interview-detail-card {
      display: flex !important;
      padding-top: 1rem;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 0.5rem;
      box-sizing: border-box;

      .date-text {
        color: $gray-500;
      }

      .candidate-details {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 100%;
        .name {
          display: flex;
          gap: 0.5rem;
          align-items: center;
          justify-content: space-between;
          .candidate-name {
            display: flex;
            align-items: center;
            gap: 0.5rem;
          }

          .slot-cancel-icon-wrapper {
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            color: $warning-text;
            padding: 0.125rem 0.5rem;
            font-size: 0.75rem;
            text-decoration: none;
            font-weight: $weight-600;
            background-color: $warning-bg;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }

          img {
            width: 16px;
            height: 16px;
          }
        }
        .timings {
          display: flex;
          gap: 0.5rem;
        }
      }
    }
    .slot-cancel-title {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $gray-900;
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .slot-cancel-icon-wrapper {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 40px;
        height: 40px;
        background: $white;
        border-radius: 8px;
        cursor: pointer;
        &:hover {
          background-color: $bg-color;
        }
      }
    }
    .close-icon-wrapper {
      box-sizing: border-box;
      height: 40px;
      min-height: 40px;
      max-height: 40px;
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      position: absolute;
      right: 1.5rem;
      top: 1rem;
      cursor: pointer;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $bg-color;
      }
    }
  }
  .slot-cancel-card-main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    .slot-cancel-card-content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      .cancel-reason-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-14px;
        font-weight: $weight-700;
        color: $text-color-primary;
      }
      .cancel-input-box {
        box-sizing: border-box;
        height: 72px !important;
      }
      .slot-cancel-checkbox-wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 8px;
        margin-top: 8px;
        .slot-available-label {
          box-sizing: border-box;
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-400;
          color: $text-color-medium;
        }
      }
    }
    .slot-cancel-card-action-btn {
      box-sizing: border-box;
      align-self: flex-end;
      height: 48px;
      width: 149px;
      border-radius: 8px;
      box-shadow: $box-shadow;
      color: $primary;
      font-weight: $weight-700;
      border: 1px solid $primary;
      background: $white;
      &:disabled {
        border: 1px solid $disabled;
        color: $disabled;
      }
    }
  }
}
