@import './assets/styles/_variable';
@import './assets/styles/theme';
@import './assets/styles/media';

.layout {
  display: flex;
  flex-direction: row;
  background-color: $bg-color;
  height: 100vh;
  font-family: $Roboto;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  .container {
    display: flex;
    height: 100%;
    flex: 1;
    background-color: $bg-color;
    flex-grow: 1;
    overflow-y: auto;
    @media screen and (max-width: $md-max) {
      height: calc(100% - (64px + 72px));
    }
  }

  .side-navigation {
    @media screen and (max-width: $md-max) {
      position: sticky;
      display: block;
      top: 0;
      z-index: 4;
    }
  }

  .bottom-navigation-wrapper {
    display: none;

    @media screen and (max-width: $md-max) {
      position: fixed;
      display: flex;
      bottom: 0px;
      width: 100%;
      z-index: 4;
    }
  }

  @media screen and (max-width: $md-max) {
    display: block;
  }
}
.Toastify__toast,
.Toastify__toast-container {
  width: auto !important;
  min-width: 350px !important;

  @media screen and (max-width: $md-max) {
    min-width: 328px !important;
  }
}
.Toastify__toast--success {
  border: 1px solid $gray-200 !important;
  box-shadow: $box-shadow-notification !important;
  border-radius: 0.5rem !important;
  background: $white !important;
  margin: 0.25rem;
}
.Toastify__toast--success::before {
  content: url('./assets/images/toast.svg');
  position: relative;
  z-index: 10;
  left: 12px;
  top: 6px;
}
.Toastify__toast--error::before {
  content: url('./assets/images/warning-icon.svg');
  display: flex;
  align-items: center;
  margin-left: 1rem;
}
.Toastify__toast--success::after {
  position: absolute;
  color: $gray-900;
  font-size: 15px;
  font-weight: 700;
  left: 265px;
  padding-top: 14px !important;
}
.Toastify__toast-body {
  color: $gray-900;
  font-size: 0.875rem;
  padding-left: 1rem;
  line-height: 1.313rem;
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  margin-left: 1rem !important;
}

.Toastify--animate-icon,
.Toastify__toast-icon {
  display: none !important;
}
.Toastify__progress-bar {
  height: 2px !important;
}
.Toastify__progress-bar--success {
  background: $primary !important;
}
.toast-close-button {
  padding: 0.75rem;
  height: 16px;

  &:hover {
    cursor: pointer;
  }
}
