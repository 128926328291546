@import '../../../../../assets/styles/media';
@import '../../../../../assets/styles/variable';

.slot-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-bottom: 5.065rem;
  margin-top: 1rem;
  .slot-details-card {
    display: flex;
    flex-direction: column;
    margin: 0 1rem;
    border-radius: 0.5rem;
    border: 0.063rem solid $gray-300;
    box-shadow: $add-slot-card-box-shadow;

    .slot-details-header {
      display: flex;
      position: relative;
      padding: 1.5rem 1.5rem 0 1.5rem;
      justify-content: space-between;
      .slot-details-date {
        display: flex;
        gap: 1rem;
        flex: 1;
      }
      .close-icon {
        position: absolute;
        right: 1rem;
        top: 1rem;
        padding: 0.5rem;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
.add-slot-drawer-footer {
  display: flex;
  gap: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: $drawer-buttons-box-shadow;
  background-color: $white;
  border-top: 0.063rem solid $gray-200;
  padding: 1rem;

  .cancel-btn,
  .save-btn {
    padding: 0.75rem 1.5rem;
    padding: 0.75rem 1.5rem;
    color: $white;
    width: auto;
    display: flex;
    flex: 1;
    height: 3rem;
    font-size: 1rem;
    font-weight: $weight-700;
    background: $primary;
    border: $primary-border;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
  }

  .cancel-btn {
    background-color: $white;
    color: $primary;
  }
}
@media screen and (max-width: $sm-max) {
  .slot-details-wrapper {
    .slot-details-card {
      margin: 0 1rem;
    }
  }
}
.drawer-delete-slots{
  height: 1.5rem;
  width: 1.5rem;
}