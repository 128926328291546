@import './_variable.scss';

$primary: #2c74d6;
$primary-dark: #2444a4;
$primary-light: #eef7fe;
$bg-color: #f9fafc;

$text-color-primary: #101828;
$text-color-secondary: #344054;
$text-color-medium: #667085;
$text-color-light: #98a2b3;
$calendar-time-box-border: rgba(152, 162, 179, 0.2);
$disable: #c0c4cc;

$error: #ff5252;
$error-secondary: #ffecef;
$warning: #f7d64d;
$warning-secondary: #fdf8e1;
$success: #04bfa4;
$success-secondary: #dcf4f0;
$calendar-border: #d1d6df;
$white: #ffffff;
$existing-event-bg: #e7e8e9;
$dark-black: #000000;
$border-color-with-op: rgba(0, 0, 0, 0.25);
$calendar-wrapper-border: rgba(44, 116, 214, 0.04);
