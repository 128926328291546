@mixin common-flex-column {
  display: flex;
  flex-direction: column;
}

@mixin common-flex {
  display: flex;
  flex: 1;
}

@mixin common-tech-label($border: 0.0625rem solid $text-color-2, $padding: 0.75rem 1rem) {
  display: flex;
  border: $border;
  box-shadow: $box-shadow;
  border-radius: 0.5rem;
  padding: $padding;
}
