@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';

.sort-container {
  width: min-content;
  height: min-content;
  z-index: 4;
  box-shadow: $modal-box-shadow;
  border-radius: 0.5rem;
  background-color: $white;
  max-width: 17rem;
  min-width: 17rem;
  display: flex;
  flex-direction: column;
}

.sort-header {
  font-family: $Montserrat;
  font-weight: $weight-600;
  font-size: 1rem;
  background-color: $bg-color;
  border-bottom: 0.0625rem solid $separator;
  height: 3rem;
  display: flex;
  align-items: center;
  padding-left: 1.5rem;
  border-radius: 0.5rem 0.5rem 0 0;
  color: $text-color-1;
}
.options-container {
  padding: 0.5rem 1.5rem;
  padding-right: 3rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: $text-color-1;
  font-weight: $weight-400;

  .radio-container {
    margin-top: 0 !important;
  }
}
