@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';
.slot-drawer-title {
  padding-left: 0.5rem;
}

.image-circle {
  width: 56px;
  height: 56px;
  border-radius: 50%;
}

.slot-drawer-body {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex: 1;

  .slot-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    z-index: 100;
    background-color: $white;

    .loading-spinner {
      width: 56px;
      height: 56px;
      animation: spinner 1.5s linear infinite;
    }
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .slots-received {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding-bottom: 1.5rem;
    flex: 1;
    overflow-y: scroll;
  }
  .no-response {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 5px;

    .no-result-text {
      font-size: 0.875rem;
      line-height: 21px;
      color: $gray-500;
      font-family: $Montserrat;
      font-weight: $weight-600;
    }
  }
  .request-slot {
    display: flex;
    align-items: center;
    margin-top: 1.5rem;
    justify-content: space-between;
    padding: 0rem 1.5rem 1rem 1.5rem;

    .response-text {
      font-family: $Montserrat;
    }
    .new-request-btn {
      border: 1px solid $primary;
      padding: 0 1.5rem;
      color: $primary;
      font-size: 1rem;
      line-height: 1.5rem;
      height: 48px;
    }
  }
}
.slots-toast {
  z-index: 5;
}
.multi-slot-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1.5rem;

  .duplicate-time-error {
    margin: 0 1.5rem;
  }
  .slot-timing_multiple {
    display: flex;
    flex: 1;

    .multiple-slot-timings {
      display: flex;
      align-items: center;
      gap: 0.5rem;

      .time {
        min-width: 120px;
      }

      .select-arrow-icon {
        display: none;
      }
    }
    .addMore {
      display: flex;
      gap: 0.5rem;
      flex: 1;
      justify-content: flex-end;
      margin-left: 0.5rem;

      .time-add-btn {
        width: 40px;
        height: 40px;
      }

      .time-delete,
      .time-add {
        padding: 0.5rem;
      }
    }
  }
}
.add-slot-drawer {
  height: 100%;

  .date-filter-div {
    height: calc(100% - 24px);

    .slot-date-filter-drawer {
      width: calc(100% - 48px);
      display: flex;
      border: 1px solid $calendar-gray;
      box-shadow: $slot-card-hover-box-shadow;
      border-radius: 0.5rem;
      margin: auto;
      margin-top: 0.5rem;
      & > :first-child {
        width: 100%;
      }
    }
    .date-btn-drawer {
      width: calc(100% - 48px);
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      background: $white;
      border: 1px solid $gray-500;
      border-radius: 0.5rem;
      justify-content: space-between;
      padding: 0.75rem 0;
      cursor: pointer;
      margin-top: 1.5rem;
    }
    .date-btn-focused {
      width: calc(100% - 48px);
      margin: auto;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
      background: $white;
      border: 1px solid $primary;
      box-shadow: $date-hover-box-shadow;
      border-radius: 0.5rem;
      justify-content: space-between;
      padding: 0.75rem 0;
      margin-top: 1.5rem;
      cursor: pointer;
    }
    .placeholder {
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-400;
      font-size: 1rem;
      line-height: 24px;
      color: $disabled;
      padding-left: 1rem;
    }
    .date-img {
      padding-right: 1rem;
    }

    .addSlotsSection {
      display: flex;
      align-items: baseline;
      width: 100%;
      border-bottom: 1px solid $calendar-gray;
    }
    .add-slots-subText {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: calc(100% - 48px);
      margin: auto;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
    }
    .slotsText {
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-600;
      font-size: 1rem;
      line-height: 24px;
      color: $gray-900;
      margin-left: 1.125rem;
    }
    .slot-timing_multiple {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      padding: 0 1.5rem;

      .select {
        min-width: 131px;
      }
      .separator {
        width: 8px;
        height: 1px;
        border-bottom: 1px solid $black;
      }

      .time {
        flex: 1;
      }
    }
    .addMore {
      cursor: pointer;
    }
  }
}
