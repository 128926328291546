@import '../../../../../assets/styles/variable';
.card-wrapper {
  height: auto;
  background: $white;
  padding: 1.5rem;
  box-shadow: 0px 2px 1rem rgba(44, 116, 214, 0.04);
  border-radius: 8px;
  row-gap: 1rem;
  display: flex;
  flex-direction: column;
}
.summary-text {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-color-1;
}
.total-users-div {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  width: 100%;
}
.user-box {
  border: 1px solid $gray-200;
  border-radius: 8px;
  padding: 1.5rem;
  width: 50%;
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  justify-content: space-between;
}
.countSection {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.count {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 3rem;
  line-height: 3rem;
  color: $text-color-1;
}
.totalUsers-heading {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-color-1;
}
.totalUsers-heading-blue {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-color-1;
}
.greenbar {
  width: 1rem;
  height: 2px;
  border-bottom: 2px solid $success;
  border-radius: 1px;
}
.bluebar {
  width: 1rem;
  height: 2px;
  background: $primary;
  border-radius: 1px;
}
