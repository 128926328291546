@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.slot-summary-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  background: $white;
  box-shadow: $card-box-shadow;
  border-radius: 0.5rem;
  padding-top: 1.5rem;
  min-height: 20.75rem;
  height: 100%;
  position: relative;
  box-sizing: border-box;

  .page {
    height: 100%;
    overflow: scroll;
    .page--headingText {
      font-size: 1rem;
    }

    .page--bodyText {
      font-size: 0.875rem;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    box-sizing: border-box;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: $sm-max) {
      padding: 0 0.5rem;
    }

    .title {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      .slot-summary-header {
        font-family: $Montserrat;
      }
      .date-picker {
        position: relative;

        .date-btn {
          width: 3rem;
          height: 3rem;
          border: 0.0625rem solid $primary;

          img {
            margin-right: 0;
          }
        }

        .slot-summary-date-picker-badge {
          width: 1rem;
          height: 1rem;
          position: absolute;
          border-radius: 50%;
          background-color: $primary;
          top: -0.5rem;
          left: 2.5rem;
        }
        .slot-summary-date-picker-popover {
          background-color: $white;
          position: absolute;
          right: 0;
          box-shadow: $modal-box-shadow;
          border-radius: 0.5rem;
          z-index: 10;
          margin-top: 0.5rem;
          box-sizing: border-box;
          margin-right: 0;

          @media screen and (max-width: $lg-max) {
            right: auto;
            left: 0;
          }
          @media screen and (max-width: $md-max) {
            right: 0;
            left: auto;
          }
          @media screen and (max-width: $sm-max) {
            right: -0.5rem;
            left: auto;
          }
          .year {
            display: flex;
            justify-content: flex-start;
            padding: 1rem 1.5rem;
            position: relative;
            border-bottom: 0.0625rem solid $calendar-gray;

            .modal-close-icon {
              position: absolute;
              top: 0.5rem;
              right: 1rem;
              padding: 0.5rem;
              border-radius: 0.5rem;
              &:hover {
                cursor: pointer;
                background-color: $bg-color;
              }
            }
          }
          .slot-date-picker-wrapper {
            padding: 0 2rem;
            padding-top: 1.5rem;
            padding-bottom: 1.125rem;
            box-sizing: border-box;

            @media screen and (max-width: $sm-max) {
              padding: 0 0.5rem;
              padding-top: 1.5rem;
              padding-bottom: 1.125rem;
            }
          }
          .modal-buttons {
            display: flex;
            justify-content: flex-end;
            gap: 1rem;
            padding: 1.5rem;
            border-top: 0.0625rem solid $calendar-gray;

            .cancel-button,
            .request-button {
              flex: 1;
              height: 3rem;
              font-weight: $weight-700;
              font-size: 1rem;
              line-height: 1.5rem;
            }

            .cancel-button {
              border: 0.0625rem solid $primary;
              color: $primary;
            }
          }
        }
      }
    }
  }
  .slot-summary-body {
    display: flex;
    flex: 1;
    gap: 0.5rem;
    padding: 1.5rem;
    flex-direction: column;
    padding-top: 1rem;
    height: 100%;
    max-height: 28.75rem;
    box-sizing: border-box;
    overflow: scroll;
    &::-webkit-scrollbar {
      width: 0.125rem;
      height: 0.25rem;
    }

    .no-events {
      display: flex;
      flex: 1;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      .no-slot-message {
        color: $gray-500;
        font-family: $Montserrat;
      }
    }
  }
}
