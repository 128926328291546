@import '../../../../../assets/styles/theme';

.slot-cancelled-popup {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  gap: 24px;
  width: 440px;
  min-height: 430px;
  background: $white;
  box-shadow: $modal-box-shadow;
  border-radius: 8px;
  .loading-wrapper {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    .loading-spinner {
      position: static;
    }
  }
  .slot-cancelled-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    gap: 4px;
    .slot-cancel-time-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-grow: 1;
      overflow: hidden;
      padding-block: 8px;
      .slot-cancelled-time-label {
        box-sizing: border-box;
        font-family: $Montserrat;
        font-size: $font-size-16px;
        font-weight: $weight-600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $text-color-primary;
      }
    }
    .slot-icon-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
      border-radius: 8px;
      &:hover {
        background-color: $bg-color;
      }
    }
  }
  .slot-cancelled-main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 16px;
    overflow: auto;
    .slot-cancelled-name-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 4px;
      .slot-candidate-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-16px;
        font-weight: $weight-600;
        white-space: nowrap;
        color: $text-color-primary;
      }
      .slot-candidate-name-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-weight: $weight-400;
        font-size: $font-size-16px;
        color: $text-color-primary;
        word-wrap: break-word;
        word-break: break-word;
      }
    }
    .slot-cancelled-candidate-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 4px;
      .slot-cancelled-tech-stack-tag {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px 8px;
        background-color: $secondary;
        border-radius: 8px;
        min-width: 97px;
        max-width: 100%;
        height: 24px;
        align-self: flex-start;
      }
      .slot-cancelled-tech-stack-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-12px;
        font-weight: $weight-600;
        color: $primary;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .slot-cancelled-by-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 4px;
      .slot-cancelled-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-16px;
        font-weight: $weight-600;
        color: $text-color-medium;
      }
    }
    .slot-cancelled-reason-wrapper {
      margin-top: auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 4px;
      padding-block: 14px;
      .slot-cancelled-reason-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-16px;
        font-weight: $weight-600;
        color: $text-color-medium;
      }
      .slot-cancelled-reason {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-16px;
        font-weight: $weight-400;
        color: $text-color-primary;
        word-wrap: break-word;
      }
    }
  }
}
