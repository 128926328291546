// @import "./_variable.scss";
@import './_media.scss';
@import './_theme.scss';

html,
body {
  margin: 0;
  padding: 0;
  scrollbar-width: 0;
  scrollbar-color: rgb(0, 0, 0, 0);
}

* {
  scrollbar-width: none;
  scrollbar-color: rgb(0, 0, 0, 0);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: antialiased;
  -ms-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
}
