@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';

.upcoming-interview {
  display: flex;
  flex-direction: column;
  min-height: 219px;
  background: #ffffff;
  box-shadow: $card-box-shadow;
  border-radius: 8px;
  padding-top: 1.5rem;
  position: relative;
  box-sizing: border-box;

  .upcoming-interview-header {
    font-family: $Montserrat;
    padding: 0.75rem 1.5rem;
    box-sizing: border-box;
  }
  .no-upcoming-interviews {
    display: flex;
    align-items: center;
    flex: 1;
    gap: 1rem;
    align-self: center;
    padding: 0 1.5rem;
    box-sizing: border-box;

    img {
      width: 80px;
      height: 80px;
    }

    .details {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      flex: 1;

      .headline-24 {
        text-align: center;

        @media screen and (max-width: $sm-max) {
          font-size: 1rem;
        }
      }

      .supporting-text {
        font-size: 1rem;
        font-weight: $weight-600;
        font-family: $Montserrat;
        text-align: center;

        @media screen and (max-width: $sm-max) {
          font-size: 0.75rem;
        }
      }
    }
  }
  .interview-details {
    display: flex;
    gap: 0.5rem;
    position: relative;
    min-height: 124px;
    box-sizing: border-box;
    flex-direction: column;

    .slick-slider .right-arrow,
    .slick-slider .left-arrow {
      @media screen and (max-width: $lg-max) {
        bottom: 2.625rem;
      }
      @media screen and (max-width: $md-max) {
        bottom: 1rem;
      }
      @media screen and (max-width: $sm-max) {
        bottom: 2.625rem;
      }
    }

    .interview-date {
      padding-left: 1.5rem;
      box-sizing: border-box;
      color: $gray-500;
      font-family: $Montserrat;
      margin-bottom: 0.5rem;
      box-sizing: border-box;
    }

    .interview-detail-card {
      display: flex !important;
      margin-left: 1.5rem;
      padding: 1rem;
      width: calc(100% - 32px);
      min-height: 82px;
      align-items: center;
      border: $tech-item-border;
      border-radius: 0.5rem;
      box-sizing: border-box;
      flex-wrap: wrap;

      .candidate-details {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex: 1;
        div {
          width: auto;
        }
        .name {
          display: flex;
          align-items: center;
          gap: 0.5rem;
          justify-content: flex-start;
          img {
            width: 16px;
            height: 16px;
          }
        }
        .timings {
          display: flex;
          gap: 0.5rem;
          align-items: center;

          .times {
            font-size: 0.75rem;
            color: $gray-900;
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            line-height: 18px;
            white-space: nowrap;
          }
        }
      }
      .cancel-button {
        color: $primary;
        font-weight: $weight-700;
        font-size: 1rem;
        line-height: 1.5rem;
        box-sizing: border-box;
        box-shadow: none;
        height: 40px;
        padding: 0.5rem;
        border-radius: 0.5rem;

        &:hover {
          background-color: $level-tag-color;
          text-decoration: underline;
        }
      }
    }
  }
}
@media screen and (max-width: $sm-max) {
  .upcoming-interview {
    .interview-details {
      .interview-detail-card {
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        width: 100%;
        margin-left: 0;

        .candidate-details {
          flex: auto;
        }
      }
    }
  }
}
