@import 'assets/styles/variable';
.radio-container .radio-custom {
  width: 1.25rem;
  height: 1.25rem;
  border: $checkbox-border;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.radio-custom:hover {
  background-color: $tags-color;
  border: $primary-border;
}

.radio {
  position: absolute;
  height: 1.25rem;
  width: 1.25rem;
  appearance: none;
}

.radio-outer-container {
  display: relative;
}

.radio-outer-slots {
  display: flex;
  cursor: pointer;
  padding: 1rem 1.5rem;
  gap: 0.5rem;
  flex-direction: column;
  &.no-padding {
    padding-block: 0;
  }

  .slot-panel-container {
    display: flex;
    padding: 0.5rem 0;
    gap: 1rem;
  }
}

.radio-container input[type='radio']:checked + .radio-custom {
  border: $primary-border;
  background-color: $tags-color;
}

.radio-container input[type='radio']:checked + .radio-custom:after {
  content: '';
  width: 0.625rem;
  height: 0.625rem;
  background-color: $primary;
  border-radius: 20%;
  display: block;
  position: absolute;
  top: 25%;
  left: 25%;
  border-radius: 50%;
}

.slot-radio-label {
  display: flex;
  flex: 1;
  gap: 0.5rem;
  flex-direction: column;

  .slot-details {
    display: flex;
    gap: 1rem;
  }

  .panel-image {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }
  .panel-details {
    display: flex;
    gap: 1rem;

    .panel-name {
      display: flex;
      gap: 0.5rem;
      position: relative;
      z-index: 10;
      align-items: center;

      .truncate-panel-name {
        max-width: 12rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .panel-name-tooltip {
        display: none;
        justify-content: center;
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-400;
        font-size: 0.75rem;
        line-height: 1.125rem;
        color: $text-color-1;
        background: $white;
        border-radius: 0.5rem;
        padding: 0.75rem;
        box-shadow: 0 0.25rem 0.25rem $gray-300;
        position: absolute;
        bottom: 1.25rem;
        left: 0.938rem;

        .panel-name-tooltip-arrow {
          position: absolute;
          width: 0.75rem;
          height: 0.75rem;
          left: calc(50% - 0.375rem);
          bottom: -0.313rem;
          background: $white;
          border-radius: 0.063rem;
          display: flex;
          transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
          box-shadow: 0.125rem 0.125rem 0 $gray-300;
        }
      }

      .truncate-panel-name:hover + .panel-name-tooltip {
        display: flex;
      }

      .single-tag {
        display: flex;
        background-color: $level-tag-color;
        padding: 0.375rem 0.75rem;
        color: $text-color-1;
        font-size: 0.75rem;
        font-family: $Roboto;
        font-weight: $weight-400;
        border-radius: 0.5rem;
        justify-content: center;
        align-items: center;
        line-height: 0.75rem;
        border: none;
        min-width: auto;
        min-height: auto;
        flex-grow: 0;
        height: 1.5rem;
        box-sizing: border-box;
      }
    }
  }
}
.panel-slots-radio-group {
  &::-webkit-scrollbar {
    width: 0.25rem;
  }
}
.tags {
  display: flex;
  gap: 0.25rem;
}
