@import 'assets/styles/variable';
@import 'assets/styles/media';

.project-card {
  background-color: $white;
}
.project-item {
  background-color: $white;
  padding: 0.5rem;
  border: $tech-item-border;
  border-radius: $border-radius;
  display: flex;
  height: 8.25rem;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  @media (max-width: $md-min) {
    height: auto;
    padding: 0;
    width: auto;
    flex: 1;
  }

  .project--detail {
    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 1rem;
    @media (max-width: $md-min) {
      gap: 0.5rem;
      padding: 1rem;
    }
    .project--name {
      font-size: $font-size-20px;
      font-weight: $weight-600;
      color: $gray-900;
      line-height: 2.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 9.9375rem;
      margin: 0;
      font-family: $Montserrat;
      @media (max-width: $md-min) {
        font-size: 0.875rem;
      }
    }
    .project--sub-detail {
      font-size: 1rem;
      font-weight: $weight-400;
      color: $primary;
      font-family: $Roboto;
      line-height: 1.5rem;

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
      @media (max-width: $md-min) {
        font-size: 0.875rem;
      }
    }
  }
  .project--dropdown {
    .dots {
      cursor: pointer;
      height: 3rem;

      .ellipsis {
        padding: 0.75rem;
      }
    }
  }
  .dropdown {
    position: absolute;
    right: 2rem;
    top: 3rem;
    background-color: $white;
    padding: 0.5rem 0;
    width: 8.3125rem;
    z-index: 4;
    border-radius: 0.5rem;
    border: $tech-item-border;
    box-shadow: $box-shadow-notification;
    .divider {
      border-bottom: $tech-item-border;
      margin: 0.5rem;
    }
    .dropdown-option {
      font-weight: $weight-400;
      font-size: $textFontSize;
      color: $text-color-1;
      line-height: 1.5rem;
      padding: 0.5rem 1rem;
      &:hover {
        background-color: $tags-color;
        cursor: pointer;
      }
    }
  }
}
.selected-project {
  animation: blinkingBorder 3s infinite;
}

@keyframes blinkingBorder {
  0% {
    border: 0.25rem solid $white;
  }
  25% {
    border: 0.25rem solid $animation-color;
  }
  50% {
    border: 0.25rem solid $white;
  }
  75% {
    border: 0.25rem solid $animation-color;
  }
  100% {
    border: 0.25rem solid $white;
  }
}
.edit-modal {
  background-color: $white;
  display: flex;
  padding: 1.5rem;
  border-radius: $border-radius;
  flex-direction: column;
  box-sizing: border-box;
  width: 30rem;
  .edit-modal-content {
    font-family: $Roboto;
    font-style: normal;
    font-weight: $weight-400;
    font-size: $body1FontSize;
    line-height: 1.5rem;
    color: $gray-700;
    .delete-modal-para {
      margin: 0;
    }
    .delete-modal-confirm-text {
      margin-top: 1rem;
      color: $gray-500;
    }
    .delete-modal--project-label {
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-600;
      font-size: $font-size-14px;
      line-height: 1.25rem;
      color: $gray-900;
    }
    .project-name {
      width: 100%;
      height: 1.875rem;
      margin-top: 0.5rem;
      background: $white;
      border: 0.0625rem solid $gray-blue;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      padding: 0.75rem 1rem;
      height: 3rem;
      box-sizing: border-box;
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-400;
      font-size: 1rem;
      line-height: 1.5rem;
      outline: none;
      &:focus {
        border: 0.0625rem solid $primary;
        box-shadow: $settings-autocomplete-boxShadow;
      }
    }
    .edit-modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1.5rem;
      gap: 1.5rem;
      .edit-modal--cancel-btn,
      .edit-modal--save-btn {
        padding: 0.75rem 1.5rem;

        color: $primary;
        width: 7.5rem;
        height: 3rem;
        background: $white;
        border: $primary-border;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
      }
      .edit-modal--save-btn {
        background: $primary;
        color: $white;
      }
    }
  }
}
.project-tooltip {
  display: none;
}
.project-tooltip-arrow {
  display: none;
}
.project--name:hover + .project-tooltip {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $text-color-1;
  background: $white;
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0 0.25rem 0.25rem $gray-300;
  position: absolute;
  margin-top: -2.5rem;
  display: flex;
  justify-content: center;
  .project-tooltip-arrow {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    left: calc(50% - 0.375rem);
    bottom: -0.3125rem;
    background: $white;
    border-radius: 0.0625rem;
    display: flex;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    box-shadow: 0.125rem 0.125rem 0 $gray-300;
  }
}
.user-list-modal {
  width: 50rem;
  background-color: $white;
  box-shadow: 0 0.125rem 1rem rgba(44, 116, 214, 0.04);
  border-radius: 0.5rem;
  height: 90%;
  display: flex;
  justify-content: center;
}
.error-message {
  color: $error;
  font-size: 0.875rem;
  font-weight: 400;
}
