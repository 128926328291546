@import '../../../../../assets/styles/theme';
@mixin logo-common {
  min-width: 0.9rem;
  min-height: 0.9rem;
  max-width: 0.9rem;
  max-height: 0.9rem;
  border-radius: 50%;
  margin-right: 0.25rem;
  font-family: $Roboto;
}

.color-indicator-wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 0.75rem;
}

.color-indicator {
  display: flex;
  align-items: center;
}

.color-indicator-text {
  font-weight: 400;
  font-size: 0.9rem;
  color: $text-color-light;
}

.logo-available {
  @include logo-common();
  background-color: $primary;
}

.logo-booked {
  @include logo-common();
  background-color: $success;
}

.logo-existing {
  @include logo-common();
  background-color: $text-color-light;
}

.logo-cancelled {
  @include logo-common();
  background-color: $error;
}

.logo-requested {
  @include logo-common();
  background-color: $warning-color-1;
}
