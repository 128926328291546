@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.slots-search-header {
  display: flex;
  align-items: center;
  gap: 1rem;
  .slots-search-title {
    font-family: $Montserrat;
    font-size: 1.5rem;
    font-weight: $weight-600;
    color: $disabled-text-color;
    & > span {
      color: $gray-500;
    }
  }
  .slots-header-button {
    color: $primary;
    border: 1px solid $primary;
    font-size: 1rem;
    height: 48px;
    padding: 0.75rem;
    margin-right: 1rem;
  }
}

@media screen and (max-width: $md-max) {
  .slots-search-header {
    .slots-search-title {
      font-size: 1rem;
    }
  }
}

@media screen and (max-width: $sm-max) {
  .slots-search-header {
    width: 100%;
    justify-content: space-between;
  }
}
