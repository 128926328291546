@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';

.slot-requirement-modal {
  width: 30rem;
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: $modal-box-shadow;
  padding: 1.5rem;
  position: relative;

  div {
    &:focus-visible {
      outline: none;
    }
  }

  .slot-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26.313rem;
    border-radius: 0.5rem;
    z-index: 100;
    background-color: $white;

    .loading-spinner {
      width: 3.5rem;
      height: 3.5rem;
      animation: spinner 1.5s linear infinite;
    }
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media screen and (max-width: $md-min) {
    margin: 1rem;
  }

  .modal-close-icon {
    padding: 0.5rem;
    height: 1.5rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    &:hover {
      cursor: pointer;
      border-radius: 0.5rem;
      background-color: $list-hover-color;
    }
  }

  .heading {
    margin-bottom: 1rem;
  }
  .slot-requirement-form-hint {
    color: $gray-500;
    line-height: 1.5rem;
    margin-bottom: 1rem;
  }

  .slot-requirement-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .date-input {
      position: relative;
    }
    .form-field {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .warning-message {
        display: flex;
        margin-top: 0.5rem;
        gap: 0.5rem;
        align-items: flex-start;

        img {
          margin-top: 0.25rem;
        }

        .warning-text {
          color: $gray-500;

          span {
            color: $primary;
            font-weight: $weight-600;
            &:hover {
              border-radius: 0.25rem;
              text-decoration: underline;
              cursor: pointer;
            }
          }
        }
      }

      .select {
        @media screen and (max-width: $md-min) {
          min-width: auto;
        }
      }
      .clipped-select-wrapper {
        margin-bottom: 0;
      }

      .slot-timing {
        display: flex;
        gap: 0.5rem;
        align-items: center;

        .separator {
          width: 0.5rem;
          height: 0.063rem;
          background: $black;
        }

        .time {
          flex: 1;

          .select-arrow-icon {
            display: none;
          }
        }
      }

      .slot-date-picker-wrapper {
        border-radius: 0.5rem;
        border: $tech-item-border;
        background-color: $white;
        padding: 0.5rem 2.5rem;
        position: absolute;
        z-index: 5;
        top: 5.75rem;
        right: 0;
        left: 0;
      }

      .clipped-select-wrapper .primary-select,
      .clipped-select-wrapper .clipped-select-with-icon .secondary-select {
        flex: 1;
      }
      .clipped-select-wrapper .clipped-select-with-icon {
        flex: 1;

        .close-icon {
          display: none;
        }
      }

      .stackLoi-row-container {
        display: flex;

        .input-label {
          flex: 1;
        }
      }
    }
    .modal-buttons {
      display: flex;
      justify-content: flex-end;
      gap: 1.5rem;
      margin-top: 0.5rem;

      .cancel-button,
      .requirement-button {
        width: 7.5rem;
        height: 3rem;
        font-weight: $weight-700;
        font-size: 1rem;
        line-height: 1.5rem;
      }

      .cancel-button {
        border: 0.063rem solid $primary;
        color: $primary;
      }
    }
  }
}
@media screen and (max-width: $sm-min) {
  .slot-requirement-modal {
    padding: 1rem;

    .slot-requirement-form {
      .form-field {
        .slot-date-picker-wrapper {
          right: -1rem;
          left: -1rem;
          padding: 0.5rem 0;
        }
      }
    }
  }
}
.closeIcon{
  height: 1.5rem;
  width: 1.5rem;
}