@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/media';

.button-group {
  width: max-content;
  display: flex;
  cursor: pointer;
  margin-bottom: 24px;
  @media screen and (max-width: $sm-min) {
    width: 100%;
  }
}

.button-group > * {
  box-sizing: border-box;
  box-shadow: $button-group-box-shadow;
  color: $gray-900;
  font-weight: $weight-400;
  border-radius: 0;
  background-color: $white;
  border: $button-group-border;
  padding: 0.75rem 1rem;
  height: 3rem;

  @media screen and (max-width: $md-min) {
    padding: 0.75rem 0.375rem;
  }
}

.button-group:nth-child(2) {
  border-left: 0;
  border-right: 0;
}

.button-group > .selected {
  color: $white;
  background-color: $primary;
  border: none;
  font-weight: $weight-600;
}

.button-group--horizontal {
  *:first-child {
    border-radius: $button-group-horizontal-first-child;
  }

  *:last-child {
    border-radius: $button-group-horizontal-last-child;
  }
}

.button-group--vertical {
  flex-direction: column;

  *:first-child {
    border-radius: $button-group-vertical-first-child;
  }

  *:last-child {
    border-radius: $button-group-vertical-last-child;
  }
}
.label-count,
.selectedCount {
  color: $calendar-gray;
  font-size: 1rem;
  font-weight: 400;
  font-family: $Roboto;
  line-height: 1.5rem;
}
.label-text {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
}
.selectedCount {
  font-weight: 600;
  color: $calendar-gray;
}
