@import '../../../assets/styles/theme';

.settings-layout {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
  align-self: stretch;
  flex-grow: 1;
  height: 100%;
  overflow: auto;
  .settings-text {
    user-select: none;
    font-family: $Montserrat;
    font-size: $tabHeadingFontSize;
    font-weight: $weight-600;
    color: $text-color-primary;
  }
  .pagination-container {
    padding: 0;
  }
  .settings-toast-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-grow: 1;
    .settings-undo-button {
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-14px;
      font-weight: 400;
      line-height: 21px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
  .help-accordion {
    box-sizing: border-box;
    padding: 16px;
    .help-text {
      box-sizing: border-box;
      display: inline-block;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-400;
      color: $text-color-1;
      user-select: none;
      margin-right: 4px;
    }
    .help-link {
      box-sizing: border-box;
      display: inline-block;
      color: $primary;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-500;
      text-decoration: none;
    }
  }
}
