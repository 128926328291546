@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';

.slot-modal {
  width: auto;
  border-radius: $border-radius;
  background-color: $white;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  .heading {
    display: flex;
    padding: 0 1rem;
    justify-content: flex-start;
    gap: 3.5rem;
    align-items: center;
    font-weight: $weight-600;
    font-family: $Montserrat;
    color: $gray-700;
    margin: 0;

    .radio-label {
      font-family: $Roboto;
      font-weight: $weight-400;
      line-height: 1.5rem;
      margin-left: 0.5rem;
      font-size: $font-size-16px;
    }
    .radio-outer {
      padding: 1rem 0 1rem 0.5rem;
    }
  }
  .slot-date-picker-wrapper {
    padding: 1.5rem;
    border-top: 1px solid $calendar-gray;
    border-bottom: 1px solid $calendar-gray;
  }

  .slot-timing {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid $calendar-gray;
    .select {
      min-width: 131px;
    }
    .separator {
      width: 8px;
      height: 1px;
      background: $black;
    }

    .time {
      flex: 1;

      .select-arrow-icon {
        display: none;
      }
    }
  }

  .modal-buttons {
    display: flex;
    justify-content: space-evenly;
    gap: 1rem;
    padding: 1.5rem;

    .cancel-button,
    .request-button {
      flex: 1;
      height: 48px;
      font-weight: $weight-700;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .cancel-button {
      border: 1px solid $primary;
      color: $primary;
    }
  }

  .border {
    max-width: 480px;
    width: 100%;
    .border-line {
      border: 1px solid $calendar-gray;
      margin: 0 0 24px;
    }
  }

  .modal-close-icon {
    height: 24px;
    width: 24px;
    position: absolute;
    right: 1rem;
    padding: 0.5rem;

    &:hover {
      cursor: pointer;
      border-radius: 0.5rem;
      background-color: $list-hover-color;
    }
  }

  .input-label {
    padding: 0 24px 0;
  }
}

@media screen and (max-width: $sm-min) {
  .slot-modal {
    .heading {
      gap: 1rem;
    }
    .slot-date-picker-wrapper {
      padding: 0rem;
    }
  }
}
