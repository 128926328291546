@import 'assets/styles/variable';

.infinite-scroll-loader {
  flex: 1;
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  color: $gray-500;
  .loading-wrapper {
    position: static;
  }
}
