@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/media';
@import '../../../../assets/styles/theme';

.dashboard-page {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1.5rem;

  .search-circle {
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
  }

  .clipped-select-wrapper .primary-select > :first-child,
  .clipped-select-wrapper .secondary-select > :first-child {
    max-width: none;
  }

  .slots-body,
  .table-outer-container {
    margin: 0;
  }

  .no-slots-found,
  .no-slots-available {
    img {
      margin-bottom: 0.5rem !important;
    }
  }

  .slots-header {
    display: flex;
    height: fit-content;
    margin-bottom: 0.5rem;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    justify-content: space-between;

    .header-container {
      display: flex;
      flex: 1;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .header-title {
        color: $text-color-1;
        font-family: $Montserrat;
        font-weight: $weight-600;
        font-size: 2rem;
        margin-right: 1rem;

        @media screen and (max-width: $md-min) {
          font-size: 1.5rem;
          display: block;
          margin: 1.5rem 0;
        }
      }
    }

    .actions-container {
      .slot-btn {
        max-height: 3rem;
        font-weight: $weight-600;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $primary;
        padding: 0.75rem 1.5rem;
        background: $white;
        border: 0.0625rem solid $primary;
        box-shadow: $tag-box-shadow;

        &.slot-btn-active {
          box-shadow: 0 0 0 0.25rem $primary-light;
          background-color: $primary-light;
        }
      }
      .search-bar {
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media screen and (max-width: $sm-min) {
          width: 100%;
        }
      }
    }
  }

  .slot-drawer-title {
    padding-left: 0.5rem;
  }

  .slot-drawer-body {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1;

    .slot-loading-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      z-index: 100;
      background-color: $white;

      .loading-spinner {
        width: 3.5rem;
        height: 3.5rem;
        animation: spinner 1.5s linear infinite;
      }
      @keyframes spinner {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }

    .slots-received {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      padding-bottom: 1.5rem;
      flex: 1;
      overflow-y: scroll;
    }
    .no-response {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 0.3125rem;

      .no-result-text {
        font-size: 0.875rem;
        line-height: 1.3125rem;
        color: $gray-500;
        font-family: $Montserrat;
        font-weight: $weight-600;
      }
    }
    .request-slot {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0rem 1.5rem 1rem 1.5rem;

      .response-text {
        font-family: $Montserrat;
      }
      .new-request-btn {
        border: 0.0625rem solid $primary;
        padding: 0 1.5rem;
        color: $primary;
        font-size: 1rem;
        line-height: 1.5rem;
        height: 3rem;
      }
    }
  }
  .tag-label {
    width: auto !important;
  }
}

@media screen and (max-width: $lg-max) {
  .dashboard-overview {
    flex-direction: column;
  }
}
@media screen and (max-width: $md-min) {
  .search-bar {
    width: 20rem;
  }
}
@media screen and (max-width: $sm-max) {
  .dashboard-page {
    padding: 1rem;
    .slots-header {
      flex-direction: column;
      gap: 1rem;

      .search-bar {
        width: 100%;
      }

      .header-container {
        .header-title {
          margin: 0;
        }
      }
    }
    .panel-card-lower {
      margin-left: 4.5rem !important;
    }
  }
}
.slot-btn-active {
  max-height: 3rem;
  font-weight: $weight-600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $primary;
  padding: 0.75rem 1.5rem;
  border: 0.0625rem solid $primary;
  box-shadow: 0 0 0 0.25rem $primary-light;
  background-color: $primary-light;
}
