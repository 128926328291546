@import '../../../../assets//styles/variable';

@mixin common-font{
  font-family: $Montserrat;
    font-style: normal;
    font-weight:  $weight-600;
}
.outerWrapper {
  width: 50rem;
  height: 100%;
  .modalLoader {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex: 1;
    justify-content: center;
    border-radius: 0.5rem;
    align-items: center;
    .loading-spinner {
      left: 0;
      top: 0;
    }
  }
  .heading-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 1.5rem 1.5rem 0 1.5rem;
    column-gap: 0.5rem;

    .total-count {
      @include common-font();
      padding-left: 0.5rem;
      font-size: 1.5rem;
      line-height: 2.25rem;
      color: $disabled;
    }

    .list-heading {
      @include common-font();
      margin-bottom: 0;
      font-size: 1.5rem;
      line-height: 2.25rem;
      color: $text-color-1;
    }
    .search-box {
      display: flex;
      flex-direction: row;
      column-gap: 1.5rem;
      .input-wrapper{
        width: 15rem;
        height: 3rem;
        box-sizing: border-box;
        .input-field{
          width: 8.75rem;
        }
      }
      .crossIcon {
        cursor: pointer;
      }
    }
  }
    .table-separator {
      width: 100%;
      border-bottom: 0.063rem solid $separator;
      padding: 1.5rem 0 0.75rem 0;
      height: 0;
      border-left: none;
    }
  .tab-header {
    padding-left: 1.5rem;
    border-bottom: none !important;
  }
  .tab-separator {
    width: 100%;
    border: none;
    height: 0;
    border-left: none;
  }
  .tab-container {
    margin-top: 1.5rem;
  }
  .tab-data {
    .user-details {
      height: 85%;
      overflow: scroll;
      scroll-behavior: auto;
    }
  }
  .tableSection {
    .table-outer-container {
      max-height: 17.775rem;
    }
  }
  .noUsersScreen {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 80%;
    .noUsersTitle {
      @include common-font();
      font-size: 1.5rem;
      line-height: 2.25rem;
      text-align: center;
      color: $text-color-1;
    }
    .noUsersSubHeading {
    @include common-font();
      font-size: 1rem;
      line-height: 1.5rem;
      text-align: center;
      color: $text-color-2;
    }
    .panellist-btn {
      padding: 0.75rem 1.5rem;
      width: max-content;
      margin-top: 1.5rem;
      box-shadow: 0 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
    }
  }
}
