@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';

.drawer-body-style {
  display: flex;
  flex: 1;
  flex-direction: column;
  .edit-drawer-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    font-family: $Roboto;
    font-style: normal;
    font-weight: $weight-400;
    font-size: $body1FontSize;
    margin-top: 2rem;
    line-height: 1.5rem;
    color: $gray-700;

    .zoho-icon {
      width: 5rem;
      height: 3.125rem;
      position: absolute;
      top: 0;
      right: 0;
    }
    .zoho-label {
      display: flex;
      position: absolute;
      top: 1.5rem;
      right: 1.5rem;
      font-size: 0.75rem;
      user-select: none;
      line-height: 1rem;
      color: $gray-500;
      align-items: center;
      font-weight: $weight-400;
      .zoho-tag {
        font-weight: $weight-600;
        color: $white;
        font-family: $Montserrat;
        border-radius: 0.25rem;
        margin-left: 0.25rem;
        padding: 0.125rem 0.25rem;
        box-sizing: border-box;
        background-color: $error;
      }
    }
    .delete-drawer-para {
      margin: 0;
    }
    .tech-drawer-text {
      color: $gray-500;
      padding-bottom: 1rem;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      border-bottom: $tech-item-border;
      display: flex;
      justify-content: center;
    }
    .edit-tech-drawer-img {
      width: 10rem;
      height: 10rem;
      border: 0.0625rem solid $gray-200;
      border-radius: 0.5rem;
      margin: 0 auto;
      margin-bottom: 1.5rem;

      .image-upload-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        flex-direction: column;
        height: 100%;
        .add-image {
          border-radius: 0.5rem;
          padding: 0.5rem;
          font-weight: 700;
          font-size: 1rem;
          color: $primary;
          cursor: pointer;
          &:hover {
            background: $img-bg-color;
            text-decoration-line: underline;
          }
        }
      }

      .editable-tech-image {
        width: 100%;
        height: 100%;
        position: relative;

        .delete-tech-image {
          position: absolute;
          bottom: -10%;
          right: -10%;
          background-color: $white;
          padding: 1rem;
          line-height: 0;
          border: 0.0625rem solid $primary;
          border-radius: 50%;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .hidden-input {
        display: none;
      }
      .image-upload-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
      .uploaded-image {
        width: 100%;
        height: 100%;
        border-radius: 0.5rem;

        .profile-text {
          font-size: 2rem;
          font-weight: $weight-600;
        }
      }
    }
    .tech-drawer-form {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      margin-top: 1rem;
    }

    .delete-drawer--tech-label {
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-600;
      font-size: $font-size-14px;
      line-height: 1.25rem;
      color: $gray-900;
    }
    .tech-name {
      width: 100%;
      height: 1.875rem;
      background: $white;
      border: $border;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      padding: 0.75rem 1rem;
      height: 3rem;
      box-sizing: border-box;
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-400;
      font-size: 1rem;
      line-height: 1.5rem;
      outline: none;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      &:focus {
        border: 0.0625rem solid $primary;
        box-shadow: $settings-autocomplete-boxShadow;
      }
    }
    .edit-drawer-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      border-top: $tech-item-border;
      padding: 1.5rem;
      gap: 1.5rem;
      .edit-drawer--cancel-btn,
      .edit-drawer--save-btn {
        padding: 0.75rem 1.5rem;
        padding: 0.75rem 1.5rem;
        color: $white;
        width: auto;
        display: flex;
        flex: 1;
        height: 3rem;
        font-size: 1rem;
        font-weight: $weight-700;
        background: $primary;
        border: $primary-border;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
      }

      .edit-drawer--cancel-btn {
        background-color: $white;
        color: $primary;
      }
    }
  }
}

.delete-ts-black {
  color: $gray-900;
}

.invalid-error-ts-drawer-home {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 1rem;
}
