@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/media';
@import '../../../../assets/styles/theme';

.rewards-interview {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.actions-container {
  display: flex;
  gap: 1rem;

  .notifications {
    display: flex;
    justify-content: center;
    align-items: center;
    border: $primary-border;
    border-radius: 0.5rem;
    position: relative;
    cursor: pointer;
    min-height: 3rem;
    aspect-ratio: 1/1;
    box-sizing: border-box;
    background-color: $white;

    &.notifications-active {
      box-shadow: 0 0 0 0.25rem $primary-light;
      background-color: $primary-light;
    }

    .notification-count {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      color: $white;
      border-radius: 50%;
      top: -0.375rem;
      right: -0.375rem;
      height: 1.25rem;
      width: 1.25rem;
      line-height: 0.75rem;
      font-weight: $weight-600;
      font-size: 0.75rem;

      &.count-increased {
        height: 1.35rem;
        width: 1.35rem;
        padding: 0 0.25rem;
        border-radius: 0.5rem;
      }
    }
  }
}
