.drawer-enter {
  transform: translateX(465px);
  -webkit-transform: translateX(465px);
  -moz-transform: translateX(465px);
  -o-transform: translateX(465px);
  -ms-transform: translateX(465px);
}

.drawer-enter-active {
  transform: translateX(0);
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
  transition: transform 300ms;
}

.drawer-exit {
  transform: translateX(0);
  transition: transform 300ms;
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -o-transform: translateX(0);
  -ms-transform: translateX(0);
}

.drawer-exit-active {
  transform: translateX(465px);
  -webkit-transform: translateX(465px);
  -moz-transform: translateX(465px);
  -o-transform: translateX(465px);
  -ms-transform: translateX(465px);
}
