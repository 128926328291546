@import '../../../../../assets/styles/theme';

.slot-cancel-container {
  box-sizing: border-box;
  width: 400px;
  max-width: 400px;
  border-radius: 8px;
  padding: 24px;
  background-color: $white;
  border: 1px solid $calendar-gray;
  box-shadow: $modal-box-shadow;
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: flex-start;
  align-items: stretch;
  .slot-cancel-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slot-cancel-title-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 8px;
      .slot-booked-label {
        box-sizing: border-box;
        display: block;
        font-family: $Montserrat;
        font-size: $font-size-16px;
        font-weight: $weight-600;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $text-color-primary;
        padding-block: 2px;
      }
    }
    .slot-cancel-icon-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background: $white;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        background-color: $bg-color;
      }
    }
  }
  .slot-cancel-main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 8px;
    .slot-cancel-main-sub-container {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 8px;
      .slot-timming-label {
        box-sizing: border-box;
        font-family: $Montserrat;
        font-size: $font-size-16px;
        font-weight: $weight-600;
        color: $text-color-primary;
        white-space: nowrap;
      }
      .slot-cancel-book-container {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        overflow: hidden;
        flex-grow: 1;
      }
      .slot-booked-stack-label {
        box-sizing: border-box;
        font-family: $Montserrat;
        font-weight: $weight-600;
        font-size: $font-size-16px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: $primary;
        background-color: $primary-light;
        border: none;
        flex-grow: 0;
      }
    }
    .slot-booked-date-label {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-weight: $weight-600;
      font-size: $font-size-16px;
      color: $text-color-medium;
    }
  }
  .slot-cancelled-name-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 4px;
    .slot-candidate-label {
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      white-space: nowrap;
      color: $text-color-primary;
    }
    .slot-candidate-name-label {
      box-sizing: border-box;
      font-family: $Roboto;
      font-weight: $weight-400;
      font-size: $font-size-16px;
      color: $text-color-primary;
      word-wrap: break-word;
      word-break: break-word;
    }
  }
  .slot-cancel-footer-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 24px;
    .slot-cancel-reason-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 8px;
      .slot-cancel-mark-wrapper {
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 12px;
        margin-bottom: 10px;
        .slot-cancel-radio-wrapper {
          box-sizing: border-box;
          display: flex;
          gap: 16px;
          align-items: center;
        }
        .radio-outer {
          align-self: stretch;
          width: auto;
          padding: 0;
          .radio-label {
            box-sizing: border-box;
            font-family: $Roboto;
            font-size: $font-size-16px;
            color: $text-color-1;
            font-weight: $weight-400;
            margin-left: 0.75rem;
          }
          .radio-custom {
            width: 1rem;
            height: 1rem;
            &::after {
              top: 25%;
              left: 24%;
              width: 0.5rem;
              height: 0.5rem;
            }
          }
        }
      }
      .slot-reason-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-14px;
        font-weight: $weight-700;
        color: $text-color-primary;
      }
      .slot-cancel-field {
        box-sizing: border-box;
        height: 72px;
        .input-field::placeholder {
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-400;
        }
      }
      .slot-cancel-checkbox-wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 8px;
        .slot-cancel-checkbox-label {
          box-sizing: border-box;
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-400;
          line-height: 24px;
          color: $text-color-medium;
        }
      }
    }
    .slot-cancel-button {
      box-sizing: border-box;
      width: 161px;
      height: 53px;
      background: $white;
      border: 1px solid $primary;
      box-shadow: $box-shadow;
      border-radius: 8px;
      font-family: $Roboto;
      font-weight: $weight-600;
      font-size: $font-size-16px;
      color: $primary;
      align-self: flex-end;
      &:disabled {
        color: $text-color-medium !important;
        pointer-events: none;
        cursor: not-allowed !important;
        user-select: none;
      }
    }
  }
  .loading-wrapper {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    .loading-spinner {
      position: static;
    }
  }
}
