@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.booked-slot {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .slot-card {
    display: flex;
    justify-content: space-between;
    padding: 1rem;
    box-sizing: border-box;
    background: $white;
    border: $button-group-border;
    border-radius: $border-radius;

    &-userdetails {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .user-labels-container {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .user-label {
        font-family: $Roboto;
        font-weight: $weight-600;
        font-size: $font-size-16px;
        line-height: 24px;
        color: $gray-900;
      }

      .time-label {
        font-family: $Roboto;
        font-weight: $weight-400;
        font-size: $font-size-12px;
        line-height: 18px;
        letter-spacing: 0.4px;
        color: $gray-900;
      }
    }

    button {
      font-weight: $weight-700;
      font-size: $font-size-16px;
      line-height: 24px;
      color: $primary;
    }
  }
}
