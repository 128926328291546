@import '../../../../../assets/styles/theme';

.slot-add-popup-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  min-width: 23.25rem;
  max-width: 23.25rem;
  border-radius: 0.5rem;
  padding: 1.5rem;
  gap: 1rem;
  .slot-edit-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slot-edit-label {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $text-color-primary;
    }
    .slot-edit-icon-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
      &:hover {
        background-color: $bg-color;
      }
    }
  }
  .slot-edit-content-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
    .slot-edit-select-content {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .slot-select {
        box-sizing: border-box;
        min-width: 7rem;
        width: 7rem;
        max-width: 7rem;
      }
    }
    .slot-date-label {
      margin-top: 0.5rem;
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-14px;
      font-weight: $weight-400;
      color: $text-color-primary;
    }
    .slot-warning-message-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .slot-warning-message {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-14px;
        font-weight: $weight-400;
        line-height: 1.3125rem;
        color: $warning-color-1;
      }
    }
  }
  .slot-action-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    margin-top: auto;
    .slot-cancel-btn {
      box-sizing: border-box;
      height: 3rem;
      width: 7.5rem;
      border-radius: 0.5rem;
      background: $white;
      border: 0.0625rem solid $primary;
      box-shadow: $box-shadow;
      font-family: $Roboto;
      font-weight: $weight-700;
      font-size: $font-size-16px;
      color: $primary;
    }
    .slot-save-btn {
      box-sizing: border-box;
      height: 3rem;
      width: 7.5rem;
      border-radius: 0.5rem;
      background: $primary;
      border: 0.0625rem solid $primary;
      box-shadow: $box-shadow;
      font-family: $Roboto;
      font-weight: $weight-700;
      font-size: $font-size-16px;
      color: $white;
    }
  }
}
