@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';

.loading-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 100;
  background-color: $white;

  @media (max-width: $md-max) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .loading-spinner {
    width: 56px;
    height: 56px;
    position: relative;
    top: 50%;
    left: 35%;
    animation: spinner 1.5s linear infinite;

    @media (max-width: $md-max) {
      top: auto;
      left: auto;
    }
  }
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
