@import '../../../../assets/styles/theme';

.tag-disabled {
  color: $disabled-button-color !important;
  border: 1px solid $disabled-button-color !important;
  pointer-events: none;
}

.tag-container {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 29px;
  background: $white;
  border: $checkbox-border;
  box-shadow: $tag-box-shadow;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  user-select: none;
  text-align: center;
  font-family: $Roboto;
  font-weight: $weight-400;
  font-size: $textFontSize-md;
  color: $text-color-primary;
  text-align: center;
  flex-grow: 1;
  .tag-label {
    box-sizing: border-box;
    flex-grow: inherit;
    text-align: inherit;
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
    margin-inline: 5px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    @media screen and (min-width: 320px) and (max-width: 767px) {
      width: 2rem;
    }
  }
  .tag-icon-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    .tag-icon {
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      object-fit: contain;
      object-position: center;
      &:last-child {
        cursor: pointer;
      }
    }
  }
}
