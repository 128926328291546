@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';
.component-loading-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex: 1;
  z-index: 6;
  background-color: $white;
}
.loading-spinner {
  width: 56px;
  height: 56px;
  animation: spinner 1.5s linear infinite;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
