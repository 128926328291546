@import '../../../assets/styles/variable';
@import '../../../assets/styles/media';
@import '../../../assets/styles/theme';
@import '../../../assets/styles/mixin';

@mixin tech-and-interview-level {
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
}

.admin-recruiter-outer-container {
  background-color: $gray-td-back;
  width: 100%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  font-family: $Roboto;
  min-height: 100vh;

  @media screen and (max-width: 50rem) {
    width: calc(100% - 3rem);
  }
}

.admin-recruiter-top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.recruiter-card-lower {
  display: flex;
  align-items: center;
  width: max-content;
}

.ap-card-exp {
  margin-right: 0.5rem;
}
.admin-recruiter-filters-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ap-btn-group-container {
  display: flex;
  justify-content: space-between;
  padding-top: 2rem;

  @media screen and (max-width: $xs-max) {
    width: 100%;
    flex-direction: column;
  }
}

.ap-save-update {
  color: $gray-900;
}

.ap-mark-buttons {
  display: flex;
  gap: 1rem;
  @media screen and (max-width: $xs-max) {
    margin-bottom: 0.5rem;
    position: sticky;
    top: 0;
  }
}

.ta-filter-count {
  background-color: $primary;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  border-radius: 50%;
  transform: translate(2.2rem, -3.4rem);
  position: absolute;
  z-index: 3;
  font-size: 0.75rem;
}

.ap-search {
  margin-left: 1rem;

  @media screen and (max-width: $xs-max) {
    margin: 0.5rem 0 0 0;
  }
}

.serached-options-image {
  display: flex;
  color: $white !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
}

.admin-recruiter-filter-buttons {
  width: min-content;
  height: 3rem;
  width: 3rem;
  border: 0.0625rem solid $primary;
}
.active-btn {
  box-shadow: 0 0 0 0.25rem $primary-light;
  background-color: $primary-light;
}

.ap-gray-td {
  background-color: $gray-td-back;
}

.admin-recruiter-button-group {
  margin-bottom: 1rem;
  padding: 0.25rem 0 0.25rem 0;
}
.admin-recruiter-heading {
  font-size: $tabHeadingFontSize;
  font-weight: $weight-600;
  font-family: $Montserrat;
  color: $text-color-1;
  line-height: 3rem;
}
.separatorLine {
  border-bottom: 0.0625rem solid $separator;
  margin: 1rem 1.5rem;
}
.admin-recruiter-searched-heading {
  font-weight: $weight-600;
  font-family: $Montserrat;
  font-size: $bodyFontSize;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.admin-modal-header {
  font-weight: $weight-700;
  margin-bottom: 0.5rem;
}

.sort-btn-container {
  position: relative;
}

.filter-modal-btn-container {
  position: relative;
}

.sort-btn-ontainer {
  position: relative;
}

.ap-clear-top-button {
  border-color: $primary;
  color: $primary;
  @media screen and (max-width: $xs-max) {
    margin-right: 0.25rem;
  }
}
.notice-bold-text {
  color: black;
}

.admin-panle-list-sort-by {
  padding: 1.25rem;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $modal-box-shadow;
  z-index: 10;
  position: absolute;
  top: 2.625rem;
}

.tech-bg {
  @include tech-and-interview-level();
  background-color: $tags-color;
  padding: 0.375rem 0.75rem;
  font-weight: $weight-400;
  font-size: $font-size-12px;
  font-family: $Roboto;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: max-content;
  white-space: nowrap;
  line-height: 0.75rem;
  color: $text-color-1;
  height: 2rem;
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
}

.recruiter-img {
  border-radius: 50%;
  min-height: 2.5rem;
  min-width: 2.5rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  margin: 0.25rem 1rem 0 0;
}

.tech-plus {
  @include tech-and-interview-level();
  margin-left: 0.5rem;
  background-color: $tags-color;
  padding: 0.375rem 0.5rem;
  font-weight: $weight-400;
  font-size: $font-size-12px;
  font-family: $Roboto;
  color: $text-color-1;
  height: 2rem;
  display: flex;
  align-items: center;
  width: max-content;
}

.interview-level-bg {
  @include tech-and-interview-level();
}

.recruiter-checkbox-container {
  height: 0.0781rem;
  width: 0.0781rem;
  margin: 0 1rem 0.375rem 0.25rem;
}

.header-first {
  display: flex;
}

.recruiter-header-cell {
  text-align: start;
  padding: 0.8rem 1rem;
  font-weight: $weight-600;
  color: $gray-500;
}

.transparent-backdrop {
  width: 100vw;
  height: 100vh;
  z-index: 8;
  position: fixed;
  right: 0;
  top: 0;
  box-shadow: $modal-box-shadow;
  border-radius: $border-radius;
  background-color: transparent;
}

.recruiter-filter-modal-outer {
  position: absolute;
  right: 0;
  top: 3rem;
  z-index: 10;
}

.recruiter-header-row {
  color: $gray-500;
}

.recruiter-row-cell {
  padding: 0.563rem 1rem;
  cursor: pointer;
}

.recruiter-first-row-cell {
  padding: 0.563rem 1rem 0.563rem 1.5rem;
}

.recruiter-name {
  font-weight: $weight-600;
  margin-bottom: 0.375rem;
  min-width: max-content;
  color: $gray-900;
  line-height: 1.5rem;
}

.recruiter-gray-text {
  color: $gray-500;
  font-weight: 400;
  line-height: 1.5rem;
  font-size: 1rem;
}

.profile-arrow {
  height: 0.875rem;
  width: 0.875rem;
}

.recruiter-cell-tech-stack-div {
  display: flex;
  align-items: center;
  color: $primary;
}

.recruiter-cell-project-stack-div {
  display: flex;
  align-items: center;
  padding-left: 0.625rem;
}

.align-center {
  text-align: center;
}

.drawer-body-ta {
  height: 100%;
  flex-grow: 1;
  overflow-y: scroll;
}

.blue-text {
  color: $primary;
}

.black-text-ta {
  color: $count-text;
  cursor: pointer;
}

.recruiter-sorting-parent {
  position: absolute;
  z-index: 3;
  right: 0;
  top: 3.4rem;
  @media screen and (min-width: 20rem) and (max-width: 47.937rem) {
    right: unset;
  }
}

.recruiter-filters-parent {
  position: absolute;
  left: -27.2vw;
  top: 3.4rem;
  z-index: 10;
  max-height: 70vh;
  max-width: 60vw;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.375rem rgb(0 0 0 / 25%);

  background-color: $white;
  @media screen and (max-width: 46.875rem) {
    left: 0;
  }
  @media screen and (max-width: $xs-max) {
    max-height: 60vh;
    max-width: 12.5rem;
    right: -1rem;
  }
  @media screen and (min-width: $xl-min) {
    max-height: 60vh;
    max-width: 90vw;
    left: auto;
    right: 0;
  }
}

.recruiter-name-designation {
  display: flex;
  align-items: center;
  @media screen and (max-width: $xs-max) {
    margin-left: 0.5rem;
  }
}

.recruiter-first-header {
  padding-left: 1.5rem;
}

.recruiter-designation {
  margin-top: 0.125rem;
  min-width: max-content;
  font-weight: 400;
  line-height: 1.5rem;
}

.recruiter-img-name-container {
  display: flex;
  align-items: center;
}

.admin-recruiter-img-container {
  min-height: 2.5rem;
  min-width: 2.5rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  margin: 0.25rem 1rem 0 0;
}

.admin-recruiter-img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.recruiter-toast {
  width: 28.063rem;
  display: flex;
  padding-bottom: 0.5rem;
  .toast-close-button {
    align-self: flex-start;
    margin-top: 0.375rem;
  }

  .blue-text {
    color: $primary;
    font-size: $font-size-14px;
    font-weight: $weight-700;
    padding: 0.5rem;
    text-decoration: underline;
    background-color: $tags-color;
    width: max-content;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .recriuter-toast-text {
    margin-top: 0.375rem;
    margin-bottom: 0.625rem;
  }

  .toast-arrow-right {
    align-self: flex-start;
  }
}
.filters-search-section {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 29.687rem) {
    flex-direction: column;
  }
}
.ap-top-bar-wrapper {
  display: flex;
  margin-bottom: 2rem;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: $xs-max) {
    flex-direction: column;
  }
  @media screen and (max-width: 46.875rem) {
    display: grid;
    grid-row-gap: 1.5rem;
    align-items: flex-start;
  }
}
.recruiter-img-alt {
  background-color: $primary;
  color: white;
  min-height: 2.5rem;
  min-width: 2.5rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  border-radius: 50%;
  margin: 0.25rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ap-search-msg {
  font-family: $Montserrat;
  color: $disabled-text-color;
}

.ap-searched-name {
  font-family: $Montserrat;
}

.ap-delete-modal {
  width: 26%;
  padding: 1.5rem;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $modal-box-shadow;
  z-index: 10;

  .heading {
    font-weight: $weight-600;
    line-height: 2.25rem;
    font-family: $Montserrat;
    color: $gray-700;
    margin-bottom: 1rem;
  }
}

.searched-recruiter-tags {
  color: $gray-blue;
}

.ap-delete-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 1.4rem;
  margin-top: 1.4rem;
}

.ap-delete-cancel-button {
  border-color: $primary;
  color: $primary;
}

.ap-mark-top-button {
  border-color: $primary;
  color: $primary;
  width: max-content;
  min-width: 7.5rem;
  height: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.ap-delete-top-button {
  border-color: $primary;
  color: $primary;
  min-width: 7.5rem;
  height: 3rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

.ap-delete-button {
  background-color: $primary;
  color: $white;
}

.ap-delete-header {
  font-family: $Montserrat;
  font-weight: 600;
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
}

.ap-delete-notice-text {
  color: $gray-500;
}

.admin-project-td {
  padding-left: 1rem;
}

.ap-delete-modal-backdrop {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: $modal-backdrop-background;
}
.techDetails {
  padding: 0 1.5rem;
}
.techLabel {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-600;
  font-size: $font-size-14px;
  line-height: 1.3125rem;
  color: $text-color-1;
  margin-bottom: 0.5rem;
}
.techStackLabel {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-600;
  font-size: $font-size-14px;
  line-height: 1.3125rem;
  color: $text-color-1;
  margin: 1rem 0 0.5rem 0;
}
.techName {
  @include common-tech-label(0.0625rem solid $gray-blue);
  background: $white;
  line-height: 1.375rem;
}
.techStakName {
  @include common-tech-label(0.0625rem solid $text-color-2, 0.5rem 1rem);
  background: $white;
  margin-bottom: 0.5rem;
  flex-wrap: wrap;
  row-gap: 0.5rem;
  max-height: 5.625rem;
  overflow-y: scroll;
}
.techStackNames {
  border: 0.0625rem solid $text-color-2;
  box-shadow: $box-shadow;
  border-radius: 0.5rem;
  padding: 0.3125rem 1rem;
  height: fit-content;
  margin-right: 0.5rem;
}

.tooltip-container {
  display: flex;
  justify-content: center;
  height: min-content;
}
.tooltipCountTS {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $text-color-1;
  background: $white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0.25rem 0.25rem $gray-300;
  display: flex;
  flex-direction: column;
  z-index: 9;
}
