@import '../../../../../assets/styles/theme';

.g-event-popup-wrapper {
  box-sizing: border-box;
  min-height: 149px;
  min-width: 372px;
  border-radius: 8px;
  background: $white;
  box-shadow: $modal-box-shadow;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 24px;
  padding-inline: 16px;
  padding-block: 24px;
  .g-event-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    .g-event-label {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $text-color-primary;
    }
    .g-event-icon-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      cursor: pointer;
      border-radius: 8px;
      &:hover {
        background-color: $bg-color;
      }
    }
  }
  .g-event-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    .g-event-title {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $text-color-primary;
    }
    .g-event-timming-label {
      box-sizing: border-box;
      font-family: $Roboto;
      font-weight: $weight-400;
      font-size: $font-size-14px;
      color: $text-color-primary;
    }
  }
}
