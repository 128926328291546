@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.autocomplete-wrapper {
  box-sizing: border-box;
  padding: 0.6875rem 1rem;
  background: $white;
  border: 0.063rem solid $gray-500;
  box-shadow: $box-shadow;
  border-radius: 0.5rem;
  position: relative;
  .autocomplete-field-wrapper {
    box-sizing: border-box;
    display: flex;
    gap: 0.5rem;
    height: 100%;
    align-items: center;
    .autocomplete-tag-wrapper {
      box-sizing: border-box;
      display: flex;

      flex-wrap: wrap;
      align-items: center;
      flex-grow: 1;
      gap: 0.625rem;
      .tag-style {
        flex-grow: 0;
      }
    }
    .email-icon {
      display: block;
      align-self: center;
      box-sizing: border-box;
      object-fit: contain;
      object-position: center;
      filter: invert(45%) sepia(9%) saturate(855%) hue-rotate(182deg) brightness(94%) contrast(91%);
    }
    .autocomplete-input {
      padding: 0;
      box-sizing: border-box;
      font-size: $textFontSize;
      font-family: $Roboto;
      font-weight: $weight-400;
      flex-grow: 1;
      width: 6.25rem;
      line-height: 1.5rem;
      ::-webkit-input-placeholder {
        color: $gray-500;
      }
      &::placeholder {
        font-size: $textFontSize;
        font-family: $Roboto;
        font-weight: $weight-400;
        color: $gray-500;
      }
    }
  }
  .dropdown-loading {
    box-sizing: border-box;
    position: static;
    height: 9.375rem;
    @media only screen and (min-width: $lg-min) {
      .loading-spinner {
        top: 25%;
        left: 35%;
      }
    }
  }
  .autocomplete-dropdown-wrapper {
    overflow: auto;
    max-height: 15.625rem;
    width: 100%;
    max-width: 24rem;
    box-sizing: border-box;
    position: absolute;
    background-color: $white;
    border-radius: 0.5rem;
    top: 115%;
    left: 0%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    box-shadow: $box-shadow-notification;
    .autocomplete-option {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 16px;
      cursor: pointer;
      &:hover {
        background: $list-hover-color;
        border-radius: 0 0 0.5rem 0.5rem;
      }
      .user-image {
        box-sizing: border-box;
        height: 3.5rem;
        width: 3.5rem;
        border-radius: 12.5rem;
        object-fit: contain;
        object-position: center;
      }
      .user-detail {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        flex-grow: 1;
        .user-name {
          font-family: $Roboto;
          font-size: $textFontSize;
          font-weight: $weight-600;
          color: $text-color-primary;
        }
        .user-email {
          font-family: $Roboto;
          font-size: $textFontSize-md;
          font-weight: $weight-400;
          color: $text-color-medium;
        }
      }
    }
    .selectedOption {
      background: $tags-color;
      border-radius: 0 0 0.5rem 0.5rem;
    }
  }
}

.autocomplete-shadow {
  border: 0.0625rem solid $primary;
  box-shadow: $settings-autocomplete-boxShadow;
}

.no-results-image {
  box-sizing: border-box;
  display: block;
  width: auto;
  height: 12.5rem;
  object-fit: contain;
  object-position: center;
}

.no-result-text {
  color: $gray-700;
  font-size: 1rem;
  font-family: $Roboto;
  font-weight: $weight-500;
  text-align: center;
}
