@import '../../../../assets/styles/variable';
@import '../../../../assets//styles/theme';
.tab-view {
  display: flex;
  flex-direction: row;
  background-color: $white;
  & > :first-child {
    & > :first-child {
      padding: 1.5rem 1.5rem 0 1.5rem;
    }
  }
}
.user-details {
  & > :first-child {
    min-height: 0px;
  }
  & > :last-child {
    min-height: auto;
  }
  .ta-user-detail-wrapper {
    padding-left: 1.5rem;
  }
}
.no-results-container {
  height: 200px;
}
.nr-main-container {
  display: flex;
  justify-content: center;
  margin: 3.125rem 0;
}
.desc {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 3rem;
}
.desc-heading {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 1.5rem;
  line-height: 2.25rem;
  text-align: center;
  color: $text-color-1;
}
.desc-subtext {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: $text-color-2;
}
.toast-container-detail {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  flex-grow: 1;
  z-index: 9;
  .toast-title {
    box-sizing: border-box;
    font-family: $Roboto;
    font-size: $font-size-14px;
    font-weight: $weight-400;
    color: $text-color-primary;
  }
  .toast-detail-button {
    box-sizing: border-box;
    font-family: $Roboto;
    font-weight: $weight-700;
    font-size: $font-size-14px;
    color: $primary;
    border: none;
    text-align: left;
    justify-content: flex-start;
    box-shadow: none;
    padding: 0;
  }
}
.dashboard-loader {
  height: 319px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: auto;
  .loading-spinner {
    left: auto;
    top: auto;
  }
}
.delete {
  margin-top: 0 !important;
}
.drawer-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.errorPage{
  &>:first-child{
    height: 40vh;
  }
}