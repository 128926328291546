@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';
@import '../../../../../assets/styles/variable';

.user-team-wrapper {
  box-sizing: border-box;
  padding-block: 1.5rem;
  padding-inline: 1rem;
  .user-team-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 0.0625rem solid $calendar-gray;
    border-bottom: none;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    padding: 1rem;
    @media screen and (max-width: $smx-min) {
      gap: 1rem;
      flex-wrap: wrap;
    }
    .user-team-header-search {
      box-sizing: border-box;
      min-height: 3rem;
      min-width: 20rem;
      height: 3rem;
      width: 20rem;
      max-height: 3rem;
      max-width: 20rem;
      @media screen and (min-width: 20rem) and (max-width: 47.938rem) {
        min-width: auto;
        max-width: none;
        width: calc(100% - 2rem);
      }
    }
    .user-team-header-admin-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .user-team-header-admin-label {
        box-sizing: border-box;
        display: flex;
        align-items: center;
      }
      .user-team-admin-count-label {
        box-sizing: border-box;
        height: 1.5rem;
        background: $secondary;
        border-radius: 0.5rem;
        border: none;
        color: $primary;
        font-family: $Roboto;
        font-size: $font-size-12px;
        font-weight: $weight-600;
        padding: 0;
      }
    }
  }
  .user-team-search-no-result {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 21.125rem;
    border: 0.0625rem solid $calendar-gray;
    margin-bottom: 1.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    gap: 2.5rem;
    @media screen and (max-width: $md-min) {
      flex-wrap: wrap;
    }
    .user-team-no-result-image {
      box-sizing: border-box;
      object-position: center;
      object-fit: contain;
      height: 10rem;
      width: 10rem;
    }
    .user-team-no-user-text-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.5rem;
      .user-team-no-user {
        box-sizing: border-box;
        font-family: $Montserrat;
        font-size: $font-size-24px;
        font-weight: $weight-600;
        text-align: center;
        color: $text-color-primary;
      }
      .user-team-not-found {
        box-sizing: border-box;
        font-family: $Montserrat;
        font-weight: $weight-600;
        font-size: $font-size-16px;
        text-align: center;
        color: $text-color-medium;
      }
    }
  }
  table {
    border-collapse: collapse;
    .table-header {
      position: sticky;
      top: -0.313rem;
      background-color: white;
      z-index: 1;
    }
  }

  .table-header-cell {
    border-bottom: none;
    padding-left: 1.5rem;
  }
  tr {
    border-bottom: 0.0625rem solid $gray-200;
    &:last-child {
      border-bottom: none;
    }
  }
  .user-team-text {
    box-sizing: border-box;
    user-select: none;
    font-family: $Roboto;
    font-size: $textFontSize-md;
    font-weight: $weight-600;
    margin-bottom: 1rem;
    color: $text-color-primary;
    margin-bottom: 0;
  }
  .user-team-button {
    width: 9.813rem;
    height: 3rem;
    margin-left: auto;
    &:disabled {
      background: $disabled-text-color;
      border: 0.0625rem solid $disabled-text-color;
      box-shadow: $box-shadow;
      border-radius: 0.5rem;
    }
  }
  .user-team-autocomplete-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
  }
  .table-outer-container {
    box-sizing: border-box;
    border: 0.0625rem solid $calendar-gray;
    border-bottom-left-radius: 0.625rem;
    border-bottom-right-radius: 0.625rem;
    background-color: $white;
    margin-bottom: 1.5rem;
    min-height: auto;
    max-height: 20.938rem;
    overflow: auto;
    .table-main-container {
      box-sizing: border-box;
      overflow: visible;
      .table-sub-container {
        box-sizing: border-box;
        background-color: $white;
        .table-header-cell {
          box-sizing: border-box;
          background-color: transparent;
        }
      }
    }
  }
  .table-body-tr {
    box-sizing: border-box;
    &:hover {
      box-shadow: none;
    }
    &:nth-child(even) {
      background-color: $white;
    }
  }

  .td-decoration-admin {
    box-sizing: border-box;
    vertical-align: middle;
    padding-block: 1rem;
    padding-inline: 1.5rem;
  }
}
.user-team-table-wrapper {
  box-sizing: border-box;
  position: relative;
}
.user-team-loading {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  .loading-spinner {
    position: static;
  }
}
