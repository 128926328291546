@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';
@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/mixin';

.slot-request-modal {
  width: 30rem;
  background-color: $white;
  border-radius: 0.5rem;
  box-shadow: $modal-box-shadow;
  position: relative;

  .slot-loading-wrapper {
    @include common-flex();
    justify-content: center;
    align-items: center;
    height: 26.32rem;
    border-radius: 0.5rem;
    z-index: 100;
    background-color: $white;

    .loading-spinner {
      width: 3.5rem;
      height: 3.5rem;
      animation: spinner 1.5s linear infinite;
    }

    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(360deg);
      }
    }
  }

  @media screen and (max-width: $md-min) {
    margin: 1rem;
  }

  .modal-close-icon {
    padding: 0.5rem;
    height: 1.5rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;

    &:hover {
      cursor: pointer;
      border-radius: 0.5rem;
      background-color: $list-hover-color;
    }
  }

  .heading {
    margin-bottom: 1rem;
  }

  .slot-request-form-hint {
    color: $text-color-1;
    line-height: 1.5rem;
    font-weight: $weight-400;
    font-size: 1rem;
  }

  .slot-request-form {
    @include common-flex-column();
    gap: 1rem;
    .date-input {
      position: relative;
    }

    .form-field {
      @include common-flex-column();
      gap: 0.5rem;

      &.slot-timing-form-field {
        gap: 1rem;
      }

      .select {
        @media screen and (max-width: $md-min) {
          min-width: auto;
        }
      }

      .clipped-select-wrapper {
        margin-bottom: 0;
      }

      .slot-timing {
        display: flex;
        gap: 1.25rem;
        align-items: center;

        .separator {
          width: 0.5rem;
          height: 0.0625rem;
          background: $black;
        }

        .time {
          flex: 1;

          .select-arrow-icon {
            display: none;
          }
        }
      }

      .slot-date-picker-wrapper {
        border-radius: 0.5rem;
        border: $tech-item-border;
        background-color: $white;
        padding: 0.5rem 2.5rem;
        position: absolute;
        z-index: 5;
        top: 5.75rem;
        right: 0;
        left: 0;
      }

      .clipped-select-wrapper .primary-select,
      .clipped-select-wrapper .clipped-select-with-icon .secondary-select {
        flex: 1;
      }

      .clipped-select-wrapper .clipped-select-with-icon {
        flex: 1;

        .close-icon {
          display: none;
        }
      }

      .stackLoi-row-container {
        @include common-flex();
        width: unset;

        .input-label {
          flex: 1;
        }
      }
    }
  }
}

.modal-buttons {
  @include common-flex();
  justify-content: flex-end;
  gap: 1.5rem;
  margin-right: 1rem;
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 0.0625rem solid $separator;

  .cancel-button,
  .request-button {
    width: 7.5rem;
    height: 3rem;
    font-weight: $weight-700;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .cancel-button {
    border: 0.0625rem solid $primary;
    color: $primary;
  }
}

@media screen and (max-width: $sm-min) {
  .slot-request-modal {
    padding: 1rem;

    .slot-request-form {
      .form-field {
        .slot-date-picker-wrapper {
          right: -1rem;
          left: -1rem;
          padding: 0.5rem 0;
        }
      }
    }
  }
}

.slot-request-buttons {
  display: flex;
  gap: 2rem;

  .slot-request-add-button,
  .slot-request-remove-button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
}
.slot-request-slots {
  @include common-flex();
  align-items: center;
  gap: 0.5rem;
}
.slot-request-endTime {
  @include common-flex();
}
.slot-request-startTime {
  @include common-flex();
}

.error-message {
  color: $error;
  font-size: 1rem;
  padding: 0;
  margin: 0;
}
.scrollable-container {
  @include common-flex-column();
  gap: 1rem;
  overflow-y: scroll;
  height: 26rem;
  padding-right: 0.75rem;

  &.no-scroll {
    overflow-y: hidden;
  }
}

.slot-request-clipped-fields {
  @include common-flex();
}
.scrollable-container::-webkit-scrollbar {
  width: 0.25rem !important;
  display: block !important;
  height: 3rem !important;
}

.scroll-container {
  @include common-flex-column();
  max-height: 23rem;
  gap: 1.5rem;
  padding-right: 0.75rem;
}
.start-time,
.end-time {
  @include common-flex();
}
.slot-header-container {
  @include common-flex();
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  background-color: $bg-color;
  border-radius: 0.5rem 0.5rem 0 0;
  gap: 1rem;
  border-bottom: 0.0625rem solid $separator;
}
.slot-header {
  color: $text-color-1;
  font-size: 1.5rem;
  font-family: $Montserrat;
  font-weight: $weight-600;
}
.parent-modal-container {
  padding: 1.5rem;
  padding-right: 0.75rem;
}
.slot-delete-btn {
  cursor: pointer;
}
.warning-message {
  @include common-flex();
  margin-top: 0.5rem;
  gap: 0.5rem;
  align-items: flex-start;
  padding-top: 1rem;
  .warning-icon {
    margin-top: 0.25rem;
  }

  .warning-text {
    color: $gray-500;

    span {
      color: $primary;
      font-weight: $weight-600;

      &:hover {
        border-radius: 0.25rem;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}
.slot-timing-title {
  margin-bottom: -0.5rem;
}
