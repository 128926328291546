@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.months-toggle {
  display: flex;
  align-items: center;

  .chevron {
    border: none !important;
    box-shadow: none;
    padding: 0;
    width: 40px;
    height: 40px;
  }
  .disable {
    transform: scale(1.175);
  }
  .month-name {
    font-family: $Montserrat;
    white-space: nowrap;
  }
}
@media screen and (max-width: $sm-max) {
  .months-toggle {
    flex: 1;
    justify-content: center;
    .left {
      position: absolute;
      left: 0;
    }
    .right {
      position: absolute;
      right: 0;
    }
  }
}
