$primary: #2c74d6;
$secondary: #eef7fe;
$background-color: #f9fafc;
$separator: #eaecf0;
$primary-button-hover: #2444a4;
$text-color-1: #101828;
$text-color-2: #667085;
$disabled: #98a2b3;
$calendar-gray: #d1d6df;
$error: #ff5252;
$warning: #f39b17;
$warning-color-1: #f39b17;
$warning-color-2: #fdf2e1;
$success: #04bfa4;
$chip-color-red: #ffecef;
$chip-color-yellow: #fdf8e1;
$chip-color-green: #dcf4f0;
$tertiary: #3f3f3f;

$black: #000;
$white: #fff;
$grey: #f2f2f8;
$frozen-white: #fcfcfd;
$gray: #f2f2f8;
$cool-gray: #f2f2f2;
$light-gray-bg: #f4f6f9;
$blue: #2196f3;
$black-river: #344054;
$gray-blue: #667085;
$gray-900: #101828;
$gray-700: #344054;
$gray-500: #667085;
$gray-300: #d0d5dd;
$gray-200: #eaecf0;
$gray-500: #667085;
$gray-83: #d4d4d4;
$charcoal: #344054;
$gray-td-back: #f9fafc;
$dialog-box-button-color: #848484;
$list-hover-color: #f9fafc;
$tags-color: #eef7fe;
$pulse-color: #dee4ee;
$animation-color: #a8bdf3;

$input-box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #eef7fe;

$gray-td-back: #f9fafc;
$project-tag-text-color: #04bfa4;
$project-tag-bg: #dcf4f0;
$project-badge-color: #eaf6f4;
$pagination-number-backgroud: #eef7fe;
$disabled-text-color: #98a2b3;

$select-hover-box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.1);
$select-hover-color: #b2b5b8;
$nav-select-background: #e3f2fe;
$divider-background: #eaecf0;
$warning-bg: #fdf8e1;
$warning-text: #f39b17;
$count-text: #101828;
$disabled-button-color: #98a2b3;
$count-text: #101828;
$disabled-button-color: #98a2b3;

$tech-item-border: 0.0625rem solid #eaecf0;
$nav-select-background: #e3f2fe;
$tech-bg-color: #d9d9d9;
$text-color: #505660;
$tech-bg-color: #d5d5d5;
$img-bg-color: #eef7fe;
$weight-300: 300;
$weight-400: 400;
$weight-500: 500;
$weight-600: 600;
$weight-700: 700;

$Roboto: 'Roboto', 'sans-serif';
$Montserrat: 'Montserrat', 'sans-serif';

$font-size-10px: 0.625rem;
$font-size-12px: 0.75rem;
$font-size-14px: 0.875rem;
$font-size-20px: 1.25rem;
$font-size-48px: 3rem;
$font-size-32px: 2rem;
$font-size-24px: 1.5rem;
$font-size-18px: 1.125rem;
$font-size-16px: 1rem;

$headerFontSize-md: 3.75rem;
$headerFontSize: 4.5rem;
$subHeaderFontSize-md: 2.5rem;
$subHeaderFontSize: 3.75rem;
$titleFontSize-md: 1.5rem;
$tabHeadingFontSize: 2rem;
$titleFontSize: 2.25rem;
$tabHeadingFontSize: 2rem;
$bodyFontSize-md: 1.125rem;
$bodyFontSize: 1.5rem;
$body1FontSize-md: 1.125rem;
$body1FontSize: 1.125rem;
$textFontSize-md: 0.875rem;
$textFontSize: 1rem;
$captionFontSize: 0.875rem;
$errorFontSize: 0.875rem;

$settings-autocomplete-boxShadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #eef7fe;
$card-box-shadow: 0 0.125rem 1rem rgba(7, 81, 176, 0.04);
$box-shadow-notification: 0 0.1875rem 0.375rem rgba(0, 0, 0, 0.16);
$box-shadow-searchbar: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #eef7fe;
$tag-box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
$box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
$backButton-box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
$refreshButton-box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
$modal-box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.25);
$button-group-box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
$input-box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem #eef7fe;
$slot-level-card-box-shadow: 0 0.125rem 1rem rgba(44, 116, 214, 0.04);
$modal-box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
$chevron-box-shadow: 0 0 0.25rem rgba(44, 116, 214, 0.16);
$add-slot-card-box-shadow: 0 0.125rem 1rem rgba(44, 116, 214, 0.04);
$slot-card-hover-box-shadow: 0 0.1875rem 0.375rem rgba(16, 24, 40, 0.16);
$drawer-buttons-box-shadow: 0 -0.25rem 0.25rem rgba(0, 0, 0, 0.08);
$selector-dropdown-box-shadow: 0 0.25rem 0.375rem rgba(16, 24, 40, 0.25),
  0 0.75rem 1rem rgba(16, 24, 40, 0.08);
$tooltip-box-shadow: 0.25rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
$rewards-first-box-shadow: linear-gradient(180deg, rgba(238, 247, 254, 0) 0%, #eef7fe 100%);
$rewards-second-box-shadow: linear-gradient(180deg, rgba(220, 244, 240, 0) 0%, #dceff4 100%);
$rewards-third-box-shadow: linear-gradient(180deg, rgba(253, 248, 225, 0) 0%, #fdf8e1 100%);
$rewards-profile-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
$rewards-overlay: rgba(52, 64, 84, 0.7);

$slot-card-box-shadow: -0.0625rem 0 0.0625rem rgba(0, 0, 0, 0.25);
$date-hover-box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.05), 0 0 0 0.25rem #eef7fe;
$select-li-box-shadow: 0 0.125rem 0.375rem rgba(52, 64, 84, 0.15);
$table-box-shadow: 0 0.25rem 0.25rem $gray-300;
$tooltip-new-box-shadow: 0 0.25rem 0.5rem -0.25rem rgba(16, 24, 40, 0.16),
  0 -0.25rem 0.5rem -0.125rem rgba(16, 24, 40, 0.08);

$level-tag-color: #eef7fe;
$toast-success: #d1fadf;
$toast-success-light: #ecfdf3;
$modal-backdrop-background: rgba(52, 64, 84, 0.7);
$logout-button-box-shadow: 0 0 0.375rem rgba(16, 24, 40, 0.25);
$border-radius: 0.5rem;
$border-radius-6: 0.375rem;
$border-radius-16: 1rem;
$border: 0.0625rem solid $gray-300;
$input-box-border: 0.0625rem solid $gray-500;
$backButton-border: 0.063rem solid #d0d5dd;
$refreshButton-border: 0.063rem solid #2196f3;
$button-group-border: 0.0625rem solid #eaecf0;
$checkbox-border: 0.0625rem solid #667085;
$button-group-horizontal-first-child: 0.5rem 0rem 0rem 0.5rem;
$button-group-horizontal-last-child: 0rem 0.5rem 0.5rem 0rem;
$button-group-vertical-first-child: 0.5rem 0.5rem 0rem 0rem;
$button-group-vertical-last-child: 0rem 0rem 0.5rem 0.5rem;
$dialog-box-border: 0.0625rem solid #2c74d6;
$primary-border: 0.0625rem solid #2c74d6;
$media-tablet: 46.5rem;
$media-phone: 23.438rem;
$shimmer-background: linear-gradient(to right, #f6f6f6 8%, #f0f0f0 18%, #f6f6f6 33%);
$notification-heading: #110011;
