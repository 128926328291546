@import 'assets/styles/variable';
@import 'assets/styles/media';
@import 'assets/styles/theme';

.panel-dashboard-wrapper {
  display: flex;
  width: 100%;
  overflow-x: hidden;
  flex-direction: column;
  padding: 1.5rem;
  gap: 1.5rem;
  box-sizing: border-box;

  @media screen and (max-width: $sm-max) {
    padding: 1rem;
  }

  .search-circle {
    border-radius: 50%;
    width: 3.5rem;
    height: 3.5rem;
  }
  .slot-notification {
    height: 4.5rem;
    display: flex;
    background-color: $white;
    box-shadow: $card-box-shadow;
    border-radius: 0.5rem;
    padding: 0.5rem 1.5rem;
    box-sizing: border-box;
    align-items: center;
    position: relative;
    cursor: pointer;

    .cross-icon {
      position: absolute;
      top: 1rem;
      right: 1.5rem;
      padding: 0.5rem;
      border-radius: 0.5rem;
      &:hover {
        cursor: pointer;
        background-color: $bg-color;
      }
    }
    .ta-detail {
      display: flex;
      align-items: center;
      height: 100%;

      .ta-name {
        padding-left: 0.5rem;
        height: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        font-weight: $weight-600;
        border-left: 0.0625rem solid $gray-300;
        span {
          color: $gray-500;
          margin-right: 0.25rem;
          font-weight: $weight-400;
        }
      }
    }

    .view-details-btn {
      border: none;
      box-shadow: none;
      color: $primary;
      white-space: nowrap;
      font-weight: $weight-600;
      border-radius: 0.25rem;
      margin-right: 4rem;
      color: $primary;
      font-size: 1rem;
      margin-left: 1rem;

      &:hover {
        background-color: $tags-color;
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .slot-notification-details {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      flex: 1;
      padding: 1rem 0;
      box-sizing: border-box;

      .tag {
        padding: 0.375rem 0.5rem;
        border-radius: 0.25rem;
        font-weight: $weight-600;
        font-size: 1rem;
        box-sizing: border-box;
      }
      .blue {
        background-color: $level-tag-color;
        color: $primary;
      }
      .green {
        background-color: $chip-color-green;
        color: $success;
      }
      .yellow {
        background-color: $chip-color-yellow;
        color: $warning;
      }
    }
  }

  .rewards-interview {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  .slot-achievement-widget {
    display: flex;
    background-color: $white;
    height: 13.625rem;
    border-radius: 0.5rem;
    box-shadow: $card-box-shadow;
    padding: 0.5rem 0;
    box-sizing: border-box;
  }
  .slot-summary {
    display: flex;
    height: 100%;
    background-color: $white;
    border-radius: 0.5rem;
    box-shadow: $card-box-shadow;
    padding: 0.5rem 0;
    box-sizing: border-box;
  }

  .panel-dashboard-header {
    display: flex;
    height: fit-content;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    justify-content: space-between;

    @media screen and (max-width: $sm-max) {
      gap: 0.5rem;
      flex-direction: column;
    }

    .header-container {
      display: flex;
      flex: 1;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .header-title {
        color: $text-color-1;
        font-family: $Montserrat;
        font-weight: $weight-600;
        font-size: 2rem;
        margin-right: 1rem;

        @media screen and (max-width: $md-min) {
          font-size: 1.5rem;
          display: block;
          margin: 1.5rem 0;
        }
        @media screen and (max-width: $sm-max) {
          margin: 0.75rem;
        }
      }
    }

    .search-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media screen and (max-width: $sm-max) {
        width: 100%;
      }
    }
  }
}
.notification-header {
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: $weight-600;
  font-family: $Montserrat;
}

.notification-drawer-container {
  .drawer {
    .drawer-header {
      border-bottom: none;
    }
  }

  .notification-body {
    flex: 1;
    display: flex;
    flex-direction: column;

    .page {
      height: auto;
      width: auto;
      font-size: 1rem;
      padding: 1rem;

      .page-main {
        gap: 1.25rem;

        .page-Icon {
          height: 5rem;
        }
        .page--headingText {
          font-size: 1.5rem;
        }
        .page--bodyText {
          font-size: 1.125rem;
        }
      }
    }
  }
}
.dashboard-layout {
  margin-top: -0.8rem !important;
}
