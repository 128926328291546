@import '../../../../assets/styles/theme';

.image-placeholder {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: $primary;
  color: $white;
  object-fit: contain;
  object-position: center;
  font-weight: $weight-600;

  .profile-text {
    user-select: none;
    font: inherit;
    color: inherit;
  }
}
