@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.slot-efficiency-widget {
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  height: 100%;

  .slot-efficiency-link {
    color: $primary;
    font-weight: $weight-700;
    height: 40px;
    position: absolute;
    font-size: 1rem;
    top: 1rem;
    right: 1.5rem;
    line-height: 1.5rem;
    box-shadow: none;
    padding: 0 0.75rem;

    &:hover {
      background-color: $img-bg-color;
      text-decoration: underline;
    }
  }

  .no-stats-found {
    display: flex;
    height: calc(100% - 32px);
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
    align-items: center;

    img {
      margin-bottom: 0.5rem;
    }
    .no-slot-message {
      color: $gray-500;
      text-align: center;
      font-weight: $weight-600;
      font-family: $Roboto;
    }
    .slot-btn {
      width: 160px;
      font-size: 1rem;
    }
  }

  .slot-efficiency-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    z-index: 100;
    border-radius: 0.5rem;
    background-color: $white;

    .loading-spinner {
      width: 56px;
      height: 56px;
      animation: spinner 1.5s linear infinite;
    }
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .slot-efficiency-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    gap: 1rem;

    .tech-level-filter {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      .slot-overview-dropdown {
        display: flex;
        justify-content: flex-end;
        position: relative;
      }

      .tech-select,
      .level-select {
        min-width: 120px;
        max-width: 250px;
        border: none;
        flex: 1;
        box-shadow: none;
      }
    }
    .month-filter {
      display: flex;
      margin-left: -0.5rem;
    }
  }
  .graph-legends {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    padding-bottom: 1.5rem;
    gap: 1rem;

    .label {
      font-family: 'Roboto';
      font-style: normal;
      display: flex;
      font-weight: 400;
      font-size: 0.75rem;
      line-height: 1.5rem;
      gap: 0.5rem;
      color: $gray-500;
    }
  }
}
@media screen and (max-width: $sm-max) {
  .slot-efficiency-widget {
    padding: 0.5rem;
    .slot-efficiency-filters {
      flex-direction: column;
      align-items: flex-start !important;
      gap: 0.5rem;

      .tech-level-filter {
        justify-content: space-between;
        width: 100%;
      }

      .month-filter {
        margin-left: 0.25rem;
        position: relative;
        width: 100%;
        align-items: center;
      }
    }
    .graph-legends {
      gap: 0.5rem;
    }
  }
}
