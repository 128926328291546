@import 'react-big-calendar/lib/sass/styles';
@import '../../../assets/styles/theme';
@import '../../../assets/styles/media';

@mixin month-event-common-class {
  box-sizing: border-box;
  box-shadow: none !important;
  margin: 0;
  padding: 0;
  padding-left: 0.5rem;
  background-color: $white !important;
  border: none !important;
  border-radius: 0;
  cursor: pointer;
  width: 100%;
  text-align: left;
  margin-bottom: 0.25rem;
  color: $text-color-primary !important;
  width: 95%;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

@mixin month-event-content {
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $text-color-primary;
}

.schedule-container {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
  width: 100%;
  height: 100%;
  overflow: auto;
  align-self: stretch;
  overflow-x: hidden;
  .schedule-header-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .schedule-header-selector {
      box-sizing: border-box;
      min-width: 12.5rem;
      border: 0.0625rem solid $primary;
      height: 3rem;
      .schedule-header-selector-dropdown {
        box-sizing: border-box;
        min-height: 12.5rem;
        height: 25.5rem;
        min-width: 21rem;
        z-index: 10;
      }
    }
    .schedule-header-right-container {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 1rem;
      .schedule-megaphone-btn {
        box-sizing: border-box;
        position: relative;
        height: 3rem;
        width: 3rem;
        border-radius: 0.5rem;
        border: 0.0625rem solid $primary;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .schedule-megaphone-btn-count {
        &::after {
          content: attr(data-value);
          position: absolute;
          top: -0.3125rem;
          left: 2.0625rem;
          width: 1.25rem;
          height: 1.25rem;
          font-family: $Roboto;
          font-size: $font-size-12px;
          font-weight: $weight-600;
          color: $white;
          background-color: $primary;
          text-align: center;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      .schedule-add-slot-btn {
        box-sizing: border-box;
        height: 3rem;
        width: 8.625rem;
        border-radius: 0.5rem;
      }
      .button-group {
        box-sizing: border-box;
        margin: 0;
        .selected {
          box-sizing: border-box;
          font-weight: $weight-600;
          color: $white;
        }
      }
      .schedule-view-btn-group {
        box-sizing: border-box;
        margin: 0;
        width: 5rem;
        height: 3rem;
        font-family: $Roboto;
        font-weight: $weight-400;
        font-size: $font-size-16px;
        text-align: center;
        color: $text-color-primary;
      }
    }
  }
  .user-status-badge {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-inline: 0.5rem;
    padding-block: 1rem;
    border-radius: 0.5rem;
    gap: 1rem;
    background-color: $white;
    box-shadow: $card-box-shadow;
    .info-icon {
      box-sizing: border-box;
      width: 1.5rem;
      height: 1.5rem;
      object-fit: contain;
      object-position: center;
    }
    .info-text-warning {
      box-sizing: border-box;
      color: $warning-color-1;
      font-weight: $weight-400;
      font-size: $font-size-16px;
      font-family: $Roboto;
    }
    .info-text-error {
      box-sizing: border-box;
      color: $error;
      font-weight: $weight-400;
      font-size: $font-size-16px;
      font-family: $Roboto;
    }
  }
  .badge-warning {
    border: 0.0625rem solid $warning;
  }
  .badge-error {
    border: 0.0625rem solid $error;
  }
}

.cal-container {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  height: 100%;
  min-height: 33.125rem;
  max-width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  .cal-sub-container {
    box-sizing: border-box;
    width: 100%;
    align-self: stretch;
    flex-grow: 1;
    .events-loading-wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      flex-grow: 1;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      border: 0.0625rem solid $calendar-gray;
      z-index: 1302;
      background-color: transparent;
      .loading-spinner {
        position: static;
      }
    }
  }
}

.rbc-header + .rbc-header {
  border-left: 0.0625rem solid $calendar-time-box-border;
}

.rbc-day-slot .rbc-event {
  border: none;
}

.direct-slot-add {
  min-width: 23.25rem;
  min-height: 15.3125rem;
  background: $white;
  box-shadow: 0 0 0.5rem $border-color-with-op;
  border-radius: 0.5rem;
  z-index: 10;
}

.btn-container {
  display: flex;
  gap: 0.5rem;
  width: 100%;
  justify-content: right;
}

.cancel-btn {
  background-color: $white;
  color: $primary;
  border: 0.0625rem solid $primary;
  padding: 1rem;
  border-radius: 0.5rem;
}

.add-btn {
  color: $white;
  background-color: $primary;
  border: none;
  padding: 1rem;
  border-radius: 0.5rem;
}

.rbc-event-available {
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;

  padding: 0.5rem;
  background-color: $primary-light;
  border: 0.0625rem solid $primary !important;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  text-align: left;
  .rbc-selected {
    background-color: $primary-light;
  }

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: darken($event-bg, 10%);
  }
}

.rbc-event-booked {
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;

  padding: 0.5rem;
  background-color: $success-secondary;
  border: 0.0625rem solid $success !important;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  text-align: left;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: darken($event-bg, 10%);
  }
}

.rbc-event-existing {
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;
  padding: 0.5rem;
  background-color: $existing-event-bg !important;
  border: 0.0625rem solid $text-color-light !important;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  text-align: left;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: darken($event-bg, 10%);
  }
}

.rbc-event-cancelled {
  box-sizing: border-box;
  box-shadow: none;
  margin: 0;

  padding: 0.5rem;
  background-color: $error-secondary;
  border: 0.0625rem solid $error !important;
  border-radius: 0.5rem;
  cursor: pointer;
  width: 100%;
  text-align: left;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: darken($event-bg, 10%);
  }
}

.rbc-event-temporary {
  box-sizing: border-box;
  margin: 0 !important;

  padding: 0.5rem !important;
  background-color: $white !important;
  border: 0.0625rem dashed $primary !important;
  border-radius: 0.5rem !important;
  cursor: pointer;
  text-align: left;
  box-shadow: 0 0.25rem 0.375rem rgba(16, 24, 40, 0.25), 0 0.75rem 1rem rgba(16, 24, 40, 0.08) !important;
  z-index: 30;
  opacity: 1 !important;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: darken($event-bg, 10%);
  }
}

.rbc-event-requested {
  box-sizing: border-box;
  margin: 0;

  padding: 0.5rem;
  background-color: $warning-color-2 !important;
  border: 0.0625rem solid $warning-color-1 !important;
  border-radius: 0.5rem;
  cursor: pointer;
  text-align: left;

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &.rbc-selected {
    background-color: darken($event-bg, 10%);
  }
}

.rbc-event-existing > .rbc-event-label {
  display: none;
}

.rbc-event-existing > .rbc-event-content > div {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbc-show-more {
  color: $primary;
  &:hover {
    color: $primary;
  }
}

.rbc-day-slot .rbc-time-slot {
  height: 1rem;
  border-top: 0;
}

.rbc-time-view {
  border: 0;
  .rbc-time-header.rbc-overflowing {
    border: 0;
  }
  .rbc-time-header-content {
    border: 0;

    .rbc-day-bg + .rbc-day-bg {
      border: 0;
    }
  }
}

.rbc-slot-selection {
  color: $text-color-1;
  font-family: $Roboto;
  font-weight: $weight-400;
  padding-block: 1rem;
  background-color: $white;
  border-radius: 0.5rem;
  border: 0.0625rem dashed $primary !important;
  box-shadow: 0 0.25rem 0.375rem rgba(16, 24, 40, 0.25), 0 0.75rem 1rem rgba(16, 24, 40, 0.08) !important;
}

.popup {
  box-sizing: border-box;
  border-radius: 0.5rem !important;
  box-shadow: $modal-box-shadow !important;
  background-color: $white !important;
  color: $text-color-primary !important;
  min-width: auto !important;
  min-height: auto !important;
  width: auto;
  height: auto;
  max-width: none !important;
  max-height: none !important;
  overflow: auto !important;
}

.calendar-wrapper {
  margin: 1.25rem;
  padding: 1.25rem;
  background: $white;
  border: 0.0625rem solid $calendar-border;
  box-shadow: 0 0.125rem 1rem $calendar-wrapper-border;
  border-radius: 0.5rem;
}

.rbc-time-content {
  border: 0;
  overflow-y: auto;
}

.rbc-day-slot {
  border-top: 0.0625rem solid $calendar-border;
}

.rbc-time-gutter {
  .rbc-timeslot-group {
    border: 0;
  }
}
.rbc-timeslot-group {
  border-bottom: 0.0625rem solid $calendar-time-box-border;
  border-left: 0 solid $calendar-time-box-border;
}

.rbc-time-view .rbc-header {
  border-bottom: none;
}

.rbc-header {
  min-height: 3.125rem;
}
.rbc-button-link {
  width: 100%;
}

.custom-week-header {
  display: flex;
  flex-direction: column;
  p {
    margin: 0;
    display: block;
    overflow: visible;
  }
}

.calendar-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  .calendar-header-left {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    .calendar-navigation-action {
      box-sizing: border-box;
      display: flex;
      align-items: center;
    }
    .chevron-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      &:hover {
        background-color: $bg-color;
      }
    }
  }
  .calendar-header-right {
    display: flex;
    gap: 0.625rem;
  }
}

.rbc-selected {
  background-color: transparent;
}

.current-header {
  font-weight: 600;
  font-size: 1.5rem;
  font-family: $Montserrat;
}

.calendar-header {
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.range-changer-btn {
  cursor: pointer;
  user-select: none;
}

.chevron-holder-calendar {
  height: 1rem;
}

.rbc-header {
  border-left: 0.0625rem solid $calendar-time-box-border;

  p:nth-child(1) {
    font-weight: 600;
    font-size: 1.5rem;
    color: $text-color-light;
    line-height: 2.25rem;
    font-family: $Montserrat;
  }

  p:nth-child(2) {
    font-weight: 600;
    font-size: 1rem;
    color: $text-color-light;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    font-family: $Montserrat;
  }
}

.rbc-today {
  p:nth-child(1) {
    font-weight: 600;
    font-size: 1.5rem;
    color: $primary;
    line-height: 2.25rem;
    font-family: $Montserrat;
  }

  p:nth-child(2) {
    font-weight: 600;
    font-size: 1rem;
    color: $primary;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    font-family: $Montserrat;
  }
}

.rbc-day-slot .rbc-time-slot {
  min-height: 1.875rem;
  border-top: 0;
}

.rbc-time-gutter .rbc-timeslot-group {
  border-top: 0.0625rem solid $calendar-time-box-border;
  min-height: 3.8125rem;
}

.rbc-calendar {
  border: 0.0625rem solid $calendar-border;
  border-radius: 0.5rem;
  background-color: $white;
  height: max(0, 100%);
}

::-webkit-scrollbar {
  width: 0;
}

.rbc-time-content > * + * > * {
  border-left: 0.0625rem solid $calendar-time-box-border;
}

.rbc-day-slot {
  border-top: 0.0625rem solid $calendar-time-box-border;
}

.rbc-today {
  background-color: $white;
}

.rbc-label {
  color: $text-color-light;
  font-size: 0.875rem;
  padding-left: 0.875rem;
  padding-right: 1rem;
  font-weight: 400;
  font-family: $Roboto;
  width: 100%;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 0.1875rem;
  background-color: $primary;
  pointer-events: none;

  &::before {
    content: '';
    background-color: $primary;
    margin-left: -0.125rem;
    margin-top: -0.375rem;
    position: absolute;
    height: 0.9375rem;
    width: 0.1875rem;
  }
}

.rbc-day-slot .rbc-event-label {
  font-family: $Roboto;
  line-height: 1.125rem;
  font-weight: 400;
  font-size: 0.75rem;
  color: $dark-black;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbc-event-content {
  font-family: $Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: $font-size-12px;
  line-height: 1.125rem;
  color: $text-color-medium;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.rbc-event-available.rbc-selected {
  background-color: $primary-light;
}

.rbc-event-booked.rbc-selected {
  background-color: $success-secondary;
}

.rbc-event-cancelled.rbc-selected {
  background-color: $error-secondary;
}

.rbc-event-existing.rbc-selected {
  background-color: $existing-event-bg !important;
}

.rbc-event-temporary.rbc-selected {
  background-color: $white !important;
}

.rbc-event-requested.rbc-selected {
  background-color: $warning-color-2 !important;
}

.tg-w {
  background-color: $error;
  z-index: 10;
  margin-top: -0.75rem;
}

.tgh-w {
  background-color: transparent;
  z-index: 1;
}

.cal-header-upper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 0.5rem;
}

.day-view-highlight {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $Montserrat;
  color: $primary;
  margin-top: 2rem;
}
.day-view-highlight-date {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}
.day-view-highlight-day {
  font-weight: 600;
  font-size: 1rem;
}

.rbc-time-view .rbc-row {
  box-sizing: border-box;
  min-height: auto;
  z-index: 1;
}

.rbc-time-view .rbc-time-header-content .rbc-day-bg + .rbc-day-bg {
  border: 0;
  border-left: 0.0625rem solid $calendar-time-box-border;
}

.rbc-row-segment {
  padding: 0;
}

.rbc-row-bg {
  :nth-child(1) {
    margin-left: 0.0625rem;
  }
}

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
  border-left: 0.0625rem solid $calendar-time-box-border;
  border-top: 0.0625rem solid $calendar-time-box-border;
}

.rbc-event.rbc-event-booked.rbc-event-allday {
  padding: 0.25rem 0.5rem;
  border-bottom: 0.0625rem solid $calendar-time-box-border;
}

.rbc-allday-cell {
  min-height: 1.5rem;
  &::before {
    content: 'All day';
    position: absolute;
    margin-left: -4.125rem;
    top: 0;
    color: $text-color-light;
    border-top: 0.0625rem solid $calendar-time-box-border;
    border-right: 0.0625rem solid $calendar-time-box-border;
    width: max-content;
    padding: 0.25rem 0.625rem 0.25rem 0.5rem;
  }
  .rbc-event-existing {
    padding: 0.156rem 0.5rem;
    &:focus {
      outline: none;
    }
  }
  .rbc-event-available {
    padding: 0.156rem 0.5rem;
    &:focus {
      outline: none;
    }
  }
  .rbc-event-cancelled {
    padding: 0.156rem 0.5rem;
    &:focus {
      outline: none;
    }
  }
  .rbc-event-booked {
    padding: 0.156rem 0.5rem;
    &:focus {
      outline: none;
    }
  }
}
.rbc-event-temporary {
  padding: 0.156rem 0.5rem;
  &:focus {
    outline: none;
  }
}
.rbc-event-requested {
  padding: 0.156rem 0.5rem;
  &:focus {
    outline: none;
  }
}

.month-classes {
  .rbc-date-cell.rbc-now {
    color: $primary;
  }
  .rbc-row-segment {
    padding: 0;
  }
  .calendar-header {
    padding: 1.5rem;
    padding-bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .month-header-day-name-container {
    .month-header-day-name {
      font-family: $Roboto;
      font-weight: 600;
      font-size: 1rem;
      color: $text-color-medium;
      border: none;
      margin: 0;
    }
  }

  .rbc-month-view {
    border: none;
  }

  .rbc-header {
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: min-content;
    border: none;
    border-bottom: 0.0625rem solid $calendar-border;
  }

  .rbc-off-range-bg {
    background: $bg-color;
  }
  .rbc-button-link {
    font-family: $Montserrat;
    font-weight: 600;
    font-size: 1.5rem;
    margin-top: 0.25rem;
  }

  .rbc-event-available {
    @include month-event-common-class();
    border-left: 0.125rem solid $primary !important;
    &:hover {
      background-color: $primary-light !important;
    }
    .rbc-slot-selecting & {
      cursor: inherit;
      pointer-events: none;
    }
    &.rbc-selected {
      background-color: none;
    }
    &:focus {
      outline: none;
    }
    .rbc-event-content {
      @include month-event-content();
    }
  }

  .rbc-event-cancelled {
    @include month-event-common-class();
    border-left: 0.125rem solid $error !important;
    &:hover {
      background-color: $error-secondary !important;
    }
    .rbc-slot-selecting & {
      cursor: inherit;
      pointer-events: none;
    }
    &.rbc-selected {
      background-color: none;
    }
    &:focus {
      outline: none;
    }
    .rbc-event-content {
      @include month-event-content();
    }
  }

  .rbc-event-existing {
    @include month-event-common-class();
    border-left: 0.125rem solid $text-color-light !important;
    &:hover {
      background-color: $existing-event-bg !important;
    }

    .rbc-slot-selecting & {
      cursor: inherit;
      pointer-events: none;
    }

    &.rbc-selected {
      background-color: none;
    }

    &:focus {
      outline: none;
    }
    .rbc-event-content {
      @include month-event-content();
    }
  }

  .rbc-event-temporary {
    @include month-event-common-class();
    border-left: 0.125rem solid $text-color-light !important;
    &:hover {
      background-color: $white !important;
    }

    .rbc-slot-selecting & {
      cursor: inherit;
      pointer-events: none;
    }

    &.rbc-selected {
      background-color: none;
    }

    &:focus {
      outline: none;
    }
    .rbc-event-content {
      @include month-event-content();
    }
  }

  .rbc-event-requested {
    @include month-event-common-class();
    border-left: 0.125rem solid $warning-color-1 !important;
    &:hover {
      background-color: $warning-color-2 !important;
    }

    .rbc-slot-selecting & {
      cursor: inherit;
      pointer-events: none;
    }

    &.rbc-selected {
      background-color: none;
    }

    &:focus {
      outline: none;
    }
    .rbc-event-content {
      @include month-event-content();
    }
  }

  .rbc-event.rbc-event-booked.rbc-event-allday,
  .rbc-day-slot .rbc-event-booked.rbc-event-allday.rbc-background-event {
    padding: 0;
    padding-left: 0.5rem;
  }
  .rbc-event.rbc-event-available.rbc-event-allday,
  .rbc-day-slot .rbc-event-available.rbc-event-allday.rbc-background-event {
    padding: 0;
    padding-left: 0.5rem;
  }
  .rbc-event.rbc-event-cancelled.rbc-event-allday,
  .rbc-day-slot .rbc-event-cancelled.rbc-event-allday.rbc-background-event {
    padding: 0;
    padding-left: 0.5rem;
  }
  .rbc-event.rbc-event-existing.rbc-event-allday,
  .rbc-day-slot .rbc-event-existing.rbc-event-allday.rbc-background-event {
    padding: 0;
    padding-left: 0.5rem;
  }
  .rbc-event.rbc-event-temporary.rbc-event-allday,
  .rbc-day-slot .rbc-event-temporary.rbc-event-allday.rbc-background-event {
    padding: 0;
    padding-left: 0.5rem;
  }
  .rbc-event.rbc-event-requested.rbc-event-allday,
  .rbc-day-slot .rbc-event-requested.rbc-event-allday.rbc-background-event {
    padding: 0;
    padding-left: 0.5rem;
  }

  .rbc-event-booked {
    @include month-event-common-class();
    border-left: 0.125rem solid $success !important;
    &:hover {
      background-color: $success-secondary !important;
    }

    .rbc-slot-selecting & {
      cursor: inherit;
      pointer-events: none;
    }

    &.rbc-selected {
      background-color: none;
    }

    &:focus {
      outline: none;
    }
    .rbc-event-content {
      @include month-event-content();
    }
  }

  .rbc-button-link.rbc-show-more {
    font-family: $Roboto;
    font-weight: 600;
    font-size: 0.75rem;
    margin-top: 0.25rem;
    text-align: left;
    padding-left: 0.5rem;
  }
}

.mega-phone-container {
  display: flex;
  gap: 1rem;
}

@media (max-width: $sm-min) {
  .schedule-container {
    padding: 0.625rem;
    .schedule-header-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 100%;
    }
  }

  .schedule-container .schedule-header-wrapper .schedule-header-right-container {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    margin-top: 0.625rem;
  }

  .schedule-container
    .schedule-header-wrapper
    .schedule-header-right-container
    .schedule-view-btn-group {
    flex: 1;
  }

  .mega-phone-container {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    width: 100%;
    justify-content: space-between;
    align-self: flex-start;
  }

  .cal-header-upper {
    flex-direction: column;
  }

  .month-classes {
    .cal-header-upper {
      display: flex;
      flex-direction: column;
    }

    .color-indicator-text {
      font-weight: 400;
      font-size: 0.875rem;
      color: $text-color-light;
    }
  }

  .month-classes .rbc-button-link {
    font-size: 1rem;
  }
}

.day-classes {
  .rbc-day-slot .rbc-event-label {
    width: fit-content;
    margin-bottom: -0.0625rem;
  }
  .rbc-day-slot .rbc-event-content {
    margin-top: 0.1875rem;
  }
}
.schedule-megaphone-active {
  box-shadow: 0 0 0 0.25rem $primary-light;
  background-color: $primary-light;
}
