@import '../../../../assets/styles/theme';

.accordion-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  .accordion-handler--radius {
    border-radius: 8px 8px 0px 0px !important;
  }
  .accordion-handler-wrapper {
    box-sizing: inherit;
    display: flex;
    flex-direction: row;
    background: $white;
    border: 1px solid $gray-200;
    border-radius: 8px;
    padding-inline: 16px;
    min-height: 56px;
    align-items: center;

    .accordion-title {
      user-select: none;
      box-sizing: inherit;
      display: flex;
      align-items: center;
      flex-grow: 1;
      color: $text-color-primary;
      font-family: $Roboto;
      font-size: $textFontSize;
      font-weight: $weight-600;
      cursor: pointer;
    }
    .accordion-arrow-image {
      user-select: none;
      box-sizing: inherit;
      object-position: center;
      object-fit: contain;
      padding-inline: 16px;
      cursor: pointer;
    }
  }
  .accordion-details-wrapper {
    box-sizing: inherit;
    background: $white;
    border-width: 0px 1px 1px 1px;
    border-style: solid;
    border-color: $gray-200;
    border-radius: 0px 0px 8px 8px;
    min-height: 50px;
  }
}
