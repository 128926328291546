@import 'assets/styles/variable';
@import 'assets/styles/media';

.tech-item-wrapper {
  position: relative;

  .tech-item {
    background-color: $white;
    min-height: 8rem;
    max-height: 8rem;
    box-sizing: border-box;
    padding: 1.5rem;
    border: $tech-item-border;
    border-radius: $border-radius;
    display: flex;
    position: relative;

    @media screen and (max-width: $md-min) {
      padding: 1rem;
    }

    .tech-stack--img {
      width: 5rem;
      height: 5rem;
      align-self: center;
      margin-right: 1rem;
      border-radius: $border-radius;

      .tech-no-image {
        display: flex;
        justify-content: center;
        flex: 1;
        width: 5rem;
        height: 5rem;
        align-items: center;
        height: 100%;
        background-color: $primary;
        color: $white;
        font-size: 2rem;
        font-weight: $weight-600;
        font-family: $Montserrat;
        border-radius: $border-radius;
      }
      img {
        width: 5rem;
        height: 5rem;
        border-radius: $border-radius;
      }
    }
    .tech-stack--detail {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1rem;
      .tech-stack--name {
        font-family: $Montserrat;
        font-style: normal;
        color: $gray-900;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 11.25rem;
        font-weight: $weight-600;
        font-size: $font-size-20px;
        line-height: 2.25rem;

        @media screen and (max-width: $md-min) {
          font-size: 0.875rem;
        }
      }
      .tech-stack--sub-detail {
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-400;
        line-height: 1.5rem;
        font-size: 1rem;
        color: $primary;
        width: 9.438rem;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
        @media screen and (max-width: $md-min) {
          font-size: 0.75rem;
          letter-spacing: 0.025rem;
        }
      }
    }
  }
  .selected-tech-item {
    animation: blinkingBorder 3s infinite;
  }

  @keyframes blinkingBorder {
    0% {
      border: 0.25rem solid $white;
    }
    25% {
      border: 0.25rem solid $animation-color;
    }
    50% {
      border: 0.25rem solid $white;
    }
    75% {
      border: 0.25rem solid $animation-color;
    }
    100% {
      border: 0.25rem solid $white;
    }
  }

  .tech-stack--dropdown {
    position: absolute;
    top: 0;
    margin: 0.75rem;
    right: 0;

    .zoho-icon {
      width: 5rem;
      height: 3.125rem;
    }
    .zoho-label {
      display: flex;
      font-size: 0.75rem;
      user-select: none;
      color: $gray-500;
      align-items: center;
      font-weight: $weight-400;
      padding: 0.75rem 1rem;
      .zoho-tag {
        font-weight: $weight-600;
        color: $white;
        font-family: $Montserrat;
        border-radius: 0.25rem;
        margin-left: 0.25rem;
        padding: 0.125rem 0.25rem;
        background-color: $error;
      }
    }

    .dots {
      cursor: pointer;
      padding: 0.75rem;
      height: 1.5rem;
    }
  }
  .dropdown {
    position: absolute;
    right: 1rem;
    top: 3rem;
    background-color: $white;
    padding: 0.5rem 0;
    width: 8.3125rem;
    z-index: 4;
    border-radius: 0.5rem;
    border: $tech-item-border;
    box-shadow: $box-shadow-notification;
    .divider {
      border-bottom: $tech-item-border;
      margin: 0.5rem;
    }
    .dropdown-option {
      font-weight: $weight-400;
      font-size: $textFontSize;
      color: $text-color-1;
      line-height: 1.5rem;
      padding: 0.5rem 1rem;
      &:hover {
        background-color: $tags-color;
        cursor: pointer;
      }
    }

    .dropdown-option-disabled {
      font-weight: $weight-400;
      font-size: $textFontSize;
      color: $disabled-button-color;
      line-height: 1.5rem;
      padding: 0.5rem 1rem;
      &:hover {
        background-color: transparent;
        cursor: pointer;
      }
    }
  }
}
.tech-edit-modal {
  background-color: $white;
  display: flex;
  padding: 1.5rem;
  width: 30rem;
  max-height: none;
  margin: 1rem;
  border-radius: 0.5rem;
  flex-direction: column;
  box-sizing: border-box;
  .edit-modal-content {
    font-family: $Roboto;
    font-style: normal;
    font-weight: $weight-400;
    font-size: $body1FontSize;
    line-height: 1.5rem;
    color: $gray-700;
    .delete-modal-para {
      margin: 0;
    }
    .edit-tech-modal-img {
      width: 10rem;
      height: 10rem;
      border: 0.0625rem solid $gray-200;
      border-radius: 0.5rem;
      margin: 0 auto;
      margin-bottom: 1.5rem;

      .image-upload-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 1rem;
        flex-direction: column;
        height: 100%;
        .add-image {
          border-radius: 0.5rem;
          padding: 0.5rem;
          font-weight: 700;
          font-size: 1rem;
          color: $primary;
          cursor: pointer;
          &:hover {
            background: $img-bg-color;
            text-decoration-line: underline;
          }
        }
      }

      .editable-tech-image {
        width: 100%;
        height: 100%;
        position: relative;

        .delete-tech-image {
          position: absolute;
          bottom: -10%;
          right: -10%;
          background-color: $white;
          padding: 1rem;
          line-height: 0;
          border: 0.0625rem solid $primary;
          border-radius: 50%;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .hidden-input {
        display: none;
      }
      .image-upload-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 100%;
      }
      .uploaded-image {
        width: 100%;
        height: 100%;
      }
    }
    .delete-modal--tech-label {
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-600;
      font-size: $font-size-14px;
      line-height: 1.25rem;
      color: $gray-900;
    }
    .tech-name {
      width: 100%;
      height: 1.875rem;
      background: $white;
      border: $border;
      box-shadow: $box-shadow;
      border-radius: $border-radius;
      padding: 0.75rem 1rem;
      height: 3rem;
      box-sizing: border-box;
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-400;
      font-size: 1rem;
      line-height: 1.5rem;
      outline: none;
      margin-top: 0.5rem;
      &:focus {
        border: 0.0625rem solid $primary;
        box-shadow: $settings-autocomplete-boxShadow;
      }
    }
    .edit-modal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1.5rem;
      gap: 1.5rem;
      .edit-modal--cancel-btn,
      .edit-modal--save-btn {
        padding: 0.75rem 1.5rem;

        color: $white;
        width: 7.5rem;
        height: 3rem;
        font-size: 1rem;
        font-weight: $weight-700;
        background: $primary;
        border: $primary-border;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
      }

      .edit-modal--cancel-btn {
        background-color: $white;
        color: $primary;
      }
    }
  }
}

.delete-ts-black {
  color: $gray-900;
}

.invalid-error-ts-modal {
  width: 100%;
  justify-content: center;
  display: flex;
  margin-bottom: 1rem;
}

.ts-tooltip {
  display: none;
}
.ts-tooltip-arrow {
  display: none;
}

.tech-stack--name:hover + .ts-tooltip {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $text-color-1;
  background: $white;
  border-radius: 0.5rem;
  padding: 0.75rem;
  box-shadow: $tooltip-new-box-shadow;
  position: absolute;
  bottom: 6.875rem;
  display: flex;
  justify-content: center;

  .ts-tooltip-arrow {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    left: calc(50% - 0.375rem);
    bottom: -0.3125rem;
    background: $white;
    border-radius: 0.0625rem;
    display: flex;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    box-shadow: 0.125rem 0.125rem 0 $gray-300;
  }
}
.error-message {
  color: $error;
  font-size: 0.875rem;
  font-weight: 400;
}
.dots {
  .ellipsis {
    padding: 0.75rem;
    cursor: pointer;
  }
  .active-btn-ellipsis {
    box-shadow: none;
    filter: invert(39%) sepia(86%) saturate(699%) hue-rotate(182deg) brightness(88%) contrast(97%);
  }
  padding: 0.5rem;
  @media (max-width: $md-min) {
    padding: 0.5rem;
  }
}
