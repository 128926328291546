@import '../../../assets/styles/theme';
@import '../../../assets/styles/media';
@import '../../../assets/styles/variable';

.onboard-container {
  box-sizing: border-box;
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 1.5rem;
  overflow: auto;
  gap: 2rem;
  .onboard-header {
    box-sizing: border-box;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;
    .onboard-title {
      box-sizing: border-box;
      align-self: center;
      font-family: $Montserrat;
      font-weight: $weight-600;
      font-size: $font-size-32px;
      color: $text-color-primary;
      line-height: $font-size-48px;
      user-select: none;
    }
    .onboard-toolbar {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: stretch;
      gap: 1rem;
      flex-grow: 1;

      @media screen and (max-width: $md-min) {
        flex-direction: column;
      }

      .buttonContainer {
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        gap: 1rem;
      }

      .onboard-button {
        box-sizing: border-box;
        padding: 0.75rem 1.5rem;
        width: 7.5rem;
        height: 3rem;
        background: $primary;
        border: 0.0625rem solid $primary;
        box-shadow: $box-shadow;
        border-radius: 0.5rem;
        font-family: $Roboto;
        font-weight: $weight-700;
        font-size: $font-size-16px;
        color: $white;
      }
      .filter-click-wrapper--active {
        box-shadow: $input-box-shadow;
      }
      .filter-click-wrapper--applied {
        &::after {
          content: attr(data-value);
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          height: 1.25rem;
          width: 1.25rem;
          top: -15%;
          right: -15%;
          border-radius: 50%;
          background: $primary;
          font-family: $Roboto;
          font-size: $font-size-12px;
          font-weight: $weight-600;
          text-align: center;
          color: $white;
        }
      }
      .filter-click-wrapper {
        box-sizing: border-box;
        position: relative;
        width: 3rem;
        height: 3rem;
        border-radius: 0.5rem;

        .filter-button {
          box-sizing: border-box;
          padding: 0.875rem;
          width: 3rem;
          height: 3rem;
          border: 0.0625rem solid $primary;
          border-radius: 0.5rem;
        }
      }
    }
  }
  .toast-container-detail {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    flex-grow: 1;
    .toast-title {
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-14px;
      font-weight: $weight-400;
      color: $text-color-primary;
    }
    .toast-detail-button {
      box-sizing: border-box;
      font-family: $Roboto;
      font-weight: $weight-700;
      font-size: $font-size-14px;
      color: $primary;
      border: none;
      text-align: left;
      justify-content: flex-start;
      box-shadow: none;
      padding: 0;
    }
  }
  .table-container {
    box-sizing: border-box;
    flex-grow: 1;
    overflow: visible;
  }
  .search-profile-icon {
    min-width: 3.5rem;
    min-height: 3.5rem;
    width: 3.5rem;
    height: 3.5rem;
    max-width: 3.5rem;
    max-height: 3.5rem;
    border-radius: 12.5rem;
    object-fit: contain;
    object-position: center;
  }
}

.td-decoration {
  box-sizing: border-box;
  vertical-align: middle;
  padding-block: 1rem;
  padding-right: 0;
  font-family: $Roboto;
  font-weight: $weight-400;
  font-size: $font-size-16px;
  color: $text-color-medium;
  padding-left: 0.5rem;
  cursor: pointer;
}

.table-th-cell--first {
  padding: 0.8rem;
  padding-left: 0.25rem;
}

.table-onboard-header {
  display: inline-block;
  padding-right: 1rem;
  padding-left: 0.5rem;
}

.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}
.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
.filter-button-active {
  box-shadow: 0 0 0 0.25rem $primary-light;
  background-color: $primary-light;
}
