@import '../../../../assets/styles/variable';

.modal-backdrop {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: $modal-backdrop-background;

  .modal {
    width: 60%;
    padding: 1.25rem;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $modal-box-shadow;

    .heading {
      font-weight: $weight-600;
      line-height: 2.25rem;
      font-family: $Montserrat;
      color: $gray-700;
      margin-bottom: 1.5rem;
    }
  }
}

.heading-modal {
  font-weight: $weight-600;
  line-height: 2.25rem;
  font-family: $Montserrat;
  color: $gray-900;
  margin-bottom: 1.5rem;
}
