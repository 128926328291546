@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/variable';

.checkbox {
  user-select: none;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  .checkbox-label {
    user-select: auto;
    font: inherit;
  }
}

.unchecked-box {
  border: 0.0625rem solid $gray-500;
  height: 1.125rem;
  width: 1.125rem;
  background-color: $white;
  border-radius: 0.25rem;
  &:hover {
    background-color: $tags-color;
    border: 0.0625rem solid $primary;
  }
}

.checkbox-disabled {
  pointer-events: none;

  img {
    opacity: 0.5;
  }
}
.unchecked-box-disabled {
  background-color: $gray-td-back;
}
