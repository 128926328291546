@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';
@import '../../../../../assets/styles/theme';

.admin-panel-list-filters-modal {
  z-index: 10;
  box-shadow: $modal-box-shadow;
  border-radius: $border-radius;
  background-color: $white;
  position: absolute;
  top: 0;
  right: 0;
}
.failure-page {
  height: 50vh;
  overflow-y: scroll;
  width: 26rem;
  max-width: 26rem;
  @media screen and (max-width: 46.875rem) {
    max-width: calc(100% - 1.5rem);
  }
  & > :first-child {
    height: 60vh;
  }
}

.admin-filter-panel-button-container {
  display: flex;
  margin-top: 1rem;
  justify-content: flex-end;
  width: 100%;
  gap: 1.4rem;
  align-items: center;
}
.admin-filter-panel-button-container-panel {
  display: flex;
  margin-top: 1rem;
  justify-content: space-between;
  width: 100%;
  gap: 1.4rem;
  align-items: center;
}
.ap-filter-modal-divider {
  height: 0.0625rem;
  background-color: grey;
  width: 100%;
  margin: 0.25rem 0 0.5rem 0;
}
.ap-filter-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9;
  position: relative;
  background-color: $bg-color;
  border-bottom: 0.0625rem solid $separator;
  padding-left: 1.5rem;
  padding: 0.8rem;
  border-radius: 0.5rem 0.5rem 0 0;
}

.ap-filter-close {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius;
  &:hover {
    background: $bg-color;
  }
}

.admin-filter-apply-disabled {
  color: $white;
  background-color: $disabled-text-color;
  height: 3rem;
  min-width: 7.5rem;
}

.admin-filter-reset-disabled {
  color: $disabled-text-color;
  height: 3rem;
  min-width: 7.5rem;
}

.admin-modal-filter-header {
  font-family: $Montserrat;
  font-weight: 600;
  margin: 0.25rem 0 0 0;
  font-size: 1.5rem;
}

.admin-filter-apply-button {
  background-color: $primary;
  color: $white;
  height: 3rem;
  min-width: 7.5rem;
}

.admin-filter-reset-button {
  color: $primary;
  border: 0.0625rem solid $primary;
  height: 3rem;
  min-width: 7.5rem;
}

.ap-filter-modal-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
  width: min-content;
  cursor: pointer;
  user-select: none;
  position: relative;

  .ts-tooltip {
    display: none;
    z-index: 11;
  }
  .ts-tooltip-arrow {
    display: none;
    z-index: 11;
  }
}
.ap-filter-loader {
  height: 100%;
  width: 100%;
  position: static;
}

.ap-filter-modal-list-item-name {
  margin-right: 3rem;
  max-width: 9.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $text-color-primary;
  padding-right: 1.5rem;
  overflow-y: scroll;
  max-height: 30rem;
}

.ap-filter-modal-column {
  display: flex;
  flex-direction: column;
}

.ap-filter-modal-list-grid-container-panel {
  display: grid;
  grid-template-rows: 5;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0;
  max-height: 30rem;
  overflow-y: scroll;
  @media screen and (min-width: 20rem) and (max-width: 47.937rem) {
    grid-template-columns: 1fr;
  }
}
.ap-filter-modal-list-grid-container-recruiter {
  display: grid;
  grid-template-rows: 5;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 1.2rem;
  max-height: 30rem;
  overflow-y: scroll;
  @media screen and (min-width: 20rem) and (max-width: 47.937rem) {
    grid-template-columns: 1fr;
  }
}

.ap-filter-card {
  margin: 1.5rem 1rem 0 0;
  width: 100%;
}
.eligiblePanel {
  width: max-content;
  display: flex;
  column-gap: 0.5rem;
}
.btnContainer {
  display: flex;
  flex-direction: row;
  column-gap: 1.5rem;
  justify-content: flex-end;
  padding: 1rem 1.5rem 1.5rem 0;
  border-top: thin solid $separator;
}
.checkBoxLabel {
  width: max-content;
}
.ap-filter-modal-sub-title {
  margin-bottom: 1.25rem;
  color: $text-color-primary;
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 1rem;
  position: relative;
  z-index: 9;
}

.ap-filter-outer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  max-height: 50vh;
  max-width: 60vw;
  overflow-y: scroll;
  @media screen and (max-width: $xs-max) {
    display: flex;
    flex-direction: column;
    max-height: 50vh;
    max-width: 90vw;
  }
}

.ap-filter-modal-list-item-name:hover + .ts-tooltip {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $text-color-1;
  background: $white;
  border-radius: 0.5rem;
  padding: 0.75rem;
  box-shadow: $tooltip-new-box-shadow;
  position: absolute;
  bottom: 1.25rem;
  left: 0.9375rem;
  display: flex;
  justify-content: center;

  .ts-tooltip-arrow {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    left: calc(50% - 0.375rem);
    bottom: -0.3125rem;
    background: $white;
    border-radius: 0.0625rem;
    display: flex;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    box-shadow: 0.125rem 0.125rem 0 $gray-300;
  }
}
.side-bar-title {
  border-right: 0.0625rem solid $separator;
  width: 12rem;
  cursor: pointer;
  padding-top: 1.5rem;
}
.item-name {
  color: $text-color-1;
  padding: 0.5rem;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  height: 3.5rem;
  font-size: 1rem;
  font-weight: $weight-400;
  cursor: pointer;

  &:hover {
    background: $tags-color;
  }
}
.parent-filter-container {
  display: flex;
  gap: 1.5rem;
  height: 30rem;
  width: 47.5rem;
}
.right-side {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 30rem;
  overflow-y: scroll;
  padding-top: 1.5rem;
  flex: 1;
}
.item-name-active {
  background: $tags-color;
  color: $primary;
  font-weight: $weight-600;
}
.search-bar {
  display: flex;
  height: 3rem;
}
.input-bar {
  display: flex;
  flex: 1;
}
.experience {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 0;
}
