@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.dashboard-layout {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  flex-direction: row;

  .column {
    flex: 1 1 calc(50% - 1.5rem);
    min-width: calc(50% - 1.5rem);
  }

  @media screen and (max-width: $md-max) {
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
  }
}
