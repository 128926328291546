@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';
@import '../../../../assets/styles/variable';
.no-filters-main-container {
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.desc-no-filters {
  margin-top: 0.5rem;
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 1.125rem;
  line-height: 1.688rem;
  text-align: center;
  color: $text-color-2;
  width: 65%;
}