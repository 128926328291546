@import '../../../assets/styles/variable';
@import '../../../assets/styles/media';
@import '../../../assets/styles/theme';

@mixin tech-and-interview-level {
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  box-sizing: border-box;
}

@mixin chips-common-styles {
  padding: 0.375rem 0.75rem;
  font-weight: $weight-400;
  font-size: $font-size-12px;
  font-family: $Roboto;
  box-sizing: border-box;
}

.sorting-parent-container {
  position: absolute;
  right: 0;
  top: 3.5rem;
  z-index: 10;
}

.admin-panel-outer-container {
  background-color: $gray-td-back;
  width: 100%;
  padding: 1.5rem 1.5rem 0 1.5rem;
  font-family: $Roboto;
  height: 100vh;
  box-sizing: border-box;

  .panel-loader {
    height: 80%;
    justify-content: center;
    display: flex;
    position: relative;

    .loading-spinner {
      left: auto;
    }
  }

  @media screen and (min-width: 20rem) and (max-width: 47.937rem) {
    width: calc(100% - 2rem);
    margin: auto;
  }

  @media screen and (max-width: $xs-max) {
    flex-direction: column;
    padding: 0;
  }
}

.admin-panel-top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.panel-toast {
  width: 28.063rem;
  display: flex;
  padding-bottom: 0.5rem;
  z-index: 9;
  position: absolute;
  margin-top: -0.125rem;
  margin-top: -0.125rem;
  max-height: 4rem;
  .toast-close-button {
    align-self: flex-start;
    margin-top: 0.375rem;
    margin-top: 0.375rem;
  }

  .blue-text {
    color: $primary;
    font-size: $font-size-14px;
    font-weight: $weight-700;
    padding: 0.5rem;
    text-decoration: underline;
    width: max-content;
    border-radius: 0.5rem;
    cursor: pointer;
  }

  .panel-toast-text {
    margin-top: 0.375rem;
    margin-bottom: 0.625rem;
    margin-top: 0.375rem;
    margin-bottom: 0.625rem;
  }

  .toast-arrow-right {
    align-self: flex-start;
  }
}

.panel-card-lower {
  display: flex;
  align-items: center;
  width: max-content;
}

.black-text {
  color: $count-text;
  cursor: pointer;
}

.ap-card-exp {
  margin-right: 0.5rem;
}
.admin-panel-filters-container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.drawer-body-ap {
  height: 100%;
  flex-grow: 1;
}

.ap-btn-group-container {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: $xs-max) {
    width: 100%;
    flex-direction: column;
  }
}

.ap-save-update {
  color: $gray-900;
}

.ap-mark-buttons {
  display: flex;
  gap: 1rem;
  @media screen and (max-width: $xs-max) {
    margin-bottom: 0.5rem;
    position: sticky;
    top: 0;
  }
}

.ap-filter-count {
  background-color: $primary;
  width: 1.25rem;
  height: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  align-items: center;
  justify-content: center;
  color: $white;
  border-radius: 50%;
  transform: translate(2.2rem, -3.4rem);
  position: absolute;
  z-index: 3;
}

.ap-search-bar {
  margin-left: 1rem;

  @media screen and (max-width: $xs-max) {
    margin: 0.5rem 0 0 0;
    width: 100%;
  }
}

.serached-options-image {
  display: flex;
  color: $white !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $primary;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
}

.admin-panel-filter-buttons {
  width: min-content;
  height: 3rem;
  width: 3rem;
  border: 0.0625rem solid $primary;
  border: 0.0625rem solid $primary;
}
.active-btn {
  box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05), 0 0 0 0.25rem $tags-color;
}

.ap-gray-td {
  background-color: $gray-td-back;
}

.admin-panel-button-group {
  padding: 0.25rem 0 0.25rem 0;
  padding: 0.25rem 0 0.25rem 0;
  width: max-content;
  font-family: $Roboto;
  font-weight: $weight-600;
  padding: 0.75rem 1rem;
}
.admin-panel-heading {
  font-size: $tabHeadingFontSize;
  font-weight: $weight-600;
  font-family: $Montserrat;
  color: $text-color-1;
}

.admin-panel-searched-heading {
  font-weight: $weight-600;
  font-family: $Montserrat;
  font-size: $bodyFontSize;
  display: flex;
  gap: 1rem;
  align-items: center;
}

.admin-modal-header {
  font-weight: $weight-700;
  margin-bottom: 0.5rem;
}

.sort-btn-container {
  position: relative;
}

.filter-modal-btn-container {
  position: relative;
}

.sort-btn-ontainer {
  position: relative;
}

.ap-clear-top-button {
  border-color: $primary;
  color: $primary;
  @media screen and (max-width: $xs-max) {
    margin-right: 0.25rem;
  }
}
.notice-bold-text {
  color: black;
}

.admin-panle-list-sort-by {
  padding: 1.25rem;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $modal-box-shadow;
  z-index: 10;
  position: absolute;
  top: 2.625rem;
}

.tech-bg {
  @include tech-and-interview-level();
  background-color: $tags-color;
  padding: 0.375rem 0.5rem;
  font-size: $font-size-12px;
  font-family: $Roboto;
}
.secondTechStack {
  margin-left: 0.5rem;
}

.project-bg {
  @include tech-and-interview-level();
  font-weight: $weight-400;
  font-size: $font-size-12px;
  width: max-content;
  color: $text-color-1;
  display: flex;
  align-items: center;
  background-color: $project-badge-color;
  height: 2rem;
  @include chips-common-styles();
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  .text {
    @include chips-common-styles();
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 3rem;
    display: block;
  }
}
.ap-on-bench-common {
  @include tech-and-interview-level();
  background-color: $warning-bg;
  font-weight: $weight-400;
  font-size: $font-size-12px;
  width: max-content;
  color: $text-color-1;
  display: flex;
  align-items: center;
  height: 2rem;
  @include chips-common-styles();
}

.project-plus {
  @include tech-and-interview-level();
  margin-left: 0.5rem;
  width: max-content;
  font-weight: $weight-400;
  font-size: $font-size-12px;
  background-color: $project-badge-color;
  color: $text-color-1;
  display: flex;
  align-items: center;
  @include chips-common-styles();
}

.tech-plus {
  @include tech-and-interview-level();
  margin-left: 0.5rem;
  @include chips-common-styles();
  background-color: $tags-color;
}

.interview-level-bg {
  @include tech-and-interview-level();
}

.panel-checkbox-container {
  height: 0.0781rem;
  width: 0.0781rem;
  height: 0.0781rem;
  width: 0.0781rem;
  margin: 0 1rem 0.375rem 0.25rem;
}

.header-first {
  display: flex;
}

.panel-header-cell {
  text-align: start;
  padding: 0.8rem 0.825rem;
  font-weight: $weight-500;
  color: $gray-500;
  box-sizing: border-box;
}

.transparent-backdrop {
  width: 100vw;
  height: 100vh;
  z-index: 8;
  position: fixed;
  right: 0;
  top: 0;
  box-shadow: $modal-box-shadow;
  border-radius: $border-radius;
  background-color: transparent;
}

.filter-modal-outer {
  position: absolute;
  top: 3.5rem;
  left: -30vw;
  z-index: 10;
  max-height: 48rem;
  max-width: 60vw;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  z-index: 9;
  height: 40rem;

  background-color: $white;
  @media screen and (min-width: 20rem) and (max-width: 47.937rem) {
    left: 0;
  }
  @media screen and (max-width: $xs-max) {
    right: -2rem;
    max-width: 90vw;
    left: auto;
  }

  @media screen and (min-width: $xl-min) {
    max-width: 90vw;
  }
}

.panel-header-row {
  color: $gray-500;
}

.panel-row-cell {
  padding: 0.875rem 1rem;
  cursor: pointer;

  &.status-cell {
    max-width: 8.5rem;
  }
}

.panel-panel-name {
  font-weight: $weight-600;
  margin-bottom: 0.375rem;
  min-width: max-content;
  color: $gray-900;
}

.panel-panel-gray-text {
  color: $gray-500;
}

.profile-arrow {
  height: 0.875rem;
  width: 0.875rem;
}

.panel-cell-tech-stack-div {
  display: flex;
  align-items: center;
  color: $primary;
}

.panel-cell-project-stack-div {
  display: flex;
  align-items: center;
  max-width: 6.25rem;
  height: 1.5rem;
}

.align-center {
  text-align: center;
}

.ap-tech-container {
  display: flex;
  align-items: center;
  height: 2rem;
}
.panel-name-designation {
  display: flex;
  align-items: center;
}

.panel-panel-designation {
  margin-top: 0.125rem;
  margin-top: 0.125rem;
  min-width: max-content;
}

.panel-img-name-container {
  display: flex;
  align-items: center;
}

.ap-name-card-cell {
  padding-left: 1rem;
}

.admin-panel-img {
  border-radius: 50%;
  min-height: 2.5rem;
  min-width: 2.5rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  margin: 0.25rem 1rem 0 0;
}

.ap-name-header-cell {
  padding-left: 1.3rem;
}

.ap-top-bar-wrapper-main {
  display: flex;
  width: 100%;
  align-items: center;
  @media screen and (min-width: 20rem) and (max-width: 47.937rem) {
    margin-top: 1rem;
  }
  @media screen and (max-width: $xs-max) {
    flex-direction: column;
  }
}
.panel-panel-img-alt {
  background-color: $primary;
  color: white;
  min-height: 2.5rem;
  min-width: 2.5rem;
  max-height: 2.5rem;
  max-width: 2.5rem;
  border-radius: 50%;
  margin: 0.25rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ap-search-msg {
  font-family: $Montserrat;
  color: $disabled-text-color;
}

.ap-searched-name {
  font-family: $Montserrat;
}

.ap-delete-modal {
  width: 26%;
  padding: 1.5rem;
  border-radius: $border-radius;
  background-color: $white;
  box-shadow: $modal-box-shadow;
  z-index: 10;

  .heading {
    font-weight: $weight-600;
    line-height: 2.25rem;
    font-family: $Montserrat;
    color: $gray-700;
    margin-bottom: 1rem;
  }
}

.searched-panel-tags {
  color: $gray-blue;
}

.ap-delete-btn-container {
  display: flex;
  justify-content: flex-end;
  gap: 1.4rem;
  margin-top: 1.4rem;
}

.ap-delete-cancel-button {
  border-color: $primary;
  color: $primary;
}

.ap-mark-top-button {
  border-color: $primary;
  color: $primary;
  width: max-content;
  padding: 1rem;
}

.ap-delete-top-button {
  border-color: $primary;
  color: $primary;
}

.ap-delete-button {
  background-color: $primary;
  color: $white;
}

.ap-delete-header {
  font-family: $Montserrat;
  font-weight: $weight-600;
  font-size: $bodyFontSize;
  margin: 0 0 1rem 0;
}

.ap-delete-notice-text {
  color: $gray-500;
}

.admin-project-td {
  padding-left: 1rem;
}

.ap-delete-modal-backdrop {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  background-color: $modal-backdrop-background;
}

.approve {
  box-sizing: border-box;
  background: transparent;
  border: 0.0625rem solid $success;
  border-radius: 0.5rem;
  padding: 0.5rem 2.25rem;
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-500;
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-align: center;
  color: $success;
  width: 10rem;
  color: $success;
  width: 10rem;
  & > :first-child {
    margin-bottom: 0.0625rem;
    margin-bottom: 0.0625rem;
    margin-right: 0.5rem;
  }
}
.onboard {
  font-family: $Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $primary;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $primary;
}
.dropdownStatus {
  & > :first-child {
    max-width: 11rem;
    min-width: 6.25rem;
    max-width: 11rem;
    min-width: 6.25rem;

    & > :first-child {
      font-weight: $weight-500;
      align-items: center;
    }
  }
}
.select--status {
  min-height: 2rem;
}
.userRecord {
  width: 100%;
  border-radius: 0.5rem;
}
.backgroudDark {
  background: $gray-td-back;
}
.usersList {
  display: flex;
  flex-direction: row;
  column-gap: 1rem;
  margin: 1rem 1.5rem;
}
.user-data-list {
  display: flex;
  flex-direction: column;
  row-gap: 0.25rem;
  row-gap: 0.25rem;
}
.fixedHeight {
  max-height: 25rem;
  overflow-y: scroll;
}
.userList-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: $white;
  border-radius: 0.5rem;
  border: 0.0625rem solid rgb(158, 157, 157);
  border: 0.0625rem solid rgb(158, 157, 157);
  margin-top: 1.5rem;
}
.profile-img-list {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 12.5rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 12.5rem;
}
.user-name {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-color-1;
}
.user-designation {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $gray-blue;
}
.dropdown-status {
  margin-bottom: 1rem;
  min-width: 12.5rem;
  min-width: 12.5rem;
  max-width: 100%;
}
.tooltip-container-proj {
  display: flex;
  justify-content: center;
  height: min-content;
  margin-left: -1rem;
}
.tooltip-container {
  display: flex;
  justify-content: center;
  height: min-content;
}
.tooltipCountTS {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $text-color-1;
  background: $white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0.25rem 0.25rem $gray-300;
  display: flex;
  flex-direction: column;
  z-index: 9;
}
