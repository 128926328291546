@import '../../../../../assets/styles/theme';

.slot-request-card-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 16px;
  background: $white;
  border: 1px solid $calendar-gray;
  border-radius: 8px;
  gap: 36px;
  width: 100%;
  .slot-request-card-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slot-request-card-raised-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-basis: 50%;
      .slot-request-card--raisedBy {
        box-sizing: border-box;
        display: block;
        color: $text-color-light;
        font-family: $Roboto;
        font-size: $font-size-16px;
        font-weight: $weight-600;
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .slot-request-card-name-avatar-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 11px;
      flex-basis: 50%;
      .slot-request-card-name-wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .slot-request-card--name {
          box-sizing: border-box;
          display: block;
          color: $text-color-light;
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-600;
          flex-grow: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .slot-request-card-avatar-wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        min-height: 40px;
        height: 40px;
        max-height: 40px;
        min-width: 40px;
        width: 40px;
        max-width: 40px;
        border-radius: 50%;
        .slot-request-card--avatar {
          box-sizing: border-box;
          display: block;
          object-position: center;
          object-fit: contain;
          min-width: 100%;
          max-width: 100%;
          width: 100%;
          min-height: 100%;
          max-height: 100%;
          height: 100%;
          border-radius: 50%;
        }
        .slot-request-card--avatar-fallback {
          box-sizing: border-box;
          min-height: 40px;
          height: 40px;
          max-height: 40px;
          min-width: 40px;
          width: 40px;
          max-width: 40px;
          border-radius: 50%;
        }
      }
    }
  }
  .slot-request-card-body {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    .slot-card-date-element {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 4px;
      .slot-card-date-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-16px;
        font-weight: $weight-600;
        color: $text-color-primary;
      }
      .slot-card-time-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-16px;
        font-weight: $weight-700;
        color: $text-color-primary;
      }
      .slot-card-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-16px;
        font-weight: $weight-600;
        color: $text-color-light;
      }
    }
    .slot-card-detail-element {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .slot-card-detail-tag {
        box-sizing: border-box;
        height: 24px;
        background: $primary-light;
        border-radius: 8px;
        flex-grow: 0;
        font-family: $Roboto;
        font-size: $font-size-12px;
        font-weight: $weight-600;
        color: $primary;
        border: none;
        padding: 6px 8px 6px 8px;
      }
    }
  }
  .slot-request-card-footer {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 16px;
    margin-top: auto;
    .slot-request-card-buttons {
      box-sizing: border-box;
      max-width: 120px;
      min-width: 120px;
      width: 120px;
      max-height: 40px;
      min-height: 40px;
      height: 40px;
      border: 1px solid $primary;
      box-shadow: $box-shadow;
      border-radius: 8px;
      text-align: center;
      font-family: $Roboto;
      font-weight: $weight-700;
      font-size: $font-size-14px;
      cursor: pointer;
    }
    .slot-request-card--decline-button {
      background: $white;
      color: $primary;
    }
    .slot-request-card--accept-button {
      background: $primary;
      color: $white;
    }
  }
}
