@import '../../../../assets/styles/theme';

.slot-detail-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $white;
  border: 0.0625rem solid $gray-200;
  border-radius: 0.5rem;
  width: 100%;
  .slot-detail-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $white;
    border-bottom: 0.0625rem solid $gray-200;
    padding-inline: 1rem;
    padding-block: 0.5rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    height: 3.5rem;
    .slot-detail-title {
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $text-color-primary;
      user-select: none;
    }
    .slot-detail-edit {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 2.5rem;
      max-width: 2.5rem;
      width: 2.5rem;
      height: 2.5rem;
      max-height: 2.5rem;
      min-height: 2.5rem;
      border-radius: 0.5rem;
      cursor: pointer;
      border: none;
      box-shadow: none;
      background: $white;
      padding: 0;
      &:hover {
        background: $bg-color;
      }
      .slot-edit-icon {
        box-sizing: border-box;
        display: block;
        object-fit: contain;
        object-position: center;
      }
    }
  }
  .slot-detail-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding-block: 1rem;
    gap: 1rem;
  }
}

.slot-strip-wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 0.5rem;
  padding-inline: 1rem;
  height: 2.5rem;
  .slot-strip-title-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    .slot-strip-title {
      box-sizing: border-box;
      font-family: $Roboto;
      font-weight: $weight-400;
      font-size: $font-size-16px;
      color: $text-color-primary;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .slot-strip-action-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    align-self: stretch;
    gap: 0.5rem;
  }
  .slot-strip-cancel-btn {
    box-sizing: border-box;
    height: 2.5rem;
    width: 6.125rem;
    border-radius: 0.5rem;
    border: none;
    font-family: $Roboto;
    font-size: $font-size-16px;
    font-weight: $weight-700;
    color: $primary;
    box-shadow: none;
    cursor: pointer;
    &:hover {
      background-color: $primary-light;
    }
  }
  .slot-strip-delete-btn {
    box-sizing: border-box;
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 0.5rem;
    border: none;
    box-shadow: none;
    &:hover {
      background-color: $bg-color;
    }
  }
}

.slot-strip-grey {
  border-left: 0.125rem solid $disabled;
}

.slot-strip-red {
  border-left: 0.125rem solid $error;
}

.slot-strip-blue {
  border-left: 0.125rem solid $primary;
}

.slot-strip-green {
  border-left: 0.125rem solid $success;
}

.slot-strip-yellow {
  border-left: 0.125rem solid $warning-color-1;
}

.cancel-card-border-wrapper {
  box-sizing: border-box;
  display: flex;
  gap: 0.375rem;
  padding-right: 0.5rem;
  .cancel-card-border {
    box-sizing: border-box;
    border-left: 0.125rem solid $success;
    height: 2.5rem;
    margin-top: 0.75rem;
  }
}

.slot-edit-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.5rem;
  .slot-warning-message-wrapper {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-right: 2.5rem;
    padding-inline: 0.5rem;
    .slot-warning-message {
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-14px;
      font-weight: $weight-400;
      line-height: 1.3125rem;
      color: $warning-color-1;
    }
  }
  .slot-edit-container-inner {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline: 0.5rem;
    border-left: 0.125rem solid $primary;
    .slot-edit-select-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 0.5rem;
      .slot-edit-select {
        box-sizing: border-box;
        min-width: 7rem;
        width: 7rem;
        height: 3rem;
        min-height: 3rem;
      }
    }
    .slot-edit-delete-wrapper {
      box-sizing: border-box;
      width: 2.5rem;
      height: 2.5rem;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.5rem;
      &:hover {
        background-color: $bg-color;
      }
    }
  }

  .slot-edit-action-btn-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    padding-right: 1rem;
    .slot-edit-action-btn {
      box-sizing: border-box;
      height: 3rem;
      width: 7.5rem;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-700;
      line-height: 1.5rem;
    }
    .slot-edit-cancel-btn {
      box-sizing: border-box;
      color: $primary;
      background: $white;
      border: 0.0625rem solid $primary;
      box-shadow: $box-shadow;
      border-radius: 0.5rem;
    }
  }
}
