@import '../../../../../assets/styles/theme';

.filter-wrapper {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  padding: 1.5rem;
  width: 22.687rem;
  height: 23.5rem;
  background: $white;
  border: $tech-item-border;
  box-shadow: $box-shadow-notification;
  border-radius: 0.5rem;
  z-index: 3;
  top: 110%;
  right: 0%;

  @media screen and (max-width: 47.9375rem) {
    right: -20%;
  }
  .filter-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .filter-title {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-weight: $weight-600;
      font-size: $font-size-24px;
      color: $text-color-primary;
      user-select: none;
    }
    .filter-close-button {
      box-sizing: border-box;
      height: 3rem;
      width: 3rem;
      border-radius: 0.5rem;
      border: none;
      box-shadow: none;
      &:hover {
        background: $bg-color;
      }
      .filter-close-icon {
        box-sizing: border-box;
        height: 1rem;
        width: 1rem;
      }
    }
  }
  .filter-options-wrapper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    .filter-options-title {
      box-sizing: border-box;
      user-select: none;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-600;
    }
    .filter-options {
      box-sizing: border-box;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1.5rem;
      padding-block: 0.75rem;
      .filter-options-checkbox {
        box-sizing: border-box;
        font-family: $Roboto;
        font-weight: $weight-400;
        font-size: $font-size-16px;
        line-height: 1.5rem;
        color: $text-color-primary;
      }
    }
  }
  .filter-actions-wrapper {
    margin-top: auto;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1.5rem;
    .filter-action-button {
      box-sizing: border-box;
      padding: 0.75rem 1.5rem;
      width: 7.5rem;
      height: 3rem;
      box-shadow: $box-shadow;
      border-radius: 0.5rem;
      font-family: $Roboto;
      font-weight: $weight-700;
      font-size: $font-size-16px;
    }
    .filter-action--apply {
      &:disabled {
        background: $disabled;
        border: 0.0625rem solid $disabled;
      }
    }
    .filter-action-reset {
      background: $white !important;
      border: 0.0625rem solid $primary !important;
      color: $primary !important;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-700;

      &:disabled {
        background: $white !important;
        border: 0.0625rem solid $disabled !important;
        color: $disabled !important;
      }
    }
  }
}
.item-name {
  color: $text-color-1;
  padding: 0.5rem;
  padding-left: 1.5rem;
  display: flex;
  align-items: center;
  height: 3.5rem;
  font-size: 1rem;
  font-weight: $weight-400;
  cursor: pointer;

  &:hover {
    background: $tags-color;
  }
}
.onboard-parent-filter-container {
  display: flex;
  gap: 1.5rem;
  height: 21rem;
  width: 47.5rem;
}
.right-side {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  max-height: 30rem;
  overflow-y: scroll;
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  flex: 1;
}
.item-name-active {
  background: $tags-color;
  color: $primary;
  font-weight: $weight-600;
}
.onboard-filter-modal-outer {
  position: absolute;
  top: 3.5rem;
  right: 20%;
  z-index: 10;
  max-width: 60vw;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);
  height: inherit !important;
}
