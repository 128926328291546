@import '../../../../../assets/styles/theme';

.table-email-wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .table-email-lable {
    box-sizing: border-box;
    font-family: $Roboto;
    font-size: $textFontSize-md;
    font-weight: $weight-400;
    color: $gray-500;
    flex-grow: 1;
  }
  .table-email-delete {
    box-sizing: border-box;
    font-family: $Roboto;
    font-size: $textFontSize;
    font-weight: $weight-700 !important;
    color: $primary;
    background-color: transparent !important;
    box-shadow: none;
    width: 62px;
    height: 40px;

    .button--label {
      padding: 0.5rem;
    }

    &:hover {
      background: $primary-light !important;
      border-radius: 8px;
      text-decoration: underline;
    }
  }
}
