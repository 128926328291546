@import '../../../../assets/styles/theme';

.date-picker-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: $white;
  .date-picker-header {
    box-sizing: border-box;
    height: 48px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .date-picker-icons-wrapper {
      box-sizing: border-box;
      min-width: 40px;
      width: 40px;
      height: 100%;
      min-height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      .date-picker-icon {
        box-sizing: border-box;
        width: 8px;
        height: 14.14px;
        user-select: none;
      }
      .date-picker-icon-disabled {
        filter: invert(64%) sepia(0%) saturate(14%) hue-rotate(134deg) brightness(95%) contrast(89%);
      }
    }
    .date-picker-header-title-wrapper {
      box-sizing: border-box;
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $Roboto;
      font-size: $font-size-20px;
      font-weight: $weight-400;
      text-align: center;
      user-select: none;
    }
  }
  .date-picker-days-row {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-self: center;
    flex-grow: 1;
    width: 100%;
    .date-picker-days-wrapper {
      box-sizing: border-box;
      display: flex;
      height: 48px;
      .date-picker-day {
        box-sizing: border-box;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $Roboto;
        font-weight: $weight-600;
        font-size: $textFontSize;
        text-align: center;
        color: $text-color-primary;
      }
      .date-picker-date-cell {
        box-sizing: border-box;
        flex-grow: 1;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .date-picker-date-hover {
        box-sizing: border-box;
        background-color: $primary-light !important;
      }
    }
    .date-picker-date-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      overflow: auto;
      width: 100%;
      .date-picker-date-button {
        box-sizing: border-box;
        min-width: 48px;
        min-height: 48px;
        max-width: 48px;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background-color: transparent;
        color: $text-color-primary;
        border: none;
        cursor: pointer;
        font-family: $Roboto;
        font-size: $font-size-16px;
        font-weight: $weight-400;
        padding: 0;
        &:hover {
          font-weight: $weight-400 !important;
          color: $text-color-primary !important;
          background: $primary-light !important;
          border: 1px solid $primary;
        }
      }
      .date-picker-date-button--today {
        box-sizing: border-box;
        color: $primary;
        font-weight: $weight-600;
      }
      .date-picker-date-button--radius {
        box-sizing: border-box;
        font-family: $Roboto;
        color: $white !important;
        font-size: $textFontSize;
        font-weight: $weight-600;
        text-align: center;
        background-color: $primary !important;
        &:hover {
          font-weight: $weight-600 !important;
          color: $white !important;
          background: $primary !important;
          border: none;
        }
      }
      .date-picker-date-button--radius-hover {
        box-sizing: border-box;
        font-family: $Roboto;
        font-weight: $weight-400 !important;
        color: $text-color-primary !important;
        background: $primary-light !important;
        border: 1px solid $primary;
        text-align: center;
      }
      .date-picker-date-cell--disabled {
        box-sizing: border-box;
        color: $text-color-light !important;
        &:hover {
          color: $text-color-light !important;
          background: transparent !important;
          border: none;
        }
      }
    }
  }
}

.date-picker-radius-first {
  box-sizing: border-box;
  font-family: $Roboto;
  color: $white !important;
  font-size: $textFontSize;
  font-weight: $weight-600;
  text-align: center;
  background: linear-gradient(90deg, transparent 50%, $primary-light 50%);
}

.date-picker-radius {
  box-sizing: border-box;
  font-family: $Roboto;
  color: $white !important;
  font-size: $textFontSize;
  font-weight: $weight-600;
  text-align: center;
  background: linear-gradient(90deg, $primary-light 50%, transparent 50%);
}

.date-picker-day-cell-text {
  box-sizing: border-box;
  margin: 0;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  width: 48px;
  height: 48px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
