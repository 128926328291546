@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';
@import '../../../../assets/styles/variable';

@mixin tech-and-interview-level {
  background-color: $cool-gray;
  padding: 0.25rem 0.5rem;
  border-radius: 0.375rem;
}

.table-outer-container {
  font-family: $Roboto;
  min-height: 80vh;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .table-first-body-div {
    display: flex;
    align-items: center;
    border-top: 0.0625rem solid $gray-200;
  }

  .table-checkbox-cell {
    padding: 0.8rem 0 0.8rem 1.5rem;
  }

  .table-body-tr,
  .table-tr {
    &:nth-child(even) {
      background-color: $gray-td-back;
    }
    &:hover {
      box-shadow: $table-box-shadow;
      z-index: 1;
    }
  }
  .table-tr {
    &:nth-child(even) {
      background-color: $white;
    }
  }

  .table-first-column-div {
    display: flex;
    background-color: $frozen-white;
  }

  .selected-tr {
    animation: blinkingBackground 3s infinite;
  }
  @keyframes blinkingBackground {
    0% {
      background-color: $white;
    }
    25% {
      background-color: $pulse-color;
    }
    50% {
      background-color: $white;
    }
    75% {
      background-color: $pulse-color;
    }
    100% {
      background-color: $white;
    }
  }

  thead tr th {
    top: 0;
    background-color: $white;
  }
}
.table-main-container {
  overflow-x: auto;
  border-radius: 0.5rem;
  box-shadow: $card-box-shadow;
}

.table-sub-container {
  width: 100%;
  min-width: 100%;
  background-color: $white;
  position: relative;
  margin-bottom: 0.4375rem;
  border-radius: 0.625rem;
}

.inline {
  display: inline;
}

.table-header-cell {
  text-align: start;
  font-weight: $weight-500;
}

.table-th-cell {
  box-sizing: border-box;
  text-align: left;
  font-family: $Roboto;
  font-size: $font-size-16px;
  font-weight: $weight-600;
  color: $text-color-medium;
  border-bottom: 0.0625rem solid $separator;
  position: relative;
}

.table-header-row {
  color: $gray-blue;
}

.no-border {
  border: none;
}

.align-center {
  text-align: center;
}

.table-row-cell {
  cursor: pointer;
  padding: 1rem 0 1rem 1rem;
}

.table-header-cell {
  text-align: left;
  padding: 0.875rem 0 0.875rem 1rem;
  font-weight: $weight-500;
  color: $gray-500;
  border-bottom: 0.0625rem solid $gray-200;
}

.table-main-overflow {
  overflow-x: visible !important;
}

::-webkit-scrollbar {
  display: none;
}

.pagination-container {
  padding-bottom: 1.5rem;
}
.no-filters-div {
  width: 100%;
  display: flex;
  justify-content: center;
  background-color: $white;
}
.no-results-container-panel {
  background-color: $white;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.sort-indicator {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 10%;
}
