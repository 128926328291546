@import '../../../../../assets/styles/theme';

.slot-events-drawer-body {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1rem;
  flex-grow: 1;
  overflow: auto;
  padding: 1.5rem;
  .no-events-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    gap: 1rem;
    .no-events-text {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $text-color-primary;
      line-height: 2.25rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  .loading-wrapper {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-spinner {
      box-sizing: border-box;
      position: static;
    }
  }
}
.drawer-header-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  flex-grow: 1;
  overflow: hidden;
  .drawer-header-title {
    box-sizing: border-box;
    font-family: $Montserrat;
    font-size: $font-size-24px;
    font-weight: $weight-600;
    color: $text-color-primary;
    line-height: 2.25rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
