@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';

.horizontal-scroll-div {
  display: flex;
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1.5rem;
  box-sizing: border-box;
  align-items: center;
  position: relative;

  &:hover {
    .right-arrow,
    .left-arrow {
      display: flex;
    }
  }

  .right-arrow,
  .left-arrow {
    display: none;
    position: absolute !important;
    align-items: center;
    flex: 1;
    z-index: 6;
    padding: 0 0.25rem;
    height: 40px;
    border: none;
    background-color: $white;
    box-sizing: border-box;
    box-shadow: $chevron-box-shadow;
    img {
      width: 24px;
      filter: invert(32%) sepia(56%) saturate(1450%) hue-rotate(191deg) brightness(102%)
        contrast(94%);
    }
    cursor: pointer;

    &:active,
    &:hover {
      background-color: $tags-color;
    }
  }
  .right-arrow {
    right: 0;
  }
  .left-arrow {
    left: 0;
  }

  .arrow-disabled {
    img {
      width: 24px;
      filter: none;
    }
    cursor: not-allowed;
    &:active,
    &:hover {
      background-color: $white;
    }
  }

  .scroll-body {
    display: flex;
    width: 100%;
    gap: 1rem;
    position: relative;
    overflow-x: scroll;
    margin-left: 1.5rem;
    overflow-y: hidden;
    scroll-behavior: smooth;

    &::-webkit-scrollbar {
      display: none;
    }

    .scroll {
      display: flex;
      gap: 1rem;
    }
  }
}
@media screen and (max-width: $sm-max) {
  .horizontal-scroll-div {
    width: auto;
    &:hover {
      .right-arrow,
      .left-arrow {
        display: none;
      }
    }
  }
}
