@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.slot-efficiency-widget-wrapper {
  flex: 1;
  height: 100%;
  padding: 1.5rem 0;
  border-radius: 0.5rem;
  background-color: $white;
  box-sizing: border-box;
  position: relative;
  box-shadow: $card-box-shadow;
  position: relative;

  .page {
    height: 100%;
    overflow: scroll;
    .page--headingText {
      font-size: 1rem;
    }

    .page--bodyText {
      font-size: 0.875rem;
    }
  }

  .slot-efficiency-header {
    display: flex;
    justify-content: space-between;
    padding: 0 1.5rem;
    align-items: center;

    .slot-efficiency-title {
      font-family: $Montserrat;
    }
  }
  @media screen and (max-width: $sm-max) {
    padding: 0;
    max-height: 574px;
  }
}

// TODO: Slot Efficiency widget will be developed

@media screen and (max-width: $md-max) {
  .slot-efficiency-widget-wrapper {
    .placeholder {
      height: 300px;
    }
  }
}
