@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';

.pagination-main-container {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  @media screen and (min-width: 20rem) and (max-width: 47.9365rem) {
    column-gap: 2rem;
  }
}

.pagination-page-numbers {
  display: flex;
  align-items: center;
}

.pagination-dynamic-numbers {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.pagination-button-styles {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  width: min-content;
  color: $primary;
  height: 3rem;
  width: 3rem;

  .button--label {
    font-weight: $weight-700;
    @media screen and (min-width: 20rem) and (max-width: 47.9365rem) {
      padding-left: 0.75rem;
    }
  }

  .icon--leading {
    margin: 0 0.5rem 0 0;
    padding: 0 !important;
    width: 1rem;
    height: 1rem;
    @media screen and (min-width: 20rem) and (max-width: 47.9365rem) {
      padding-left: 0.75rem;
    }
  }

  .icon--trailing {
    margin: 0 0 0 0.5rem;
    padding: 0 !important;
    width: 1rem;
    height: 1rem;
  }

  @media screen and (max-width: $xs-max) {
    padding: 0;
  }
}

.pagination-disabled-button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $gray-500 !important;
  border: 0.0625rem solid $separator !important;
  background-color: none !important;
  font-weight: $weight-700;
  height: 3rem;
  width: 3rem;

  .button--label {
    font-weight: $weight-700;
    color: $disabled;
    @media screen and (min-width: 20rem) and (max-width: 47.9365rem) {
      padding-left: 0.75rem;
    }
  }

  .icon--leading {
    margin: 0 0.5rem 0 0;
    padding: 0 !important;
    width: 1rem;
    height: 1rem;
  }

  .icon--trailing {
    padding: 0 !important;
    width: 1.5rem;
    height: 1.5rem;
  }

  @media screen and (max-width: $xs-max) {
    padding: 0;
  }
}

.pg-next-btn {
  width: 3rem;
  border: 0.0625rem solid $separator;
}

.pg-prev-btn {
  width: 3rem;
  border: 0.0625rem solid $separator;
}
.pagination-button-styles:hover {
  background-color: $secondary;
}
.page-number-common:hover {
  border-radius: 50%;
  background-color: $secondary;
}
.results-count {
  color: $disabled;
  font-weight: $weight-700;
  display: flex;
}
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  gap: 1rem;

  .button--outlined:disabled {
    border: 0.0625rem solid $separator !important;
  }
}
.page-number-div {
  border: none !important;
  background-color: transparent;
  height: 3rem;
  width: 3rem;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: none !important;
  color: $gray-500;
  cursor: pointer;
  border-radius: 50%;
  @media screen and (min-width: 20rem) and (max-width: 47.9365rem) {
    display: none;
  }
  &.page-number-selected {
    font-weight: $weight-500;
    background-color: $secondary;
    color: $primary;
  }
}
.page-number-div:hover {
  border-radius: 50%;
  background-color: $secondary;
}
