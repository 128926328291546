.clipped-select-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .primary-select {
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex-basis: 50%;
    min-width: auto;
    max-width: 50%;
    &>:first-child{
      max-width: 8.75rem;
    }
  }
  .secondary-select {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    flex-basis: 50%;
    min-width: auto;
    max-width: 50%;
    &>:first-child{
      max-width: 8.75rem;
    }
  }
}
