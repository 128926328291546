@import '../../../../assets/styles/theme';

.drawer-form-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  .form-label {
    box-sizing: border-box;
    font-family: $Roboto;
    color: $text-color-1;
    font-size: $font-size-14px;
  }
  .form-label-primary {
    font-weight: $weight-600;
  }
  .form-label-secondary {
    font-weight: $weight-400;
  }
  .checkboxfield {
    width: max-content;
  }
  .drawer-detail-wrapper {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: auto;
    padding: 1.5rem;
    gap: 1rem;
    .drawer-header-style {
      box-sizing: border-box;
      margin: 0;
      border-bottom: 0.0625rem solid $gray-200;
    }
    .role-form-container {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: stretch;
      flex-grow: 1;
      gap: 1rem;
      .role-form-wrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        flex-grow: 1;
        gap: 1rem;
        .role-selection-container {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          padding-top: 0.5rem;
        }
        .panel-form-container {
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: stretch;
          padding-top: 0.875rem;
          gap: 1rem;
          .panel-form-status-container {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 0.5rem;
          }
          .panel-form-project-wrapper {
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            gap: 1.125rem;
            .panel-form-project-container {
              box-sizing: border-box;
              display: flex;
              flex-direction: column;
              align-items: stretch;
              gap: 0.5rem;
            }
          }
        }
      }
    }
  }
  .role-form-action-button-wrapper {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding: 1.5rem 0 0 0;
    gap: 1.5rem;
    border-top: 0.0625rem solid $gray-200;
    flex: 0;
    .form-action-buttons {
      box-sizing: border-box;
      width: 100%;
      height: 3rem;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-700;
      border-radius: 0.5rem;
      box-shadow: $box-shadow;
    }
    .form-action-buttons--cancel {
      color: $primary;
      background-color: $white;
      border: 0.0625rem solid $primary;
    }
  }
}

.role-form-action-button-wrapper {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: stretch;
  height: 6rem;
  padding: 1.5rem;
  gap: 1.5rem;
  border-top: 0.0625rem solid $gray-200;
  .form-action-buttons {
    box-sizing: border-box;
    width: 100%;
    height: 3rem;
    font-family: $Roboto;
    font-size: $font-size-16px;
    font-weight: $weight-700;
    border-radius: 0.5rem;
    box-shadow: $box-shadow;
  }
  .form-action-buttons--cancel {
    color: $primary;
    background-color: $white;
    border: 0.0625rem solid $primary;
  }
}

.panel-label {
  padding: 0.75rem;
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $text-color-1;
  background: $white;
  border: 0.0625rem solid $text-color-2;
  box-shadow: 0 0.0625rem 0.125rem rgba(16, 24, 40, 0.05);
  border-radius: 0.5rem;
}
.techStack-fieldArray {
  display: flex;
}
.onboard-remove-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
}
.disable-text {
  font-family: $Roboto;
  font-size: $textFontSize;
  font-weight: $weight-700;
  color: $text-color-light;
}
.techStack-error {
  color: $error;
  font-size: $textFontSize;
}
.ta-text-secondary {
  display: inline;
  cursor: pointer;
  font-family: $Roboto;
  font-size: $textFontSize;
  font-weight: $weight-700;
  color: $primary;
}
.stackLoi-row-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  width: calc(100% - 2rem);
  .stackLoi-text-primary {
    font-family: $Roboto;
    font-weight: $weight-600;
    font-size: $textFontSize-md;
    color: $text-color-primary;
    margin-right: auto;
    flex: 1;
  }
}
.onboard-form {
  flex: 1;
  flex-grow: 1;
  overflow: auto;
  height: 100%;
}
.stackLoi-icon {
  filter: invert(74%) sepia(12%) saturate(378%) hue-rotate(179deg) brightness(86%) contrast(85%);
  cursor: pointer;
  padding-left: 0.5rem;
}
.onboard-add-button {
  border: none !important;
  float: left !important;
  width: max-content !important;
  background-color: transparent !important;
  cursor: pointer;
  font-size: $textFontSize;
  font-weight: $weight-700;
  color: $primary;
}
.onboard-add-button-disabled {
  color: $disabled;
}
.status-label {
  padding-bottom: 0.5rem;
}
.error-message {
  color: $error;
  font-size: 1rem;
}
