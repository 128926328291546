@import '../../../../../assets/styles/theme';

.slot-cancel-card-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 25rem;
  border-radius: 0.5rem;
  border: 0.0625rem solid $separator;
  padding: 1rem;
  gap: 1rem;
  align-self: stretch;
  width: 100%;
  .slot-cancel-card-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slot-cancel-title {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $text-color-primary;
    }
    .close-icon-wrapper {
      box-sizing: border-box;
      height: 2.5rem;
      min-height: 2.5rem;
      max-height: 2.5rem;
      width: 2.5rem;
      min-width: 2.5rem;
      max-width: 2.5rem;
      cursor: pointer;
      border-radius: 0.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &:hover {
        background-color: $bg-color;
      }
    }
  }
  .slot-cancel-card-main {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1.5rem;
    .slot-cancel-card-content {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 0.5rem;
      .slot-cancel-mark-wrapper {
        position: relative;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 0.75rem;
        margin-bottom: 0.625rem;
        .radio-outer {
          align-self: stretch;
          width: auto;
          padding: 0;
          .radio-label {
            box-sizing: border-box;
            font-family: $Roboto;
            font-size: $font-size-16px;
            color: $text-color-1;
            font-weight: $weight-400;
            margin-left: 0.75rem;
          }
          .radio-custom {
            width: 0.9rem;
            height: 0.9rem;
            &::after {
              top: 20%;
              left: 24%;
              width: 0.5rem;
              height: 0.5rem;
            }
          }
        }
      }
      .cancel-reason-label {
        box-sizing: border-box;
        font-family: $Roboto;
        font-size: $font-size-14px;
        font-weight: $weight-700;
        color: $text-color-primary;
      }
      .cancel-input-box {
        box-sizing: border-box;
        height: 4.5rem !important;
      }
      .slot-cancel-checkbox-wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        margin-top: 0.5rem;
        .slot-available-label {
          box-sizing: border-box;
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-400;
          color: $text-color-medium;
        }
      }
    }
    .slot-cancel-card-action-btn {
      box-sizing: border-box;
      align-self: flex-end;
      height: 3rem;
      width: 9.3125rem;
      border-radius: 0.5rem;
      box-shadow: $box-shadow;
      color: $primary;
      border: 0.0625rem solid $primary;
      background: $white;
      &:disabled {
        border: 0.0625rem solid $disabled;
        color: $disabled;
      }
    }
  }
}
