@import '../../../../../../assets/styles/theme';
@import '../../../../../../assets/styles/media';

.notification-card {
  display: flex;
  background-color: $bg-color;
  border-bottom: $tech-item-border;
  padding: 1.5rem;
  gap: 1rem;

  &.notification-read {
    background-color: $white;
  }

  .notification-card-icon {
    .icon {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $gray-200;
      border-radius: 50%;
      height: 2.5rem;
      aspect-ratio: 1/1;
    }
  }
  .notification-card-data {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    cursor: pointer;

    .notification-card-header {
      display: flex;
      justify-content: space-between;

      .category-heading {
        color: $notification-heading;
        font-family: $Montserrat;
        font-weight: $weight-600;
        line-height: 1.5rem;
      }

      .notification-time {
        color: $disabled;
        font-size: 0.75rem;
        font-weight: $weight-400;
        line-height: 1.125rem;
      }
    }

    .notification-card-body {
      color: $text-color-2;
      font-weight: $weight-400;
      line-height: 1.5rem;
      .notification-bold {
        font-weight: $weight-600;
      }
    }
  }
}
