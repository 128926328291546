@import '../../../../assets/styles/variable';
.drawer-backdrop {
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;

  .drawer {
    flex: 1;
    position: fixed;
    flex-direction: column;
    display: flex;
    top: 0;
    right: 0;
    width: 100vw;
    max-width: 464px;
    height: 100vh;
    box-sizing: border-box;
    border-radius: $border-radius 0 0 $border-radius;
    background-color: $white;
    box-shadow: $modal-box-shadow;
    overflow: hidden;
    .drawer-header {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      border-bottom: $tech-item-border;

      .drawer-header-content {
        display: flex;
        flex: 1;
        .delete-icon {
          top: 0;
          left: 0;
          line-height: 0;
          padding: 0.75rem;
          &:hover {
            border-radius: 0.5rem;
            background-color: $list-hover-color;
            cursor: pointer;
          }
        }
      }
      .close-icon {
        top: 0;
        right: 0;
        padding: 0.75rem;
        line-height: 0;
        z-index: 2;

        &:hover {
          border-radius: 0.5rem;
          background-color: $list-hover-color;
          cursor: pointer;
        }
      }
    }
    .divider {
      height: 1px;
      margin: 0 1.5rem;
      background-color: $divider-background;
    }
    .drawer-body {
      display: flex;
      height: 100%;
      flex-direction: column;
      overflow: hidden;
    }
    .drawer-footer {
      display: flex;
      flex: 1;
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
      .drawer-divider {
        height: 1px;
        width: calc(100% + 3rem);
        margin-left: -1.5rem;
        background-color: $divider-background;
      }
      .drawer-footer-buttons {
        display: flex;
        width: 100%;
        padding-top: 1.5rem;
        flex-direction: row;
        .cancel-button {
          flex: 1;
          background-color: $white;
          border: $dialog-box-border;
          color: $primary;
          font-size: 1rem;
          height: 3rem !important;
          font-family: $Roboto;
          font-weight: $weight-700;
          margin-right: 1.5rem;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
        }
        .confirm-button {
          flex: 1;
          background-color: $primary;
          border: $dialog-box-border;
          color: $white;
          height: 3rem !important;
          padding-top: 0.75rem;
          padding-bottom: 0.75rem;
          .confirm-button-text {
            color: $white;
            font-size: 1rem;
            font-family: $Roboto;
          }
        }
      }
    }
  }
  .heading {
    font-weight: $weight-600;
    line-height: 2.25rem;
    font-family: $Montserrat;
    color: $gray-700;
    margin-bottom: 1rem;
  }
}
.active {
  width: 100%;
}
