@import '../../../../assets/styles/variable';

.slick-slider {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  &:hover {
    .right-arrow,
    .left-arrow {
      display: flex;
    }
  }

  @media screen and (max-width: 1024px) {
    width: auto !important;
  }
  .slick-list {
    height: fit-content !important;
    width: 100%;
  }
  .slick-arrow,
  .slick-next {
    height: 100% !important;
  }
  .slick-slide {
    width: 100%;

    div {
      width: 100%;
    }
  }
  .right-arrow,
  .left-arrow {
    display: none;
    position: absolute !important;
    align-items: center;
    flex: 1;
    bottom: 16px;
    z-index: 3;
    padding: 0 0.25rem;
    height: 82px;
    width: 32px;
    border: none;
    background-color: $white;
    box-sizing: border-box;
    box-shadow: $chevron-box-shadow;
    border-radius: 0.25rem;
    img {
      width: 24px;
      filter: invert(32%) sepia(56%) saturate(1450%) hue-rotate(191deg) brightness(102%)
        contrast(94%);
    }
    cursor: pointer;

    &:active,
    &:hover {
      background-color: $tags-color;
    }
  }
  .right-arrow {
    right: 0;
  }
  .left-arrow {
    left: 0;
  }

  .arrow-disabled {
    img {
      width: 24px;
      filter: none;
    }
    cursor: not-allowed;
    &:active,
    &:hover {
      background-color: $white;
    }
  }

  .slick-track {
    display: flex;
    flex: 1;
    gap: 0.5rem;
    margin: 1rem 0;
  }

  .slick-list {
    .slick-slider .slick-track,
    .slick-slider .slick-list {
      width: auto !important;
    }
  }
}
