@import '../../../../assets/styles/variable';
.radio-container .radio-custom {
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid $gray-500;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.radio-custom:hover {
  background-color: $tags-color;
  border: 1px solid $primary;
}

.radio {
  position: absolute;
  height: 1.25rem;
  width: 1.25rem;
  appearance: none;
}

.radio-outer-container {
  display: relative;
}

.radio-outer {
  display: flex;
  align-items: center;
  width: max-content;
  cursor: pointer;
  padding: 0.75rem 0 0.75rem 0;
}
.radio-container {
  position: relative;
}

.radio-container input[type='radio']:checked + .radio-custom {
  border: 1px solid $primary;
  background-color: $tags-color;
}

.radio-container input[type='radio']:checked + .radio-custom:after {
  content: '';
  width: 0.625rem;
  height: 0.625rem;
  background-color: $primary;
  border-radius: 20%;
  display: block;
  position: absolute;
  top: 25%;
  left: 25%;
  border-radius: 50%;
}

.radio-label {
  margin-left: 0.5rem;
}
