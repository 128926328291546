@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/media';

.slots--search-result {
  display: flex;
  flex-direction: column;
  flex: 1;
  .table-outer-container {
    min-height: auto;
    margin: 0 1.5rem;
    .table-main-container {
      box-shadow: $slot-level-card-box-shadow;
      .table-sub-container {
        margin-bottom: 0;
      }
    }
  }
  .slots-body {
    display: flex;
    flex: 1;
    gap: 1.5rem;
    margin: 0 1.5rem 1.5rem;
    flex-direction: column;
    @media screen and (max-width: $md-max) {
      margin: 0 1rem 1rem;
    }

    .slot-overview-dropdown {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.5rem;
      height: 3rem;
    }

    .slots-body-header {
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-between;

      @media screen and (max-width: $md-min) {
        flex-direction: column;
        gap: 1rem;
      }

      .button-group {
        margin-bottom: 0;
      }
      .slot-filters {
        display: flex;
        gap: 1rem;
        height: fit-content;

        .date-filter {
          position: relative;

          .slots-filter-badge {
            width: 1rem;
            height: 1rem;
            position: absolute;
            border-radius: 50%;
            background-color: $primary;
            top: -0.5rem;
            left: 2.5rem;
          }
          .slot-date-filter-popover {
            background-color: $white;
            position: absolute;
            right: 0;
            box-shadow: $modal-box-shadow;
            border-radius: 0.5rem;
            width: 26rem;
            z-index: 10;
            margin: 0.5rem;
            box-sizing: border-box;
            margin-right: 0;

            @media screen and (max-width: $sm-max) {
              width: auto;
              right: auto;
              left: -0.5rem;
            }
          }
          @media screen and (max-width: $md-max) {
            flex: 1;
          }
        }

        .date-btn {
          width: 3rem;
          height: 3rem;
          border: 0.0625rem solid $primary;

          img {
            margin-right: 0;
          }
        }
        .tech-select {
          min-width: 9rem;
        }
      }
    }

    .empty {
      height: 100%;
    }
    .slots-tabs {
      display: flex;
      background: $white;
      box-shadow: $slot-level-card-box-shadow;
      border-radius: 0.5rem;
      border: 0.0625rem solid $calendar-gray;
      min-height: max-content;
      max-height: none;
      margin-bottom: 1.5rem;

      .tab-header {
        padding: 1.5rem 1.5rem 0 1.5rem;
      }

      .tab-data {
        overflow-y: hidden;
      }

      .slot-details {
        max-height: 34.375rem;
        overflow-y: scroll;
        width: 100%;

        .slot-loading-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30.625rem;
          z-index: 100;
          border-radius: 0.5rem;
          background-color: $white;

          .loading-spinner {
            width: 3.5rem;
            height: 3.5rem;
            animation: spinner 1.5s linear infinite;
          }
          @keyframes spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        .slots-loading-component {
          height: 100%;
        }

        .no-slots-available {
          display: flex;
          height: calc(100% - 2rem);
          flex-direction: column;
          gap: 0.5rem;
          justify-content: center;
          align-items: center;

          img {
            margin-bottom: 1.5rem;
          }
          .no-slot-message {
            color: $gray-500;
            text-align: center;
            font-weight: $weight-600;
            font-family: $Montserrat;
          }
        }
      }
      .levels {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }

  .single-tag {
    background-color: $level-tag-color;
    padding: 0.375rem 0.5rem;
    color: $primary;
    font-size: 0.75rem;
    font-family: $Roboto;
    font-weight: $weight-600;
    border-radius: 0.5rem;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    line-height: 0.75rem;
    border: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .toast-wrapper {
    .success-toast {
      align-items: center;
    }
    .toast-arrow-right {
      background: none;
      border: none;
    }
  }

  .project {
    color: $project-tag-text-color;
    background-color: $project-tag-bg;
    width: fit-content;
    height: auto;
  }

  .circle {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }

  .approved {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 0.0625rem solid $project-tag-text-color;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    font-weight: $weight-400;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $project-tag-text-color;
    .circle {
      background-color: $project-tag-text-color;
    }
  }

  .in_review {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border: 0.0625rem solid $warning-text;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    font-weight: $weight-400;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $warning;
    .circle {
      background-color: $warning-text;
    }
  }

  .archive {
    box-sizing: border-box;
    border: 0.0625rem solid $error;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    font-weight: $weight-400;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: $error;
    .circle {
      background-color: $error;
    }
  }

  .bench {
    background-color: $warning-bg !important;
    color: $warning-text !important;
    width: max-content;
  }

  .add-slot-drawer {
    height: 100%;

    .date-filter-div {
      height: calc(100% - 1.5rem);
    }
  }

  .panel-header-cell {
    text-align: left;
    padding: 0.75rem 1rem;
    background-color: $white;
    flex-wrap: wrap;
  }

  .add-slot-btn {
    width: 8.625rem;
    height: 3rem;
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .row-cell {
    padding: 1rem 0;
  }

  .cell-profile {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: row;
    padding: 0 1rem;

    @media screen and (max-width: $sm-max) {
      align-items: flex-start;
      flex-direction: column;
      gap: 0.5rem;
    }

    .card-profile {
      display: flex;
      @media screen and (max-width: $sm-max) {
        align-items: center;
      }
    }

    .cell-profile-image {
      width: 4rem;
      height: 4rem;
      border-radius: 50%;
    }

    .no-image {
      display: flex;
      color: $white !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $primary;
      box-sizing: border-box;
    }

    .cell-profile-data {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-left: 1rem;
      gap: 0.25rem;

      .cell-name {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: $weight-600;
        color: $gray-900;
      }

      .cell-designation {
        font-weight: $weight-400;
        color: $gray-500;
      }
    }
  }

  .row-cell-tech-level {
    margin-top: 1rem;
    padding-left: 1rem;
    gap: 0.5rem;
    flex: 1;

    .tech-level {
      display: flex;
      gap: 0.5rem;
      .single-tag {
        background-color: $level-tag-color;
        padding: 0.375rem 0.5rem;
        color: $primary;
        font-size: 0.75rem;
        font-family: $Roboto;
        font-weight: $weight-600;
        border-radius: 0.5rem;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        line-height: 0.75rem;
        border: none;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .project {
        color: $project-tag-text-color;
        background-color: $project-tag-bg;
      }
    }

    .interviews {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .label {
        color: $gray-500;
        font-size: 1rem;
        line-height: 1.5rem;

        span {
          color: $gray-900;
          font-weight: $weight-500;
        }
      }
    }
  }

  .row-cell-tech-stack {
    display: flex;
    flex-direction: row;
    flex: 1;
    flex-wrap: wrap;
    cursor: pointer;
    padding-left: 1.5rem;
    height: 4.625rem;
    align-items: center;

    @media screen and (max-width: $sm-min) {
      height: auto;
    }

    @media screen and (max-width: $sm-max) {
      padding-left: 5rem;
      gap: 0.5rem;
    }

    .tech-tag {
      display: flex;
      background-color: $tags-color;
      padding: 0.375rem 0.5rem;
      color: $primary;
      font-size: 0.75rem;
      font-family: $Roboto;
      font-weight: $weight-600;
      margin-right: 0.25rem;
      border-radius: $border-radius-16;
      justify-content: center;
      align-items: center;
      height: fit-content;
    }
  }

  .slot-details {
    padding-top: 1.5rem;

    .request-flag {
      display: flex;
      gap: 0.5rem;
      top: 1.5rem !important;
      align-items: center;
      padding: 0 1.5rem 1.5rem 1.5rem;

      .yellow-circle {
        background-color: $warning;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
      }
    }
  }
  .slot-date-filter-drawer {
    width: calc(100% - 3rem);
    display: flex;
    border: 0.0625rem solid $calendar-gray;
    box-shadow: $slot-card-hover-box-shadow;
    border-radius: 0.5rem;
    margin: auto;
    margin-top: 0.5rem;
    & > :first-child {
      width: 100%;
    }
  }
  .date-btn-drawer {
    width: calc(100% - 3rem);
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    background: $white;
    border: 0.0625rem solid $gray-500;
    border-radius: 0.5rem;
    justify-content: space-between;
    padding: 0.75rem 0;
    cursor: pointer;
  }
  .date-btn-focused {
    width: calc(100% - 3rem);
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    background: $white;
    border: 0.0625rem solid $primary;
    box-shadow: $date-hover-box-shadow;
    border-radius: 0.5rem;
    justify-content: space-between;
    padding: 0.75rem 0;
    cursor: pointer;
  }
  .placeholder {
    font-family: $Roboto;
    font-style: normal;
    font-weight: $weight-400;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $disabled;
    padding-left: 1rem;
  }
  .date-img {
    padding-right: 1rem;
  }

  .addSlotsSection {
    display: flex;
    align-items: baseline;
    width: 100%;
    border-bottom: 0.0625rem solid $calendar-gray;
  }
  .add-slots-subText {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: calc(100% - 3rem);
    margin: auto;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  .slotsText {
    font-family: $Roboto;
    font-style: normal;
    font-weight: $weight-600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray-900;
    margin-left: 1.125rem;
  }
  .slot-timing_multiple {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    padding: 0 1.5rem;

    .select {
      min-width: 8.187rem;
    }
    .separator {
      width: 0.5rem;
      height: 0.0625rem;
      border-bottom: 0.0625rem solid $black;
    }

    .time {
      flex: 1;
    }
  }
  .addMore {
    cursor: pointer;
  }
}

@media screen and (max-width: $md-max) {
  .slots--search-result {
    .table-outer-container {
      margin: 0 1rem;
    }
  }
}

@media screen and (max-width: $sm-min) {
  .slots--search-result {
    .slot-date-filter-drawer {
      width: calc(100% - 1.5rem);
    }

    .date-btn-focused,
    .date-btn-drawer {
      width: calc(100% - 1.5rem);
    }
  }
}
