@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';

.side-nav {
  display: flex;
  height: 100vh;
  width: 17.5rem;
  border-right: 0.0625rem solid $gray-200;
  width: 17.5rem;
  border-right: 0.0625rem solid $gray-200;
  background-color: $white;
  flex-shrink: 0;
  transition: 0.5s ease;
  .items {
    align-items: center;
  }

  .content {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 0 0.5rem;
    transition: left 0.3s ease-in-out;
    .sidenav-collapseIcon {
      padding: 1.5rem 1rem 0 1rem;
      cursor: pointer;
    }

    .header {
      display: flex;
      justify-content: space-between;
      flex-direction: row;

      .profile-section {
        display: none;

        .profile-section-image {
          position: relative;
          cursor: pointer;

          .nav-user-image {
            width: 2.5rem;
            height: 2.5rem;
            width: 2.5rem;
            height: 2.5rem;
            object-fit: contain;
            object-position: center;
            border-radius: 50%;
          }

          .no-image-wrapper {
            display: flex;
            color: $white !important;
            flex-direction: column;
            align-items: center;
            font-size: 1rem;
            margin-top: 0.75rem;
            justify-content: center;
            background-color: $primary;
          }

          .profile-dropdown {
            position: absolute;
            right: 0;
            height: fit-content;
            padding: 1rem;
            max-width: 20rem;
            width: 12.5rem;
            max-width: 20rem;
            width: 12.5rem;
            background-color: $white;
            border-radius: $border-radius;
            box-shadow: $modal-box-shadow;
            z-index: 200;

            .profile-user-detail {
              align-self: stretch;
              display: flex;
              flex-direction: column;
              padding-block: 0.75rem;
              gap: 0.25rem;
              padding-block: 0.75rem;
              gap: 0.25rem;
              font-family: $Roboto;
            }

            .name {
              font-weight: $weight-600;
              font-size: 1rem;
              color: $text-color-primary;
              text-align: center;
            }

            .caption {
              font-weight: $weight-400;
              font-size: 0.875rem;
              color: $text-color-medium;
              text-align: center;
            }

            .profile-logout {
              display: flex;
              justify-content: center;
              border-radius: $border-radius;
              border: 0.0625rem solid $primary;
              border: 0.0625rem solid $primary;
              cursor: pointer;
              color: $primary;

              &:hover {
                box-shadow: $logout-button-box-shadow;
              }

              .nav-image {
                margin: 0.625rem 0.9375rem;
                filter: invert(39%) sepia(86%) saturate(699%) hue-rotate(182deg) brightness(88%)
                  contrast(97%);
              }

              .nav-text {
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $Roboto;
                font-weight: $weight-500;
                color: $primary;
              }
            }
          }
        }

        @media screen and (max-width: $md-max) {
          display: flex;
          align-items: center;
        }
      }

      .header--content {
        display: flex;
        flex-direction: row;
        padding: 1rem;
        flex: 1;
        cursor: pointer;

        .nav-image {
          height: 2rem;
          height: 2rem;
        }

        .nav-heading {
          display: flex;
          justify-content: center;
          align-items: center;
          font-family: $Montserrat;
          font-weight: $weight-600;
          color: $gray-700;
          font-size: 1rem;

          @media screen and (max-width: $md-min) {
            font-size: 1rem;
          }
        }

        @media screen and (max-width: $md-min) {
          margin: 1rem 0;
        }
      }
    }

    .divider {
      height: 0.0625rem;
      height: 0.0625rem;
      background-color: $divider-background;
      margin: 0 0.5rem;
      margin: 0 0.5rem;

      @media screen and (max-width: $md-max) {
        display: none;
      }
    }

    .nav-user-info {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      gap: 1rem;
      margin-bottom: 1rem;
      gap: 1rem;
      cursor: pointer;

      .nav-user-image {
        display: block;
        width: 2.5rem;
        height: 2.5rem;
        width: 2.5rem;
        height: 2.5rem;
        object-fit: contain;
        object-position: center;
        border-radius: 50%;
      }

      .no-image-wrapper {
        display: flex;
        color: $white !important;
        flex-direction: column;
        align-items: center;
        font-size: 1rem;
        justify-content: center;
        background-color: $primary;
      }
      .nav-user-detail {
        align-self: stretch;
        display: flex;
        flex-direction: column;
        padding-block: 1rem;
        padding-bottom: 0;
        gap: 0.25rem;
        gap: 0.25rem;
        font-family: $Roboto;
        .teckStackDetails {
          display: flex;
          flex-direction: row;
          margin-top: 0.25rem;
          margin-top: 0.25rem;
          overflow: hidden;
          column-gap: 0.5rem;
          .techNameCount-container {
            display: flex;
            justify-content: center;
            height: min-content;
          }
          .techName {
            padding: 0 0.75rem;
            background: $primary-light;
            border-radius: 0.5rem;
            font-family: $Roboto;
            font-style: normal;
            font-weight: $weight-400;
            font-size: $font-size-12px;
            color: $text-color-1;
            border: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            max-width: 4.375rem;
            height: 1.5rem;
            box-sizing: border-box;
          }
          .techNameCounts {
            padding: 0.375rem 0.75rem;
            background: $secondary;
            border-radius: 0.5rem;
            font-family: $Roboto;
            font-style: normal;
            font-weight: $weight-400;
            font-size: $font-size-12px;
            line-height: 0.75rem;
            color: $text-color-1;
            border: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            max-width: max-content;
            height: 1.5rem;
            display: flex;
            align-items: center;
            box-sizing: border-box;
          }
          .tooltip {
            font-family: $Roboto;
            font-style: normal;
            font-weight: $weight-400;
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: $text-color-1;
            background: $white;
            border-radius: 0.5rem;
            padding: 1rem;
            box-shadow: 0 0.25rem 0.25rem $gray-300;
            position: absolute;
            margin-top: -3.125rem;
            margin-top: -3.125rem;
            display: flex;
            justify-content: center;
          }
          .tooltipCount {
            font-family: $Roboto;
            font-style: normal;
            font-weight: $weight-400;
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: $text-color-1;
            background: $white;
            border-radius: 0.5rem;
            padding: 1rem;
            box-shadow: 0 0.25rem 0.25rem $gray-300;
            position: absolute;
            margin-top: -4.375rem !important;
          }
          .tooltip-arrow {
            position: absolute;
            width: 0.75rem;
            height: 0.75rem;
            left: calc(50% - 0.375rem);
            bottom: -0.3125rem;
            left: calc(50% - 0.375rem);
            bottom: -0.3125rem;
            background: $white;
            border-radius: 0.0625rem;
            border-radius: 0.0625rem;
            transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
          }
          .techName + .tooltip {
            display: none;
          }

          .techNameCounts:hover + .tooltipCount {
            font-family: $Roboto;
            font-style: normal;
            font-weight: $weight-400;
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: $text-color-1;
            background: $white;
            border-radius: 0.5rem;
            padding: 0.75rem;
            display: flex;
            justify-content: center;
            flex-direction: column;
            bottom: 9rem;
            max-width: max-content;
            z-index: 1;
          }
          .techName:hover + .tooltip {
            display: block;
            font-style: normal;
            font-weight: $weight-400;
            font-size: 0.75rem;
            line-height: 1.125rem;
            color: $text-color-1;
            background: $white;
            border-radius: 0.5rem;
            padding: 0.5rem;
          }
          .techNameCounts + .tooltipCount {
            display: none;
          }
        }
      }

      .name {
        font-weight: $weight-600;
        font-size: 1rem;
        color: $text-color-primary;
      }

      .caption {
        font-weight: $weight-400;
        font-size: 0.875rem;
        color: $text-color-medium;
      }

      @media screen and (max-width: $md-max) {
        display: none;
      }
    }

    .accounts {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      margin: 0 0.5rem 2rem 0.5rem;
      border-radius: $border-radius;
      border: 0.0625rem solid $primary;
      height: 2.875rem;
      cursor: pointer;
      color: $primary;

      &:hover {
        box-shadow: $logout-button-box-shadow;
      }

      .nav-image {
        margin: 0.625rem 0.9375rem;
        filter: invert(39%) sepia(86%) saturate(699%) hue-rotate(182deg) brightness(88%)
          contrast(97%);
      }

      .nav-text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $Roboto;
        font-weight: $weight-500;
        color: $primary;
      }

      @media screen and (max-width: $md-max) {
        display: none;
      }
    }
    .footer {
      bottom: 0;
      display: flex;
      position: absolute;
      left: 1rem;

      .copyrightLogo {
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-400;
        font-size: 0.75rem;
        line-height: 1.5rem;
        color: $text-color-2;
        display: flex;
        gap: 1rem;
      }
    }
  }

  .dialog-box {
    width: 30rem;
    padding: 1.25rem;
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $modal-box-shadow;

    .heading {
      font-weight: $weight-600;
      line-height: 2.25rem;
      font-family: $Montserrat;
      color: $gray-700;
      margin-bottom: 1rem;
    }

    .dialog-box-content {
      font-weight: $weight-400;
      color: $gray-700;
      line-height: 1.5rem;
      font-family: $Roboto;
      margin-bottom: 1.5rem;
    }

    .dialog-box-footer {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;

      .cancel-button {
        width: 7.5rem;
        background-color: $white;
        border: $dialog-box-border;
        color: $primary;
        font-size: 1rem;
        font-family: $Roboto;
        font-weight: $weight-700;
        margin-right: 1.5rem;
        padding: 0.75rem 1.5rem !important;
      }

      .confirm-button {
        width: 7.5rem;
        background-color: $primary;
        border: $dialog-box-border;
        color: $white;
        padding: 0.75rem 1.5rem !important;

        .confirm-button-text {
          color: $white;
          font-size: 1rem;
          font-family: $Roboto;
        }
      }
    }

    @media screen and (max-width: $smx-min) {
      width: auto;
    }
  }

  @media screen and (max-width: $md-max) {
    display: block;
    height: auto;
    width: auto;
  }
}
.closed {
  width: 5rem;
}
