@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.slot-overview-wrapper {
  flex: 1;
  position: relative;
  height: 574px;
  overflow: scroll;
  background: $white;
  padding: 1.5rem;
  box-sizing: border-box;
  box-shadow: $add-slot-card-box-shadow;
  border-radius: 0.5rem;
  overflow-x: hidden;

  .page {
    height: 100%;
    overflow: scroll;
    .page--headingText {
      font-size: 1rem;
    }

    .page--bodyText {
      font-size: 0.875rem;
    }
  }

  .slots-loader {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    .loading-spinner {
      top: 0;
      left: 0;
      display: flex;
    }
  }

  .slot-overview-title {
    font-family: $Montserrat;
  }

  .slot-overview-dropdown {
    position: absolute;
    top: 0.75rem;
    right: 1.5rem;
  }

  .tech-select {
    min-width: 150px;
    box-shadow: none;
    border: none;
  }

  .slots-tabs {
    margin-bottom: 0 !important;
    height: 100%;
  }

  .slots-body {
    height: 100%;
    .tab-container {
      gap: 1rem;

      .tab-data {
        overflow-y: auto;
        height: calc(100% - 1.5rem);
        box-sizing: border-box;
      }
    }
  }
}

.available-slots-wrapper {
  .slots-body {
    gap: 0;

    .tab-container {
      gap: 1rem;
    }
  }
}
