@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.rewards-widget-wrapper {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  background: $white;
  box-shadow: $card-box-shadow;
  border-radius: 0.5rem;
  padding-top: 1.5rem;
  min-height: 332px;
  position: relative;
  box-sizing: border-box;

  .no-rewards-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 1rem;
    .hint {
      color: $gray-500;
      font-weight: $weight-600;
    }
  }

  .rewards-header {
    font-family: $Montserrat;
    padding: 0 1.5rem;
    padding-bottom: 0.5rem;
  }
  .message {
    color: $gray-500;
    font-size: 0.875rem;
    font-weight: $weight-400;
    line-height: 21px;
    padding: 0 1.5rem;
    span {
      color: $primary;
      font-weight: $weight-600;
    }
  }
  .months-toggle {
    justify-content: center;
    height: 48px;
    .left {
      position: absolute;
      left: 1.5rem;
      width: 48px;
      height: 48px;
    }
    .right {
      position: absolute;
      right: 1.5rem;
      width: 48px;
      height: 48px;
    }
  }
  .rewards-data-wrapper {
    display: flex;
    flex: 1;
    align-items: flex-end;

    @media screen and (max-width: $sm-max) {
      flex-direction: column;
    }

    .rewards-data {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;
      align-items: center;

      .user-description {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        position: relative;
        padding-bottom: 0.5rem;

        .points {
          color: $primary;
          font-weight: $weight-600;
        }
        .user-name {
          font-family: $Montserrat;
          text-align: center;
          padding: 0 0.5rem;
          max-width: 130px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          .tooltip-wrapper {
            display: none;
          }
          .supporting-text {
            text-align: center;
          }

          &:hover {
            cursor: pointer;
            .tooltip-wrapper {
              position: absolute;
              border-radius: 0.5rem;
              bottom: 5.5rem;
              left: 25%;
              background-color: $white;
              z-index: 5;
              color: $gray-900;
              font-weight: $weight-400;
              display: flex;
              flex-direction: column;
              font-family: $Roboto;
              font-size: 0.75rem;
              text-align: left;
              line-height: 1.125rem;
              box-shadow: $modal-box-shadow;
              padding: 0.5rem;

              .tooltip-arrow {
                position: absolute;
                bottom: -6px;
                left: calc(50% - 7px);
                background-color: $white;
                width: 16px;
                height: 16px;
                box-shadow: $tooltip-box-shadow;
                transform: rotate(45deg);
                border: none;
              }
            }
          }
        }
      }
      .first {
        background: $rewards-first-box-shadow;
        padding-bottom: 1rem;
      }

      .second {
        background: $rewards-second-box-shadow;
      }

      .third {
        background: $rewards-third-box-shadow;
      }
      .user-images {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.75rem;

        @media screen and (max-width: $sm-max) {
          padding-top: 0.5rem;
        }

        .position-badge {
          position: absolute;
          left: calc(50% - 12px);
          bottom: -10px;
          z-index: 3;

          &:nth-child(2) {
            bottom: -10px;
          }
        }

        .stacked-images {
          display: flex;

          .profile-image {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            font-size: 1.25rem;
            margin-left: -12px;
            box-shadow: $rewards-profile-box-shadow;

            @media screen and (max-width: $lg-max) {
              width: 50px;
              height: 50px;
            }

            &:first-child {
              margin-left: 0;
              z-index: 3;
              background-color: $white;
            }
            &:nth-child(2) {
              z-index: 2;
            }
          }
          .count {
            font-size: 1rem;
            position: absolute;
            color: $white;
            right: 24px;
            bottom: 28%;
            line-height: 1.5rem;
            z-index: 5;

            @media screen and (max-width: $lg-max) {
              right: 1rem;
              bottom: 22%;
            }

            &:nth-child(2) {
              bottom: 25%;
            }
          }

          .overlay {
            background: $gray-500;
            opacity: 0.7;
            .profile-text {
              display: none;
            }
          }

          .first {
            border: 2px solid $primary;
            padding: 0;
            color: $primary;
          }
          .second {
            border: 2px solid $success;
            color: $success;
          }
          .third {
            border: 2px solid $warning;
            color: $warning;
          }
        }
      }
    }

    .first {
      .profile-image {
        &:nth-child(2) {
          background-color: $white;
        }
      }
      .count {
        bottom: 20% !important;
        @media screen and (max-width: $lg-max) {
          bottom: 14% !important;
        }
      }
    }
    .second {
      border-bottom-left-radius: 0.5rem;
    }
    .third {
      border-bottom-right-radius: 0.5rem;
    }
  }
}
