@import '../../../assets/styles/variable';
@import '../../../assets/styles/media';
@import '../../../assets/styles/theme';

.tech-stack {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .tech-head-wrapper {
    display: flex;
    margin: 1.5rem 1.5rem 0 1.5rem;

    @media screen and (max-width: $md-min) {
      display: block;
    }

    .search-bar-stack {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      height: 3rem;
    }

    .tech-stack-head {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;
      margin-bottom: 2rem;

      @media screen and (max-width: $md-min) {
        margin-bottom: 1rem;
        display: block;
      }

      .tech-searched-heading {
        font-weight: $weight-600;
        font-family: $Montserrat;
        font-size: 1.5rem;
        display: flex;
        gap: 1rem;
        align-items: center;

        .tech-search-msg {
          font-family: $Montserrat;
          color: $disabled-button-color;
        }

        .tech-searched-name {
          font-family: $Montserrat;
          color: $gray-500;
        }

        .tech-clear-top-button {
          border: 0.0625rem solid $primary;
          color: $primary;
        }

        @media screen and (max-width: $lg-max) {
          font-size: 1.25rem;
        }

        @media screen and (max-width: $lg-min) {
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }

      .head-style {
        font-family: $Montserrat;
        font-style: normal;
        font-weight: $weight-600;
        font-size: 2rem;
        line-height: 3rem;
        color: $gray-900;
        @media screen and (max-width: $lg-max) {
          font-size: 1.25rem;
        }
      }
      .btns {
        display: flex;
        align-items: center;
        position: relative;
        .add-sort {
          min-width: 9.187rem;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          .add-btn {
            display: flex;
            background-color: $primary;
            width: 11.813rem;
            padding: 0.75rem 1.5rem;
            height: 3rem;
            box-shadow: $tag-box-shadow;
            color: $white;
            border-radius: $border-radius;
            border: 0.0625rem solid $primary;
            font-size: 1rem;
            @media (max-width: $sm-min) {
              font-size: 0.875rem;
            }
            @media screen and (max-width: $lg-max) {
              padding: 0.25rem 0.75rem;
            }
          }
          .sort-btn-container {
            position: relative;

            .transparent-backdrop {
              width: 100vw;
              height: 100vh;
              z-index: 8;
              position: fixed;
              right: 0;
              top: 0;
              box-shadow: $modal-box-shadow;
              border-radius: $border-radius;
              background-color: transparent;
            }
            .tech-stack-sort-button {
              height: 3rem;
              width: 3rem;
              border: 0.0625rem solid $primary;
              margin: 0 1rem;
              padding: 1rem 0.875rem;
            }
            .active-btn {
              box-shadow: 0 0 0 0.25rem $primary-light;
              background-color: $primary-light;
            }
          }
          .sortBtn {
            width: 3rem;
            height: 3rem;
            background-color: $white;
            border: $border;
            box-shadow: $box-shadow;
            border-radius: $border-radius;
            margin: 0 1rem;
            padding: 1rem 0.875rem;
          }
          .tech-sort {
            position: absolute;
            width: 7.5rem;
            height: 6.25rem;
            background-color: $white;
            border: 0.0625rem solid $gray-300;
            box-shadow: $box-shadow-notification;
            border-radius: 0.5rem;
            top: 3rem;
            left: 12.35rem;
            padding: 1rem;
            .sort-head {
              font-family: $Montserrat;
              font-style: normal;
              font-weight: $weight-600;
              font-size: $textFontSize;
              line-height: 1.5rem;
              color: $black;
            }
            form {
              margin-top: 0.5rem;
              font-family: $Roboto;
              font-style: normal;
              font-weight: $weight-400;
              font-size: $font-size-12px;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  }

  .tech-stack-body {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(22.125rem, 1fr));
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    margin: 0 1.5rem 1.5rem 1.5rem;
    padding-bottom: 1rem;

    @media screen and (max-width: $md-max) {
      row-gap: 1rem;
    }
    @media (max-width: $md-min) {
      grid-template-columns: repeat(auto-fill, minmax(70%, 1fr));
    }
  }

  .tech-stack-body-flex {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .addModal {
    background-color: $white;
    display: flex;
    padding: 1.5rem;
    border-radius: 0.5rem;
    max-height: none;
    flex-direction: column;
    box-sizing: border-box;
    .addModal-content {
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-400;
      font-size: $body1FontSize;
      line-height: 1.5rem;
      color: $gray-700;

      .addModal-img {
        width: 10rem;
        height: 10rem;
        margin: 0 auto;
        border: 0.0625rem solid $gray-200;
        border-radius: 0.5rem;
        margin-bottom: 1.5rem;
        .image-upload-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 1rem;
          flex-direction: column;
          height: 100%;
        }
        .uploaded-image {
          width: 100%;
          height: 100%;
        }
        .add-image {
          border-radius: 0.5rem;
          padding: 0.5rem;
          font-weight: 700;
          font-size: 1rem;
          color: $primary;
          cursor: pointer;
          &:hover {
            background: $img-bg-color;
            text-decoration-line: underline;
          }
        }
        .hidden-input {
          display: none;
        }
      }
      .addModal-techLabel {
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-600;
        font-size: $font-size-14px;
        line-height: 1.25rem;
        color: $gray-900;
      }
      .addTechName {
        width: 100%;
        background: $white;
        border: 0.0625rem solid $gray-500;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 0.75rem 1rem;
        height: 3rem;
        box-sizing: border-box;
        margin-top: 0.5rem;
        font-family: $Roboto;
        font-style: normal;
        color: $gray-900;
        font-weight: $weight-400;
        font-size: 1rem;
        line-height: 1.5rem;
        outline: none;

        &:focus {
          border: 0.0625rem solid $primary;
          box-shadow: $settings-autocomplete-boxShadow;
        }
      }
      .addModal-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1.5rem;
        gap: 1.5rem;
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-700;
        font-size: 1rem;
        line-height: 1.5rem;
        .addModal-cancelBtn,
        .add-modal--save-btn {
          padding: 0.75rem 1.5rem;
          width: 7.5rem;
          height: 3rem;
          color: $white;
          background: $primary;
          border: $primary-border;
          box-shadow: $box-shadow;
          border-radius: $border-radius;
        }
        .addModal-cancelBtn {
          background-color: $white;
          color: $primary;
        }
      }
    }
  }
  .searchSelected {
    display: flex;
  }
}

.ts-sort-wrapper {
  position: absolute;
  right: 1rem;
  top: 3.5rem;
  z-index: 10;
}

@media only screen and (min-width: 64rem) {
  .tech-stack {
    width: 100%;
  }
}

.tech-stack-toast-wrapper {
  @media screen and (max-width: $sm-min) {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    position: absolute;
    top: 1%;
    left: 0;
    min-width: 19.875rem;
    min-height: 4rem;
    background: $white;
    border: 0.0625rem solid $gray-200;
    box-shadow: $box-shadow-notification;
    border-radius: 0.5rem;
  }
}
.error-message {
  color: $error;
  font-size: 0.875rem;
  font-weight: 400;
}
