@import '../../../../../assets/styles/theme';

.ta-user-detail-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  .ta-user-image {
    box-sizing: border-box;
    display: block;
    height: 40px;
    width: 40px;
    object-fit: contain;
    object-position: center;
    border-radius: 200px;
  }
  .ta-user-image-fallback {
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    min-height: 40px;
    min-width: 40px;
    border-radius: 200px;
  }
  .ta-user-detail-name {
    font-family: $Roboto;
    font-size: $font-size-16px;
    font-weight: $weight-600;
    color: $text-color-primary;
  }
}
