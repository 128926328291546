@import '../../../../../assets/styles/theme';

.slot-requested-wrapper {
  box-sizing: border-box;
  min-height: 160px;
  width: 372px;
  max-width: 372px;
  border-radius: 8px;
  background: $white;
  box-shadow: $modal-box-shadow;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 24px;
  .slot-available-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slot-available-icon-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      .icon-wrapper {
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 8px;
        &:hover {
          background-color: $bg-color;
        }
      }
    }
    .slot-available-title {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $text-color-primary;
    }
  }
  .slot-available-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    .slot-timing {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
    }
    .slot-dates {
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-14px;
      font-weight: $weight-400;
    }
    .techStack-tag {
      box-sizing: border-box;
      min-height: auto;
      padding: 6px 8px;
      gap: 8px;
      background: $tags-color;
      border-radius: 8px;
      font-family: $Roboto;
      font-weight: $weight-600;
      font-size: $font-size-12px;
      color: $primary;
      border: none;
      overflow: hidden;
      flex-grow: unset;
      max-width: fit-content;
      .tag-label {
        margin-inline: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    .slot-request-card-detail {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .slot-request-card-raised-wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-basis: 50%;
        .slot-request-card--raisedBy {
          box-sizing: border-box;
          display: block;
          color: $text-color-light;
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-600;
          flex-grow: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .slot-request-card-name-avatar-wrapper {
        box-sizing: border-box;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 11px;
        flex-basis: 50%;
        .slot-request-card-name-wrapper {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          .slot-request-card--name {
            box-sizing: border-box;
            display: block;
            color: $text-color-light;
            font-family: $Roboto;
            font-size: $font-size-16px;
            font-weight: $weight-600;
            flex-grow: 1;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
        .slot-request-card-avatar-wrapper {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          min-height: 40px;
          height: 40px;
          max-height: 40px;
          min-width: 40px;
          width: 40px;
          max-width: 40px;
          border-radius: 50%;
          .slot-request-card--avatar {
            box-sizing: border-box;
            display: block;
            object-position: center;
            object-fit: contain;
            min-width: 100%;
            max-width: 100%;
            width: 100%;
            min-height: 100%;
            max-height: 100%;
            height: 100%;
            border-radius: 50%;
          }
          .slot-request-card--avatar-fallback {
            box-sizing: border-box;
            min-height: 40px;
            height: 40px;
            max-height: 40px;
            min-width: 40px;
            width: 40px;
            max-width: 40px;
            border-radius: 50%;
          }
        }
      }
    }
  }
  .loading-wrapper {
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    .loading-spinner {
      position: static;
    }
  }
}
