@import '../../../assets/styles/variable';
@import '../../../assets/styles/theme';

.splash-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  gap: 16px;
  font-size: 2rem;
  font-weight: $weight-600;
  font-family: $Montserrat;
}
