@import 'assets/styles/variable';
@import 'assets/styles/media';

.tech-level-container {
  padding: 1rem 1.5rem 2rem 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .tech-level-radio-container {
    max-height: 7rem;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .tech-level {
      &.radio-outer-slots {
        flex-direction: row;
        justify-content: flex-start;
        gap: 1rem;
        padding: 0;

        .radio-container {
          margin-right: 0;
          margin-top: 0;

          .radio-custom {
            margin-top: 0;
          }
        }

        .radio-label {
          margin-left: 0;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          .single-tag {
            display: flex;
            background-color: $level-tag-color;
            padding: 0.375rem 0.5rem;
            color: $primary;
            font-size: 0.75rem;
            font-family: $Roboto;
            font-weight: $weight-600;
            border-radius: 0.5rem;
            justify-content: center;
            align-items: center;
            width: fit-content;
            line-height: 0.75rem;
            border: none;
            min-width: auto;
            min-height: auto;
            flex-grow: 0;
          }

          &.single-tech-stack {
            margin: 0;
          }
        }
      }
    }
  }
}
