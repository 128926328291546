@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';

.undo-toast {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-family: $Roboto;

  .toast-undo-btn {
    z-index: 7;
    padding: 0.375rem 0.5rem;
    color: $primary;
    white-space: nowrap;
    font-weight: $weight-600;
    border-radius: 0.25rem;

    &:hover {
      background-color: $tags-color;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
