@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/variable';

.pa-settings-max-limit-parent-wrapper {
  padding: 44px 16px;
}

.pa-settings-max-limit-wrapper {
  display: flex;

  justify-content: space-between;
}

.pa-settings-max-limit-input-wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.pa-settings-max-limit-left {
  display: flex;
  align-items: center;
}

.pa-settings-save-button {
  box-sizing: border-box;
  height: 48px;
  width: 120px;
  border-radius: 8px;
  padding: 12px 24px 12px 24px;
  box-shadow: $box-shadow;
  &:disabled {
    background: $text-color-light;
    border: 1px solid $text-color-light;
  }
}

.pa-settings-input-field {
  border: 1px solid $text-color-medium;
  max-height: 1.875rem;
  max-width: 6.25rem;
  min-height: 1.875rem;
  min-width: 6.25rem;
  border-radius: 0.5rem;
  padding: 8px;
  font-family: $Roboto;
  font-weight: 400;
  font-size: 1rem;
}

.pa-settings-title {
  font-family: $Roboto;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: $text-color-medium;
  margin-right: 1rem;
}

.pa-settings-max-limit-input-wrapper-outer {
  display: flex;
  gap: 24px;
}

.pa-settings-input-label {
  font-family: $Roboto;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #667085;
}

.il-error {
  font-size: 0.875rem;
  font-weight: 400;
  color: $error;
  margin-top: 10px;
}

.weekly,
.daily {
  display: none;
}
.pa-input-wrapper {
  display: flex;
  justify-content: center;
  column-gap: 0.5rem;
  max-height: 48px;
  align-items: center;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  box-sizing: border-box;
  border: 1px solid $text-color-2 !important;
}
.pa-weekly-input-div {
  display: flex;
  align-items: center;
}
.pa-daily-input-div {
  display: flex;
  align-items: center;
}
.pa-input-field {
  padding: 0.5rem;
  font-family: $Roboto;
  font-weight: $weight-400;
  font-size: 1rem;
  max-width: 6.25rem;
}
.separator {
  width: 0px;
  height: 34px;
  border-left: 1px solid #8c93a1;
}
@media screen and (min-width: 320px) and (max-width: 500px) {
  .pa-settings-max-limit-wrapper {
    flex-direction: column;
    row-gap: 1rem;
  }
  .pa-settings-max-limit-left {
    align-items: flex-start;
    flex-direction: column;
    row-gap: 1rem;
  }
  .pa-input-field {
    max-width: 2.25rem;
  }
}
