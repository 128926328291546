@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';
.custom-tooltip {
  box-sizing: border-box;
  width: 153px;
  padding: 0.75rem;
  border: none !important;
  outline: none !important;
  position: relative;
  background-color: $white;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  box-shadow: $modal-box-shadow;
  border-radius: 0.5rem;

  .label {
    font-family: $Roboto;
    font-style: normal;
    display: flex;
    font-weight: $weight-400;
    font-size: 0.75rem;
    line-height: 1.5rem;
    gap: 0.5rem;
    color: $gray-500;
  }

  .tooltip-arrow {
    position: absolute;
    bottom: -6px;
    left: calc(50% - 7px);
    background-color: $white;
    width: 16px;
    height: 16px;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1);
    transform: rotate(45deg);
    border: none;
  }
}

.recharts-tooltip-wrapper {
  outline: none !important;
  z-index: 2;

  &:last-child {
    left: -60px !important;
  }

  &:focus-visible {
    outline: none !important;
  }
}
.chart-body {
  margin-bottom: 1.5rem;
  flex: 1;
  justify-content: flex-end;
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .recharts-wrapper {
    position: absolute !important;
  }

  @media screen and (max-width: 544px) {
    width: 100%;
    margin: 1rem 0;
  }

  .chart-body-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: $Montserrat;
    font-style: normal;
    font-weight: $weight-600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray-700;

    .button {
      box-sizing: border-box;
      width: 30%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 48px;
      background: $white;
      border: 1px solid $gray-500;
      border-radius: 1rem;
    }
  }
  .button-group {
    width: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    align-self: flex-end;
    background: $white;
    border: 1px solid $gray-500;
    border-radius: 1rem;
  }
}

.graph-shimmer {
  display: flex;
  height: calc(100% - 30px);
  margin-left: 1rem;
  justify-content: space-between;
  gap: 2rem;
}
.grid {
  height: 100%;
  justify-content: center;
  align-items: flex-end;
  display: flex;
  gap: 0.5rem;
  grid-template-columns: repeat(3, 150px);
}

.box {
  height: 100%;
  width: 24px;
  max-width: 80px;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
.first {
  height: 100%;
}
.second {
  height: 60%;
}
.third {
  height: 20%;
}
.fourth {
  height: 40%;
}
.shimmerBG {
  animation-duration: 2.2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
  background: $gray-300;
  background: $shimmer-background fixed;
  background-size: 100vw 100%;
}

@keyframes shimmer {
  0% {
    background-position: -100vw 0;
  }

  100% {
    background-position: 100vw 0;
  }
}
