@import '../../../../../assets/styles/theme';

.side-modal-label {
  box-sizing: border-box;
  user-select: none;
  font-family: $Montserrat;
  font-size: 1.5rem;
  font-weight: $weight-600;
  color: $text-color-primary;
}
.modal-drawer-body {
  box-sizing: border-box;
  height: 100%;
  flex-grow: 1;
  overflow: hidden;
}
