@import '../../../../assets/styles/theme';

.slot-drawer-header {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  .header-label {
    box-sizing: border-box;
    font-family: $Montserrat;
    font-size: $font-size-24px;
    font-weight: $weight-600;
    color: $text-color-primary;
  }
  .header-label-count {
    box-sizing: border-box;
    font-family: $Montserrat;
    font-size: $font-size-24px;
    font-weight: $weight-600;
    color: $text-color-light;
  }
}

.slot-request-drawer-body {
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 24px;
  overflow: auto;
  gap: 24px;
  height: 100%;
  .loader-wrapper {
    box-sizing: border-box;
    position: static;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .loading-spinner {
      position: static;
    }
  }
  .slot-request-placeholder {
    box-sizing: border-box;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 5px;
    font-family: $Roboto;
    font-style: normal;
    .no-result-image {
      align-self: center;
      box-sizing: border-box;
      height: 160px;
      max-height: 160px;
      width: 220px;
      max-width: 220px;
    }
    .no-result-text {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-14px;
      font-weight: $weight-600;
      color: $text-color-medium;
    }
  }
}
