@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';

.header {
  font-size: $headerFontSize;
  font-weight: $weight-700;

  @media screen and (max-width: $md-min) {
    font-size: $headerFontSize-md;
  }
}

.sub-header {
  font-size: $subHeaderFontSize;
  font-weight: $weight-600;

  @media screen and (max-width: $md-min) {
    font-size: $subHeaderFontSize-md;
  }
}

.title {
  font-size: $titleFontSize;
  font-weight: $weight-600;
  font-family: $Montserrat;

  @media screen and (max-width: $md-min) {
    font-size: $titleFontSize-md;
  }
}

.body {
  font-size: $bodyFontSize;
  font-weight: $weight-400;

  @media screen and (max-width: $md-min) {
    font-size: $bodyFontSize-md;
  }
}

.body1 {
  font-size: $body1FontSize;
  font-weight: $weight-400;
  font-family: $Roboto;

  @media screen and (max-width: $md-min) {
    font-size: $body1FontSize-md;
  }
}

.text {
  font-size: $textFontSize;
  font-weight: $weight-400;

  @media screen and (max-width: $md-min) {
    font-size: $textFontSize-md;
  }

  @media screen and (max-width: $smx-max) {
    font-size: 0.75rem;
  }
}

.caption {
  font-size: $captionFontSize;
  font-weight: $weight-300;
}

.error {
  font-size: $errorFontSize;
  font-weight: $weight-400;
  color: $error;
}

.headline-32 {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: $font-size-32px;
  line-height: 48px;
  color: $gray-900;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-20px;
    line-height: 30px;
  }
}

.headline-24 {
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: $font-size-24px;
  line-height: 36px;
  color: $gray-900;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-18px;
    line-height: 27px;
  }
}

.headline-16 {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-600;
  font-size: $font-size-16px;
  line-height: 24px;
  color: $gray-900;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-18px;
    line-height: 24px;
  }
}

.subtitle-16 {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: $font-size-14px;
  line-height: 24px;
  color: $gray-900;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-14px;
    line-height: 24px;
  }
}

.subtitle-18 {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: $font-size-18px;
  line-height: 24px;
  color: $gray-500;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-14px;
    line-height: 24px;
  }
}

.supporting-text {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: $font-size-14px;
  line-height: 24px;
  color: $gray-500;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-14px;
    line-height: 21px;
  }
}

.button-text {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-700;
  font-size: $font-size-16px;
  line-height: 24px;
  color: $gray-500;
  letter-spacing: 0.0125em;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-14px;
  }
}

.input-label {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-600;
  font-size: $font-size-14px;
  line-height: 21px;
  color: $gray-900;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-14px;
  }
}

.chip-text-semibold {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-600;
  font-size: $font-size-12px;
  line-height: 18px;
  color: $gray-500;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-10px;
  }
}

.chip-text-semibold {
  font-family: $Roboto;
  font-style: normal;
  font-size: $font-size-12px;
  line-height: 18px;
  color: $gray-500;

  @media screen and (max-width: $md-min) {
    font-size: $font-size-10px;
  }
}
