@import '../../../../../assets/styles/theme';

.slot-available-wrapper {
  box-sizing: border-box;
  width: 372px;
  max-width: 372px;
  border-radius: 8px;
  background: $white;
  box-shadow: $modal-box-shadow;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 16px;
  padding: 24px;
  .slot-available-header {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .slot-available-icon-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      .icon-wrapper {
        box-sizing: border-box;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 8px;
        &:hover {
          background-color: $bg-color;
        }
      }
    }
    .slot-available-title {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $text-color-primary;
    }
  }
  .slot-available-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    .slot-timing {
      box-sizing: border-box;
      font-family: $Montserrat;
      font-size: $font-size-16px;
      font-weight: $weight-600;
    }
    .slot-dates {
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-14px;
      font-weight: $weight-400;
    }
  }
}
