@import '../../../assets/styles/variable';
@import '../../../assets/styles/media';
@import '../../../assets/styles/theme';

.project {
  padding: 1.5rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  .project-head-wrapper {
    display: flex;

    @media screen and (max-width: $md-min) {
      display: block;
    }

    .search-bar {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      height: 3rem;
    }

    .project-stack-head {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: space-between;

      @media screen and (max-width: $md-min) {
        margin-bottom: 1rem;
        display: block;
      }

      .project-searched-heading {
        font-weight: $weight-600;
        font-family: $Montserrat;
        font-size: 1.5rem;
        display: flex;
        gap: 1rem;
        align-items: center;

        .project-search-msg {
          font-family: $Montserrat;
          color: $disabled-button-color;
        }

        .project-searched-name {
          font-family: $Montserrat;
          color: $gray-500;
        }

        .project-clear-top-button {
          border: 0.0625rem solid $primary;
          color: $primary;
        }

        @media screen and (max-width: $lg-max) {
          font-size: 1.25rem;
        }

        @media screen and (max-width: $lg-min) {
          font-size: 1rem;
          margin-bottom: 1rem;
        }
      }

      .head-style {
        font-family: $Montserrat;
        font-style: normal;
        font-weight: $weight-600;
        font-size: 2rem;
        line-height: 3rem;
        color: $gray-900;
        @media screen and (max-width: $lg-max) {
          font-size: 1.25rem;
        }
      }
      .btns {
        display: flex;
        align-items: center;
        position: relative;
        .add-sort {
          min-width: 9.1875rem;
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          .add-btn {
            display: flex;
            background-color: $primary;
            width: auto;
            padding: 0.25rem 1.5rem;
            height: 3rem;
            box-shadow: $tag-box-shadow;
            color: $white;
            border-radius: $border-radius;
            border: 0.0625rem solid $primary;
            font-size: 1rem;
            @media (max-width: $sm-min) {
              font-size: 0.875rem;
            }
            @media screen and (max-width: $lg-max) {
              padding: 0.25rem 0.75rem;
            }
          }
          .sort-btn-container {
            position: relative;

            .transparent-backdrop {
              width: 100vw;
              height: 100vh;
              z-index: 8;
              position: fixed;
              right: 0;
              top: 0;
              box-shadow: $modal-box-shadow;
              border-radius: $border-radius;
              background-color: transparent;
            }
            .project-stack-sort-button {
              height: 3rem;
              width: 3rem;
              border: 0.0625rem solid $primary;
              margin: 0 1rem;
              padding: 1rem 0.875rem;
            }
            .active-btn {
              box-shadow: 0 0 0 0.25rem $primary-light;
              background-color: $primary-light;
            }
          }
          .sortBtn {
            width: 3rem;
            height: 3rem;
            background-color: $white;
            border: $border;
            box-shadow: $box-shadow;
            border-radius: $border-radius;
            margin: 0 1rem;
            padding: 1rem 0.875rem;
          }
          .project-sort {
            position: absolute;
            width: 7.5rem;
            height: 6.25rem;
            background-color: $white;
            border: 0.0625rem solid $gray-300;
            box-shadow: $box-shadow-notification;
            border-radius: 0.5rem;
            top: 3rem;
            left: 12.35rem;
            padding: 1rem;
            .sort-head {
              font-family: $Montserrat;
              font-style: normal;
              font-weight: $weight-600;
              font-size: $textFontSize;
              line-height: 1.5rem;
              color: $black;
            }
            form {
              margin-top: 0.5rem;
              font-family: $Roboto;
              font-style: normal;
              font-weight: $weight-400;
              font-size: $font-size-12px;
              line-height: 1.5rem;
            }
          }
        }
      }
    }
  }
  .project-body {
    margin-top: 2rem;
    display: grid;
    width: 100%;
    grid-template-columns: repeat(auto-fill, minmax(22.125rem, 1fr));
    column-gap: 1.5rem;
    row-gap: 1.5rem;
    padding-bottom: 1rem;
    @media screen and (max-width: $md-max) {
      row-gap: 1rem;
    }
    @media (max-width: $md-min) {
      grid-template-columns: repeat(auto-fill, minmax(70%, 1fr));
    }
  }
  .addModal {
    background-color: $white;
    display: flex;
    padding: 1.5rem;
    border-radius: $border-radius;
    flex-direction: column;
    box-sizing: border-box;
    .addModal-content {
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-400;
      font-size: $body1FontSize;
      line-height: 1.5rem;
      color: $gray-700;

      .addModal-projectLabel {
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-600;
        font-size: $font-size-14px;
        line-height: 1.25rem;
        color: $gray-900;
      }
      .addProjectName {
        width: 100%;
        height: 1.875rem;
        margin-top: 0.5rem;
        background: $white;
        border: 0.0625rem solid $gray-blue;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 0.75rem 1rem;
        height: 3rem;
        box-sizing: border-box;
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-400;
        font-size: 1rem;
        line-height: 1.5rem;
        outline: none;

        &:focus {
          border: 0.0625rem solid $primary;
          box-shadow: $settings-autocomplete-boxShadow;
        }
      }
      .project-error {
        margin-top: 0.5rem;
      }
      .addModal-footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1.5rem;
        gap: 1.5em;
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-700;
        font-size: 1rem;
        line-height: 1.5rem;
        .addModal-cancelBtn,
        .add-modal--save-btn {
          padding: 0.75rem 1.5rem;
          font-weight: $weight-700;
          font-size: 1rem;
          width: 7.5rem;
          height: 3rem;
          color: $white;
          background: $primary;
          box-shadow: $box-shadow;
          border-radius: $border-radius;
        }
        .addModal-cancelBtn {
          background-color: $white;
          color: $primary;
          border: 0.0625rem solid $primary;
        }
      }
    }
  }
  .searchSelected {
    display: flex;
  }
}

.project-toast {
  display: flex;
  align-items: center;
  z-index: 9;
}

.project-toast-undo {
  font-weight: 700;
  font-size: 0.875rem;
  margin-left: 2.625rem;
  color: $primary;
  cursor: pointer;
  padding: 0.5rem;
}

@media only screen and (min-width: 64rem) {
  .project {
    width: 100%;
  }
}

.drawer-body-style {
  display: flex;
  flex: 1;
  flex-direction: column;

  .project-edit-drawer-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    font-family: $Roboto;
    font-style: normal;
    font-weight: $weight-400;
    font-size: $body1FontSize;
    line-height: 1.5rem;
    color: $gray-700;

    .project-edit-drawer-form {
      padding: 1.5rem;
    }
    .delete-drawer-para {
      margin: 0;
    }

    .delete-drawer--tech-label {
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-600;
      font-size: $font-size-14px;
      line-height: 1.25rem;
      color: $gray-900;
    }

    .edit-drawer-footer {
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: $tech-item-border;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      gap: 1.5rem;
      width: 100%;

      .edit-cancel-btn {
        border: none;
        padding-left: 1.5rem;
        padding-right: 0;
      }

      .edit-save-btn {
        border: none;
        padding-right: 1.5rem;
      }

      .edit-drawer--cancel-btn,
      .edit-drawer--save-btn {
        padding: 0.75rem 1.5rem;
        padding: 0.75rem 1.5rem;
        color: $white;
        display: flex;
        flex: 1;
        height: 3rem;
        width: 12.25rem;
        font-size: 1rem;
        font-weight: $weight-700;
        background: $primary;
        border: $primary-border;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
      }

      .edit-drawer--cancel-btn {
        background-color: $white;
        color: $primary;
      }
    }
  }
}

.project-name {
  width: 100%;
  height: 1.875rem;
  margin-top: 0.5rem;
  background: $white;
  border: 0.0625rem solid $gray-blue;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: 0.75rem 1rem;
  height: 3rem;
  box-sizing: border-box;
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 1rem;
  line-height: 1.5rem;
  outline: none;

  &:focus {
    border: 0.0625rem solid $primary;
    box-shadow: $settings-autocomplete-boxShadow;
  }
}

.delete-modal--project-label {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-600;
  font-size: $font-size-14px;
  line-height: 1.25rem;
  color: $gray-900;
}

.project-toast-wrapper {
  z-index: 9;
  @media screen and (max-width: $sm-min) {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    position: absolute;
    top: 1%;
    left: 0;
    min-width: 19.875rem;
    min-height: 4rem;
    background: $white;
    border: 0.0625rem solid $gray-200;
    box-shadow: $box-shadow-notification;
    border-radius: 0.5rem;
  }
}

.project-sort-wrapper {
  position: absolute;
  right: 1rem;
  top: 3.5rem;
  z-index: 10;
}

@media screen and (max-width: $sm-min) {
  .project .project-head-wrapper .project-stack-head .btns .add-sort {
    min-width: 9.1875rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}
.project-input-container {
  padding: 1.5rem;
  .innerWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    row-gap: 1.5rem;
  }
  .drawer-separator {
    border-bottom: 0.063rem solid $separator;
    margin: 1rem 0;
  }
  .project-drawer-sub-detail {
    font-size: 1rem;
    font-weight: $weight-400;
    color: $primary;
    font-family: $Roboto;
    line-height: 1.5rem;

    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
