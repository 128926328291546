@import '../../../../../assets//styles/theme';
@import '../../../../../assets//styles/variable';
.pa-sync-button {
  box-sizing: border-box;
  height: 48px;
  width: 120px;
  border-radius: 8px;
  padding: 12px 24px 12px 24px;
  box-shadow: $box-shadow;
}

.pa-sync-wrapper {
  padding: 1.5rem;
  padding-bottom: 0;
}

.pa-sync-sub-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pa-sync-note {
  font-family: $Roboto;
  font-weight: 400;
  font-size: 1.125rem;
  color: $text-color-medium;
}

.pa-sync-suite {
  font-family: $Roboto;
  font-weight: 400;
  color: $text-color-light;
}

.pa-diconnect-button {
  box-sizing: border-box;
  height: 48px;
  width: 120px;
  border-radius: 8px;
  padding: 12px 24px 12px 24px;
  box-shadow: $box-shadow;
  border: 1px solid $primary;
  color: $primary;
  font-weight: $Roboto;
  font-weight: 600;
  font-size: 1rem;
}
