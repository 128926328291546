@import '../../../../assets/styles/theme';

.selector-container {
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  background: $white;
  border: 0.0625rem solid $calendar-gray;
  box-shadow: $box-shadow;
  border-radius: 0.5rem;
  font-family: $Roboto;
  font-size: $font-size-16px;
  font-weight: $weight-600;
  color: $primary;
  .selector-viewbox {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    flex-grow: 1;
    overflow: hidden;
    border-radius: 0.5rem;
    font: inherit;
    color: inherit;
    .selector-main-viewbox {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      flex-grow: 1;
      gap: 0.5rem;
      align-self: stretch;
      padding-block: 0.5rem;
      padding-left: 1rem;
      padding-right: 0.5rem;
      cursor: pointer;
      overflow: hidden;
      font: inherit;
      color: inherit;
      background-color: inherit;
      .selector-selected-icon-wrapper {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 2rem;
        max-height: 2rem;
        height: 2rem;
        width: 2rem;
        min-width: 2rem;
        max-width: 2rem;
        border-radius: 50%;
        .selector-user-icon {
          box-sizing: border-box;
          display: block;
          object-fit: contain;
          object-position: center;
          min-height: 2rem;
          max-height: 2rem;
          height: 2rem;
          width: 2rem;
          min-width: 2rem;
          max-width: 2rem;
          border-radius: 50%;
        }
        .selector-user-icon--fallback {
          box-sizing: border-box;
          min-height: 2rem;
          max-height: 2rem;
          height: 2rem;
          width: 2rem;
          min-width: 2rem;
          max-width: 2rem;
          border-radius: 50%;
        }
      }
      .selector-text-placeholder {
        box-sizing: border-box;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font: inherit;
        background-color: inherit;
        color: $text-color-medium;
        font-family: $Roboto;
        font-style: normal;
      }
      .selector-text-container {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex-grow: 1;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font: inherit;
        background-color: inherit;
        color: inherit;
        .selector-text {
          box-sizing: border-box;
          display: block;
          width: 100%;
          max-width: 100%;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font: inherit;
          background-color: inherit;
          color: inherit;
        }
      }
    }
    .selector-icon-wrapper {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 2.5rem;
      max-width: 2.5rem;
      width: 2.5rem;
      min-height: 2.5rem;
      background-color: transparent;
      border: none;
      padding: 0;
      align-self: stretch;
      cursor: pointer;
      .selector-icon {
        box-sizing: border-box;
        display: block;
        object-fit: contain;
        object-position: center;
      }
    }
  }
}

.selector-container-focus {
  border-color: $primary;
  box-shadow: $input-box-shadow;
}

.selector-dropdown-container {
  box-sizing: border-box;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 1.75rem;
  top: 120%;
  min-width: 100%;
  background: $white;
  border: 0.0625rem solid $calendar-gray;
  box-shadow: $selector-dropdown-box-shadow;
  border-radius: 0.5rem;
  padding-block: 1rem;
  max-height: 25.5rem;
  .schedule-label-line {
    border: 0.0625rem solid $calendar-gray;
    margin: 0;
    margin-inline: 1.25rem;
  }
  .selector-dropdown-my-schedule-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0.25rem;
    margin-inline: 1.25rem;
    gap: 1rem;
    border-radius: 0.5rem;
    margin-bottom: 0.75rem;
    cursor: pointer;
    &:hover {
      background-color: $secondary;
    }
    .selector-image-container {
      box-sizing: border-box;
      max-height: 3.5rem;
      height: 3.5rem;
      width: 3.5rem;
      max-width: 3.5rem;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      object-fit: contain;
      object-position: center;
    }
    .my-schedule-label {
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      color: $primary;
    }
  }
  .selector-input-field-wrapper {
    box-sizing: border-box;
    min-height: 3rem;
    margin-inline: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border: 0.0625rem solid $calendar-gray;
    border-radius: 0.5rem;
    padding-inline: 1rem;
    .selector-input-field-icons {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      min-width: 1.5rem;
      max-width: 1.5rem;
      width: 1.5rem;
      height: 1.5rem;
      max-height: 1.5rem;
      min-height: 1.5rem;
      background-color: transparent;
      border-width: 0;
      cursor: pointer;
      &:first-child {
        cursor: default;
      }
    }
    .selector-input-field {
      box-sizing: border-box;
      flex-grow: 1;
      width: 100%;
      padding-inline: 1rem;
      height: 1.5rem;
      max-height: 1.5rem;
      min-height: 1.5rem;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-400;
      color: $text-color-primary;
      &::placeholder {
        color: $text-color-medium;
        font-family: $Roboto;
        font-style: normal;
      }
    }
  }
  .selector-input-field-focus {
    border: 0.0625rem solid $primary;
    box-shadow: $input-box-shadow;
  }
  .selector-dropdown-list {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 1rem;
    padding-inline: 1.5rem;
    overflow: auto;
    flex-grow: 1;
    .loading-text {
      align-self: center;
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $font-size-16px;
      font-weight: $weight-600;
      text-align: center;
    }
    .selector-user-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      gap: 1rem;
      border-radius: 0.25rem;
      padding: 0.25rem;
      cursor: pointer;
      &:hover {
        background: $secondary;
      }
      .selector-user-icon-wrapper {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 3.5rem;
        height: 3.5rem;
        border-radius: 50%;
        .selector-user-icon {
          box-sizing: border-box;
          display: block;
          object-fit: contain;
          object-position: center;
          width: 3.5rem;
          height: 3.5rem;
          border-radius: 50%;
        }
      }
      .selector-user-detail-wrapper {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        .selector-user-label {
          box-sizing: border-box;
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-600;
          color: $text-color-primary;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .selector-user-label--secondary {
          box-sizing: border-box;
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-400;
          color: $text-color-medium;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .no-result-container {
    align-self: center;
    box-sizing: border-box;
    display: block;
    width: 100%;
    object-fit: contain;
    object-position: center;
    height: auto;
    overflow: hidden;
    height: 10rem;
    width: 10rem;
    min-width: 10rem;
    max-width: 10rem;
    object-fit: contain;
    object-position: center;
  }
  .no-result-text-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    background-color: $white;
    .no-user-label {
      box-sizing: border-box;
      display: block;
      font-weight: $weight-600;
      font-family: $Roboto;
      font-size: $font-size-16px;
      text-align: center;
      color: $text-color-medium;
    }
  }
  .selector-typing-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2.5rem;
    flex-grow: 1;
    .search-icon {
      box-sizing: border-box;
      display: block;
      width: 5.3125rem;
      height: 5.3125rem;
      object-fit: contain;
      object-position: center;
    }
    .search-text {
      box-sizing: border-box;
      font-family: $Roboto;
      font-weight: $weight-600;
      font-size: $font-size-16px;
      text-align: center;
      color: $text-color-medium;
    }
  }
  .selector-loading-spinner {
    position: static;
    top: 0%;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .selector-loading-icon {
      position: static;
    }
  }
}
.selector-viewbox-active {
  box-shadow: 0 0 0 0.25rem $primary-light;
  background-color: $primary-light;
  border: $primary-border;
}
