@import '../../../../../assets/styles/theme';

.schedule-detail-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  background: $white;
  border: 1px solid $separator;
  box-shadow: $card-box-shadow;
  border-radius: 8px;
  .schedule-detail-label-container {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 16px;
    border-bottom: 1px solid $separator;
    height: 48px;
    overflow: hidden;
  }
  .schedule-detail-header {
    box-sizing: border-box;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow: hidden;
    .schedule-user-detail-wrapper {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 16px;
      gap: 16px;
      flex-grow: 1;
      overflow: hidden;
      .user-image {
        box-sizing: border-box;
        display: block;
        object-fit: contain;
        object-position: center;
        height: 64px;
        min-height: 64px;
        max-height: 64px;
        width: 64px;
        min-width: 64px;
        max-width: 64px;
        border-radius: 50%;
      }
      .fallback-user-image {
        box-sizing: border-box;
        height: 64px;
        min-height: 64px;
        max-height: 64px;
        width: 64px;
        min-width: 64px;
        max-width: 64px;
        border-radius: 50%;
        font-size: $font-size-24px;
      }
      .user-detail-container {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        overflow: hidden;
        .user-detail-status-conatiner {
          box-sizing: border-box;
          display: flex;
          align-items: center;
          gap: 16px;
          .status-container {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            padding-block: 2px;
            padding-inline: 6px;
            gap: 8px;
            border-radius: 8px;
            overflow: hidden;
            .color-indicator {
              box-sizing: border-box;
              border-radius: 50%;
              width: 8px;
              height: 8px;
            }
            .color-indicator-warning {
              background-color: $warning-text;
            }
            .color-indicator-error {
              background-color: $error;
            }
            .status-text {
              box-sizing: border-box;
              font-family: $Roboto;
              font-size: $font-size-12px;
              font-weight: $weight-400;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
            }
            .status-text-warning {
              color: $warning-text;
            }
            .status-text-error {
              color: $error;
            }
          }
          .status-container-warning {
            border: 1px solid $warning-text;
          }
          .status-container-error {
            border: 1px solid $error;
          }
        }
        .schedule-user-name {
          box-sizing: border-box;
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-600;
          line-height: 24px;
          text-align: left;
          color: $text-color-1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .schedule-user-detail-label {
          box-sizing: border-box;
          font-family: $Roboto;
          font-size: $font-size-16px;
          font-weight: $weight-400;
          line-height: 24px;
          text-align: left;
          color: $text-color-2;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .schedule-detail-tag-container {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 16px;
      flex-grow: 1;
      overflow: hidden;
      gap: 8px;
      .techStack-tag {
        box-sizing: border-box;
        min-height: auto;
        flex-grow: initial;
        padding: 6px 8px;
        gap: 8px;
        background: $tags-color;
        border-radius: 8px;
        font-family: $Roboto;
        font-weight: $weight-600;
        font-size: $font-size-12px;
        color: $primary;
        border: none;
        overflow: hidden;
        .tag-label {
          margin-inline: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .project-tag {
        box-sizing: border-box;
        min-height: auto;
        flex-grow: initial;
        padding: 6px 8px;
        gap: 8px;
        background: $project-tag-bg;
        border-radius: 8px;
        font-family: $Roboto;
        font-weight: $weight-600;
        font-size: $font-size-12px;
        color: $success;
        border: none;
        overflow: hidden;
        .tag-label {
          margin-inline: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .project-tag-warning {
        box-sizing: border-box;
        min-height: auto;
        flex-grow: initial;
        padding: 6px 8px;
        gap: 8px;
        background: $warning-bg;
        border-radius: 8px;
        font-family: $Roboto;
        font-weight: $weight-600;
        font-size: $font-size-12px;
        color: $warning-text;
        border: none;
        overflow: hidden;
        .tag-label {
          margin-inline: 0;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .tag-no-ellipsis {
        text-overflow: initial;
        white-space: unset;
        overflow: visible;
      }
    }
    .schedule-detail-interview-limit-container {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 10px;
      padding: 16px;
      flex-grow: 1;
      overflow: hidden;
      .schedule-detail-interview-limit-wrapper {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        gap: 4px;
        .schedule-limit-label {
          box-sizing: border-box;
          font-family: $Roboto;
          font-weight: $weight-400;
          font-size: $font-size-16px;
          line-height: 24px;
          color: $text-color-2;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .limit-count-label {
          box-sizing: border-box;
          font-family: $Roboto;
          font-weight: $weight-500;
          font-size: $font-size-16px;
          line-height: 24px;
          color: $text-color-1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
  }
  .schedule-detail-header-label {
    box-sizing: border-box;
    font-family: $Roboto;
    font-size: $font-size-16px;
    font-weight: $weight-600;
    line-height: 24px;
    text-align: left;
    color: $text-color-2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
