@import '../../../assets/styles/variable';
@import '../../../assets/styles/media';

.level {
  padding: 1.5rem;
  height: 100%;
  width: 100%;
  box-sizing: border-box;

  @media (max-width: $md-min) {
    font-size: 1.5rem;
    padding-bottom: 4.5rem;
  }
  .level-head {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    .head-style {
      font-family: $Montserrat;
      font-style: normal;
      font-weight: $weight-600;
      font-size: 2rem;
      color: $gray-900;

      @media (max-width: $md-min) {
        font-size: 1.5rem;
      }
      @media (max-width: $sm-min) {
        font-size: 1.125rem;
      }
    }
    .btns {
      display: flex;
      align-items: center;
      position: relative;

      .add-sort {
        min-width: 147px;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        .add-btn {
          display: flex;
          background-color: $primary;
          width: auto;
          padding: 0.25rem 1.5rem;
          height: 3rem;
          color: $white;
          border-radius: $border-radius;
          font-size: 1rem;
          font-weight: 700;
          @media (max-width: $sm-min) {
            font-size: 0.875rem;
          }
        }

        form {
          margin-top: 0.5rem;
          font-family: $Roboto;
          font-style: normal;
          font-weight: $weight-400;
          font-size: $font-size-12px;
          line-height: 24px;
        }
      }
    }
  }
}
.level-body {
  margin-top: 2rem;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  column-gap: 1.5rem;
  row-gap: 1.5rem;
  padding-bottom: 1rem;

  @media screen and (max-width: $md-max) {
    row-gap: 1rem;
  }
  @media (max-width: $md-min) {
    grid-template-columns: repeat(auto-fill, minmax(70%, 1fr));
  }
}
.addModal {
  background-color: $white;
  display: flex;
  padding: 1.5rem;
  width: 480px;
  border-radius: $border-radius;
  flex-direction: column;
  box-sizing: border-box;
  margin: 1rem;
  .addModal-content {
    font-family: $Roboto;
    font-style: normal;
    font-weight: $weight-400;
    font-size: $body1FontSize;
    line-height: 24px;
    color: $gray-700;

    .levels-form {
      gap: 1.5rem;
      display: flex;
      flex-direction: column;
      .delete-modal--level-label {
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-600;
        font-size: $font-size-14px;
        line-height: 20px;
        color: $gray-900;
        display: flex;
        margin-bottom: 0.5rem;
      }

      .level-name {
        width: 100%;
        height: 30px;
        background: $white;
        border: $border;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
        padding: 0.75rem 1rem;
        height: 48px;
        box-sizing: border-box;
        font-family: $Roboto;
        font-style: normal;
        font-weight: $weight-400;
        font-size: 1rem;
        line-height: 24px;
        outline: none;

        &:focus {
          border: 1px solid $primary;
          box-shadow: $settings-autocomplete-boxShadow;
        }
      }
      .level-error {
        margin-top: 0.5rem;
      }
    }

    .addModal-footer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-top: 1.5rem;
      gap: 1.5em;
      font-family: $Roboto;
      font-style: normal;
      font-weight: $weight-600;
      font-size: 1rem;
      line-height: 1.5rem;
      .addModal-cancelBtn,
      .add-modal--save-btn {
        padding: 0.75rem 1.5rem;
        font-weight: $weight-600;
        font-size: 1rem;
        width: 120px;
        height: 48px;
        color: $white;
        background: $primary;
        box-shadow: $box-shadow;
        border-radius: $border-radius;
      }
      .addModal-cancelBtn {
        background-color: $white;
        color: $primary;
        border: 1px solid $primary;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .level {
    width: 100%;
  }
}
.error-message {
  color: $error;
  font-size: 0.875rem;
  font-weight: 400;
}
