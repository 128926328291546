@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/variable';

.select-master-check {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.75rem 1rem;
  color: $gray-500;
  font-size: 1rem;
  font-weight: $weight-700;
  font-family: $Roboto;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 9;

  &:hover {
    background: $gray-td-back;
    box-shadow: $select-li-box-shadow;
  }
}
