@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.notifications-card-container {
  max-height: calc(100vh - 7.25rem);
  overflow-y: scroll;
}
.notifications-tabs-container {
  display: flex;
  line-height: 1.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  overflow-y: scroll;
  .tabs {
    font-weight: $weight-600;
    color: $text-color-2;
    font-family: $Montserrat;
    display: flex;
    align-items: center;
    gap: 1.25rem;

    .general,
    .activity {
      padding-bottom: 0.625rem;
      cursor: pointer;
      border-bottom: 0.125rem solid $white;

      &.active-tab {
        color: $primary;
        border-bottom: 0.125rem solid $primary;
      }
    }
  }

  .mark-as-read {
    color: $primary;
    cursor: pointer;
    font-weight: $weight-700;
    font-size: 0.875rem;
    align-self: flex-start;
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    display: inline;

    &:hover {
      text-decoration: underline;
      background-color: $primary-light;
    }
  }
}

.notification-loader {
  position: static;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .loading-spinner {
    position: static;
  }
}
.no-notifications {
  display: flex;
  flex-direction: column;
  font-family: $Montserrat;
  font-style: normal;
  font-weight: $weight-600;
  font-size: 1rem;
  line-height: 1.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: $text-color-2;
  height: 100%;
}
