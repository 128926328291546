@import '../../../assets/styles/variable';
@import '../../../assets/styles/media';
@import '../../../assets/styles/theme';

.slots-page {
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1.5rem;
  box-sizing: border-box;

  .clipped-select-wrapper .primary-select > :first-child,
  .clipped-select-wrapper .secondary-select > :first-child {
    max-width: none;
  }

  .slots--search-result {
    .table-outer-container {
      margin: 0;
    }

    .slots-body {
      margin: 0;
    }
  }

  .slots-header {
    display: flex;
    height: fit-content;
    margin-bottom: 2rem;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    justify-content: space-between;

    .header-container {
      display: flex;
      flex: 1;
      width: 100%;
      align-items: center;
      justify-content: space-between;
      .header-title {
        color: $text-color-1;
        font-family: $Montserrat;
        font-weight: $weight-600;
        font-size: 2rem;
        margin-right: 1rem;

        @media screen and (max-width: $md-min) {
          font-size: 1.5rem;
          display: block;
          margin: 1.5rem 0;
        }
      }
      .slot-btn {
        max-height: 3rem;
        font-weight: $weight-600;
        font-size: 1rem;
        line-height: 1.5rem;
        color: $primary;
        padding: 0.75rem 1.5rem;
        background: $white;
        border: 0.0625rem solid $primary;
        box-shadow: $tag-box-shadow;
      }
    }

    .search-bar {
      display: flex;
      flex-direction: row;
      justify-content: center;

      @media screen and (max-width: $sm-min) {
        width: 100%;
      }
    }
  }

  .slots-body {
    display: flex;
    flex: 1;
    gap: 1.5rem;
    flex-direction: column;

    .slot-overview-dropdown {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 0.5rem;
      height: 3rem;
    }

    .slots-body-header {
      display: flex;
      width: 100%;
      height: fit-content;
      justify-content: space-between;

      @media screen and (max-width: $md-min) {
        flex-direction: column;
        gap: 1rem;
      }

      .button-group {
        margin-bottom: 0;
      }
      .slot-filters {
        display: flex;
        gap: 1rem;
        height: fit-content;

        .date-filter {
          position: relative;

          .slots-filter-badge {
            width: 1rem;
            height: 1rem;
            position: absolute;
            border-radius: 50%;
            background-color: $primary;
            top: -0.5rem;
            left: 2.5rem;
          }
          .slot-date-filter-popover {
            background-color: $white;
            position: absolute;
            right: 0;
            box-shadow: $modal-box-shadow;
            border-radius: 0.5rem;
            width: 26rem;
            z-index: 10;
            margin: 0.5rem;
            box-sizing: border-box;
            margin-right: 0;

            @media screen and (max-width: $sm-max) {
              width: auto;
              right: auto;
              left: -0.5rem;
            }
          }
          @media screen and (max-width: $md-max) {
            flex: 1;
          }
        }

        .date-btn {
          width: 3rem;
          height: 3rem;
          border: 0.0625rem solid $primary;

          img {
            margin-right: 0;
          }
        }
        .tech-select {
          min-width: 9rem;
        }
      }
    }

    .empty {
      height: 100%;
      margin-bottom: 0 !important;
    }
    .slots-tabs {
      display: flex;
      background: $white;
      box-shadow: $slot-level-card-box-shadow;
      border-radius: 0.5rem;
      border: 0.0625rem solid $calendar-gray;
      min-height: max-content;
      max-height: none;
      margin-bottom: 1.5rem;
      position: relative;

      .request-flag {
        display: flex;
        position: absolute;
        top: 4.5rem;
        right: 0;
        gap: 0.5rem;
        align-items: center;
        padding: 0 1.5rem 1.5rem 1.5rem;

        .yellow-circle {
          background-color: $warning;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
        }
      }

      .no-slots-found {
        display: flex;
        height: calc(100% - 2rem);
        flex-direction: column;
        gap: 0.5rem;
        justify-content: center;
        align-items: center;

        img {
          margin-bottom: 1.5rem;
        }
        .no-slot-message {
          color: $gray-500;
          text-align: center;
          font-weight: $weight-600;
          font-family: $Montserrat;
        }
        .slot-btn {
          width: 10rem;
          font-size: 1rem;
        }
      }

      .tab-header {
        padding: 1.5rem 1.5rem 0 1.5rem;
      }

      .tab-data {
        overflow-y: scroll;
        max-height: 42.5rem;
      }

      .slot-details {
        height: 100%;
        width: 100%;

        .request-flag {
          top: 0;
        }

        .slot-loading-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 30.625rem;
          z-index: 100;
          border-radius: 0.5rem;
          background-color: $white;

          .loading-spinner {
            width: 3.5rem;
            height: 3.5rem;
            animation: spinner 1.5s linear infinite;
          }
          @keyframes spinner {
            0% {
              transform: rotate(0deg);
            }
            100% {
              transform: rotate(360deg);
            }
          }
        }

        .slots-loading-component {
          height: 100%;
        }

        .no-slots-available {
          display: flex;
          height: calc(100% - 2rem);
          flex-direction: column;
          gap: 0.5rem;
          justify-content: center;
          align-items: center;

          img {
            margin-bottom: 1.5rem;
          }
          .no-slot-message {
            color: $gray-500;
            text-align: center;
            font-weight: $weight-600;
            font-family: $Montserrat;
          }
        }
      }
      .levels {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }
    }
  }
  .slot-drawer-body {
    .panel-slots-radio-group {
      max-height: none;
    }
  }
  .toast-outer-wrapper {
    min-width: auto;
    top: 1.5rem;
    width: max-content;
    left: calc(50% - 9.375rem);

    .slots-toast {
      min-width: auto;
      width: max-content;
      .success-toast {
        align-items: center;
      }
    }
  }
}

.slot-widget {
  padding: 1.5rem 0 0 0;
  height: calc(100% - 1.5rem);
  box-sizing: border-box;

  @media screen and (max-width: $md-max) {
    padding: 1rem 0 0 0 !important;
  }
}

@media screen and (max-width: $md-max) {
  .slots-page {
    padding: 1rem;
    .slots-body {
      .slot-details {
        min-height: 13.687rem;
      }
    }
    .slots-header {
      margin-bottom: 1.5rem;
    }
  }
}
@media screen and (max-width: $sm-max) {
  .slots-page {
    padding: 1rem;
    .slots-header {
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 1rem;

      .search-bar {
        width: 100%;
      }
    }
    .no-slot-message {
      font-size: 0.875rem;
    }
    .slots-body {
      .slots-tabs {
        .request-flag {
          display: none;
        }
      }
    }

    .multi-slot-wrapper {
      gap: 0.5rem;
      .time {
        min-width: 6.25rem !important;
        .select-content-text-container {
          padding-left: 0.5rem !important;
        }
      }
      .addMore {
        margin-left: 0 !important;
        gap: 0.25rem !important;
      }
    }
  }
}
.tech-toast-undo {
  z-index: 7;
  padding: 0.375rem 0.5rem;
  border-radius: 0.25rem;

  &:hover {
    background-color: $tags-color;
  }
}

.basic {
  width: auto !important;
  min-width: auto !important;
}
.toast-outer-wrapper {
  min-width: auto !important;
  left: auto !important;
  align-self: center;
}
.slots-toast-wrapper {
  width: 28rem;
  justify-content: flex-start;
  z-index: 10;
  @media screen and (max-width: $sm-min) {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    gap: 1rem;
    position: absolute;
    top: 1%;
    left: 0;
    min-width: 19.875rem;
    min-height: 4rem;
    background: $white;
    border: 0.0625rem solid $gray-200;
    box-shadow: $box-shadow-notification;
    border-radius: 0.5rem;
  }
}
.tab-title-count {
  color: $disabled;
}
.slots-button-group {
  width: 8rem;
}
.date-btn-active,
.slot-btn-active {
  box-shadow: 0 0 0 0.25rem $primary-light;
  background-color: $primary-light;
}
.slot-btn-active {
  max-height: 3rem;
  font-weight: $weight-600;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $primary;
  padding: 0.75rem 1.5rem;
  border: 0.0625rem solid $primary;
}
