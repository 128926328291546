@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';
@import '../../../../../assets/styles/theme';

.slot-cancellation-card {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  gap: 1rem;
  width: 33rem;

  .slot-loading-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 24rem;
    z-index: 100;
    border-radius: 0.5rem;
    background-color: $white;

    .loading-spinner {
      width: 3.5rem;
      height: 3.5rem;
      animation: spinner 1.5s linear infinite;
    }
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  .searched {
    height: 20.5rem !important;
  }
  .cancelled {
    height: 31.25rem;
  }

  .slot-cancel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background-color: $bg-color;
    border-radius: 0.5rem 0.5rem 0 0;
    margin-bottom: 1rem;
    gap: 1rem;

    .slot-delete-btn {
      height: 1.5rem;
      padding: 0.5rem;

      &:hover {
        cursor: pointer;
        border-radius: 0.5rem;
        background-color: $list-hover-color;
      }
    }
    .slot-details {
      display: flex;
      gap: 0.25rem;
      align-items: center;
      .date-time {
        display: flex;
        gap: 0.5rem;
        align-items: center;
        font-family: $Montserrat;
        font-weight: $weight-600;
        font-size: $font-size-16px;
        color: $text-color-1;
      }
    }
  }
  .interview-details {
    display: flex;
    padding: 0 1rem 0.5rem 1rem;
    gap: 1.5rem;
    flex-direction: column;

    .candidate-name {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: space-between;

      .slot-cancel-icon-wrapper {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0.5rem;
        color: $warning-text;
        padding: 0.125rem 0.5rem;
        font-size: 0.75rem;
        text-decoration: none;
        background-color: $warning-bg;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    span {
      font-weight: $weight-400;
    }

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: $weight-600;
      font-size: 1rem;
      color: $text-color-2;
    }
  }

  .panel-detail {
    display: flex;
    padding: 1rem;
    gap: 0.5rem;
    flex-direction: column;
    padding-bottom: 0;
    .title {
      color: $gray-500;
      padding-bottom: 0.5rem;
    }
    .info {
      display: flex;
      gap: 1rem;
      align-items: center;

      .panel-image {
        height: 4rem;
        width: 4rem;
        border-radius: 50%;
        font-family: $Roboto;
      }
      .panel-data {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        .detail {
          color: $gray-500;
        }
      }
    }
  }

  .form-field {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 1rem 0 1rem;
    gap: 1rem;

    .text {
      font-weight: $weight-400;
      margin-bottom: 1rem;
    }

    .input-label {
      color: $text-color-2;
      font-size: 1rem;
      line-height: 1.5rem;
    }

    .name-input-field {
      display: flex;
      gap: 0.5rem;
      font-family: $Roboto;
      align-items: center;
    }
    .radio-input-field {
      display: flex;
      gap: 2rem;
      font-family: $Roboto;
      align-items: center;
      .radio-outer {
        .radio-label {
          margin-left: 0.5rem;
        }
      }
    }
  }
  .label {
    margin: 1.5rem 1rem 0 1rem;
    color: $gray-500;
    font-family: $Roboto;
  }
  .book-btn {
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
    .mark-booked-button {
      width: 9.4375rem;
      height: 3rem;
      font-weight: $weight-700;
      letter-spacing: 0.0125rem;
      line-height: 1rem;
      font-size: 0.875rem;
    }
  }
}
.single-tag,
.single-tag-experience {
  display: flex;
  background-color: $level-tag-color;
  padding: 0.375rem 0.75rem;
  color: $text-color-1;
  font-size: 0.75rem;
  font-family: $Roboto;
  font-weight: $weight-400;
  border-radius: 0.5rem;
  justify-content: center;
  align-items: center;
  width: fit-content;
  line-height: 0.75rem;
  border: none;
  min-width: auto;
  height: 1.5rem;
  flex-grow: 0;
  box-sizing: border-box;
}
.name-and-experience {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.grey-Bg {
  background-color: $gray-200;
  color: $text-color-2;
}
.modal-footer {
  display: flex;
  padding-top: 1.5rem;
  justify-content: space-between;
}
.separator-div {
  border-top: 0.0625rem solid $separator;
  margin: 0 1rem 0 1rem;
}
.remark {
  font-size: 0.875rem;
  padding-top: 1rem;
}
.common-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
}
.candidate-details {
  display: flex;
  gap: 1.2rem;
}
.heading {
  font-weight: $weight-400;
  font-size: 0.875rem;
  color: $text-color-2;
  line-height: 1.3125rem;
  font-family: $Roboto;
}
.detail-section {
  font-weight: $weight-600;
  font-size: 1rem;
  color: $text-color-1;
  line-height: 1.5rem;
  font-family: $Roboto;
}
.slot-header-image {
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slot-header-name {
  color: $text-color-2;
  font-size: 0.75rem;
  font-family: $Roboto;
}
.bg-green {
  background-color: $chip-color-green;
}
.bg-red {
  background-color: $chip-color-red;
}
.slot-header-details {
  display: flex;
  flex-direction: column;
  padding-left: 0.75rem;
}
.panel-name {
  color: $primary;
}
