@import '../../../assets/styles/variable';
@import '../../../assets/styles/media';

.page {
  height: 100vh;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: $Roboto;
  width: 100%;

  .page-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 2rem;

    .page-Icon {
      width: 5rem;
      height: 8rem;
    }

    .page--headingText {
      text-align: center;
      letter-spacing: -0.02em;
      font-size: $headerFontSize-md;

      @media (max-width: $md-min) {
        font-size: $font-size-48px;
      }

      @media (max-width: $sm-min) {
        font-size: $titleFontSize-md;
      }
    }
    .page--bodyText {
      text-align: center;
      font-size: $bodyFontSize;
      color: $gray-blue;
      line-height: 1.875rem;

      @media (max-width: $sm-min) {
        font-size: $body1FontSize-md;
      }
    }
  }
  .page-button--base {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;

    .page--backButton {
      background-color: $white;
      border: $backButton-border;
      width: 9.438rem;
      height: 3.5rem;
      padding: 1rem 1.75rem;
      box-sizing: border-box;
      box-shadow: $backButton-box-shadow;

      .backButton-text--style {
        font-weight: $weight-500;
        color: $black-river;
        font-size: 1rem;
        flex: 1;
      }
    }

    .page--refreshButton {
      background-color: $blue;
      border: $refreshButton-border;
      width: 10.125rem;
      height: 3.5rem;
      box-shadow: $refreshButton-box-shadow;
      color: $white;

      .refreshButton-text--style {
        font-weight: $weight-500;
      }
    }
  }
}
