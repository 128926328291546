@import '../../../../assets/styles/variable';

input:disabled {
  background-color: $list-hover-color;
  color: $disabled-button-color;
  cursor: not-allowed !important;
}

input {
  border: none;
  &:focus {
    outline: none;
  }
}
textarea {
  resize: none;
}
.disabled {
  box-shadow: $box-shadow !important;
  color: $disabled-button-color !important;
  border: $input-box-border !important;
  cursor: not-allowed !important;
  background-color: $list-hover-color !important;
}

.input-wrapper {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  border: $input-box-border;
  box-shadow: $box-shadow;
  border-radius: $border-radius;
  padding: 0.75rem 1rem;
  background-color: white;

  .start-icon {
    padding-right: 0.5rem;
  }

  .input-field {
    display: flex;
    flex: 1;
    font-family: $Roboto;
    background-color: inherit;
    line-height: 1.5rem;
    font-size: 1rem;
    padding-left: 0;
    border: none;

    &:focus {
      outline: none;
    }
  }

  .end-icon:hover {
    cursor: pointer;
  }
}

.focus {
  border: 0.0625rem solid $primary;
  box-shadow: $input-box-shadow;
}
