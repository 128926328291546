@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/variable';

.select-clickAway-listener {
  display: flex;
  flex: 1;
}

.react-select {
  &.full-width {
    flex: 1;
  }
}
.option-container {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.75rem 1rem;
  color: $gray-900;
  word-break: break-word;
  &.selected {
    color: $primary;
    font-weight: $weight-500;
    background-color: $secondary;
  }
}

.multi-value-count {
  background-color: $white;
  border: $checkbox-border;
  border-radius: 0.5rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  box-shadow: $tag-box-shadow;
  font-size: 0.875rem;
  max-height: 2rem;
  min-height: 2rem;
}
.css-1697ob3-option {
  background-color: $secondary !important;
}
