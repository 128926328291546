@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/media';

.search {
  width: 20rem;
  height: 3rem;
  position: relative;
  display: flex;
  flex: 1;

  .search-wrapper {
    flex: 1;

    .search-inputs {
      display: flex;
      flex: 1;
      border: 0.0625rem solid $gray-500;
      background-color: $white;
      box-shadow: $box-shadow;
      box-sizing: border-box;
      height: 3rem;
      border-radius: $border-radius;
      padding: 0.75rem 1rem;

      &:focus {
        border: 0.0625rem solid $primary;
        box-shadow: $box-shadow-searchbar;
      }

      input {
        background-color: white;
        border: none;
        flex: 1;
        font-size: 1rem;
        font-family: $Roboto;
        font-weight: $weight-400;
        margin-left: 0.5rem;
        color: $text-color-1;

        &:focus {
          outline: none;
        }
      }

      .search-icon {
        background-color: $white;
        display: flex;
        align-items: center;
      }

      .close-icon {
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
        justify-content: flex-end;
      }
    }

    .dropdown-wrapper {
      z-index: 7;
      background: $white;
      margin-top: 0.5rem;
      max-height: 29.5625rem;
      width: 20rem;
      box-sizing: border-box;
      position: absolute;
      box-shadow: $box-shadow-notification;
      border-radius: $border-radius;

      @media screen and (max-width: $md-min) {
        width: 100%;
        display: flex;
        flex: 1;
        flex-direction: column;
      }

      .dropdown-content {
        max-height: 29.5625rem;
        width: auto;
        overflow-x: hidden;

        .no-results {
          display: flex;
          flex: 1;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-height: 6.25rem;
          width: 16.875rem;
          padding: 1.5rem;

          @media screen and (max-width: $md-min) {
            width: auto;
          }

          .no-results-image {
            width: 10rem;
            height: 10rem;
            border-radius: $border-radius;
            margin-bottom: 1.5rem;
          }

          .name {
            color: $gray-500;
            font-size: 1rem;
            line-height: 1.5rem;
            font-family: $Roboto;
            font-weight: $weight-600;
            text-align: center;
          }

          .search--loading-wrapper {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            width: 100%;
            z-index: 100;
            background-color: $white;

            .search--loading-spinner {
              width: 1.875rem;
              height: 1.875rem;

              animation: spinner 1.5s linear infinite;
            }

            @keyframes spinner {
              0% {
                transform: rotate(0deg);
              }

              100% {
                transform: rotate(360deg);
              }
            }
          }
        }

        .selectedOption {
          width: 20rem;
          background-color: $tags-color !important;
          box-shadow: 0 0.125rem 0.375rem rgba(52, 64, 84, 0.15);
        }

        .options {
          padding: 1rem;
          display: flex;
          width: 20rem;
          width: 100%;
          max-height: 5.5rem;
          flex-direction: row;
          justify-content: space-between;
          font-size: 1rem;
          text-decoration: none;
          box-sizing: border-box;
          transition: 0.35s ease-out;
          cursor: pointer;
          background: transparent;
          border: none;
          text-align: left;

          &:first-child {
            border-radius: 0.5rem 0.5rem 0 0;
          }

          &:hover {
            width: 20rem;
            background-color: $list-hover-color;
            box-shadow: 0 0.125rem 0.375rem rgba(52, 64, 84, 0.15);
            cursor: pointer;

            &:first-child:hover {
              border-radius: 0.5rem 0.5rem 0 0;
            }
          }

          .option-content {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .option-image {
              display: flex;
              flex: 1;
              justify-content: center;
              align-items: center;
            }

            .option-data {
              margin-left: 0.5rem;

              .supporting-data {
                color: $gray-blue;
                font-size: 1rem;
                font-family: $Roboto;
                font-weight: $weight-400;
                margin-top: 0.5rem;
                font-style: normal;
              }

              .name {
                color: $gray-900;
                font-size: 1rem;
                font-family: $Roboto;
                font-weight: $weight-600;
                line-height: 1.5rem;
                text-overflow: ellipsis;
                max-width: 13.5rem;
                white-space: nowrap;
                overflow: hidden;
              }

              .tags {
                display: flex;
                flex-direction: row;
                flex: 1;
                margin-top: 0.5rem;

                .single-tag {
                  display: flex;
                  background-color: $tags-color;
                  padding: 0.375rem 0.5rem;
                  color: $primary;
                  font-size: 0.75rem;
                  font-family: $Roboto;
                  font-weight: $weight-600;
                  margin-right: 0.25rem;
                  border-radius: $border-radius-16;
                  justify-content: center;
                  align-items: center;
                  border: none;
                  min-width: auto;
                  min-height: auto;
                  flex-grow: 0;
                }

                @media screen and (max-width: $lg-max) {
                  flex-wrap: wrap;
                }
              }
            }

            .icon-leading {
              width: 3.5rem;
              height: 3.5rem;
              border-radius: $border-radius;
            }

            .icon-leading-circle {
              width: 3.5rem;
              height: 3.5rem;
              border-radius: 50%;
            }

            .alt {
              background-color: $cool-gray;
              display: flex;
              color: $white !important;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              background-color: $primary;
            }
          }
        }

        @media screen and (max-width: $md-min) {
          width: 100%;
        }

        @media screen and (max-width: $md-min) {
          width: 100%;
        }
      }
    }

    @media screen and (max-width: $md-min) {
      width: auto;
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }
}

::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-thumb {
  background: $tech-bg-color;
  border-radius: 0.5rem;
}
