@import '../../../../../assets/styles/theme';

.table-name-wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 16px;
  .table-name-image {
    box-sizing: border-box;
    object-fit: contain;
    object-position: center;
    height: 40px;
    width: 40px;
    border-radius: 200px;
    min-width: auto;
    min-height: auto;
  }
  .table-name-content {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    .table-user-name {
      box-sizing: border-box;
      font-family: $Roboto;
      font-size: $textFontSize;
      font-weight: $weight-600;
      color: $text-color-primary;
      line-height: 24px;
    }
    .table-user-designation {
      box-sizing: inherit;
      font-family: $Roboto;
      font-size: $textFontSize-md;
      font-weight: $weight-400;
      color: $text-color-medium;
    }
  }
}
