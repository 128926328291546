@import '../../../../../assets/styles/media';
@import '../../../../../assets/styles/theme';
.ta-filter-container {
  width: min-content;
  height: min-content;
  z-index: 4;
  box-shadow: $modal-box-shadow;
  border-radius: $border-radius;
  background-color: $white;
  padding: 1.5rem;
  @media screen and (max-width: 46.875rem) {
    width: 16.5rem;
  }
}

.ta-filter-close {
  cursor: pointer;
  &:hover {
    background: $bg-color;
  }
}

.ta-filter-header {
  font-family: $Montserrat;
  font-weight: $weight-600;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  display: flex;
  min-width: 100%;
  justify-content: space-between;
  color: $text-color-primary;
  z-index: 5;
}

.ta-filters-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-row-gap: 1.5rem;
  grid-column-gap: 1.5rem;
  max-height: 20.5rem;
  width: 26rem;
  max-width: 26rem;
  overflow-y: scroll;
  padding-bottom: 0.75rem;

  @media screen and (max-width: $xs-max) {
    grid-template-columns: 1fr 1fr;
    max-height: 40vh;
    width: 90vw;
    max-width: 90vw;
  }

  @media screen and (max-width: $md-max) {
    grid-template-columns: 1fr 1fr;
    max-height: 40vh;
    min-width: 100%;
  }
  @media screen and (max-width: 46.875rem) {
    grid-template-columns: 1fr;
    max-width: calc(100% - 1.5rem);
  }
}

.ta-filter-option {
  display: flex;
  align-items: center;
  width: max-content;
  gap: 0.5rem;
  cursor: pointer;
  user-select: none;
  position: relative;
}

.ta-filter-option-name {
  max-width: 8.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  .ts-tooltip {
    display: none;
    z-index: 10;
  }
  .ts-tooltip-arrow {
    display: none;
    z-index: 10;
  }
}

.ta-filter-option-name:hover + .ts-tooltip {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $text-color-1;
  background: $white;
  border-radius: 0.5rem;
  padding: 0.75rem;
  box-shadow: $tooltip-new-box-shadow;
  position: absolute;
  bottom: 1.25rem;
  left: 0.9375rem;
  display: flex;
  justify-content: center;

  .ts-tooltip-arrow {
    position: absolute;
    width: 0.75rem;
    height: 0.75rem;
    left: calc(50% - 0.375rem);
    bottom: -0.3125rem;
    background: $white;
    border-radius: 0.0625rem;
    display: flex;
    transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
    box-shadow: 0.125rem 0.125rem 0 $gray-300;
  }
}
