@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/media';

.custom-button {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: $weight-500;
  border-radius: $border-radius;
  border: none;
  box-shadow: 0rem 0.063rem 0.125rem rgba(16, 24, 40, 0.05);
  cursor: pointer;
  font-family: $Roboto;
}

.icon--leading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1rem;
  margin-right: 0.25rem;
  min-width: 1rem;
}

.icon--trailing {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 1rem;
  padding: 0.5rem;
  min-width: 1rem;
}

.button--label {
  display: flex;
  justify-content: center;
  font: inherit;
  color: inherit;
  background: transparent;
  padding: 0.75rem 0;
  white-space: nowrap;
}

.button--2xl {
  width: 10rem;
  height: 4rem;
  font-size: 1rem;
}

.button--xl {
  width: 9rem;
  height: 4rem;
  font-size: 1rem;
}

.button--small {
  width: 6rem;
  height: 2rem;
  font-size: 1rem;
}

.button--medium {
  width: 7rem;
  height: 2.5rem;
  font-size: 1rem;

  @media screen and (max-width: $sm-min) {
    font-size: 0.875rem;
    display: flex;
    flex: 1;
  }
}

.button--large {
  width: 8rem;
  height: 3rem;
  font-size: 1rem;
}

.button--outlined {
  border: $border;
  box-shadow: $box-shadow;
  background-color: $white;

  &:disabled {
    color: $gray-500 !important;
    border: 1px solid $disabled-button-color !important;
    cursor: not-allowed !important;
  }
}

.button--contained {
  background-color: $primary;
  color: $white;

  &:disabled {
    background-color: $disabled-button-color !important;
    border: 1px solid $disabled-button-color !important;
    color: $white !important;
    cursor: not-allowed !important;
  }
}

.button--text {
  border: none;
  color: $black;
  background-color: $white;

  &:disabled {
    color: $disabled-button-color !important;
    cursor: not-allowed !important;
  }
}
