@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/variable';

.user-detail-column {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.user-detail-select {
  box-sizing: border-box;
  height: 21.875rem;
  max-height: none !important;
}

.user-detail-wrapper {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  gap: 2.5rem;
  padding: 2rem 2rem 1rem 2rem;
  min-height: 14.125rem;
  .user-image-container {
    box-sizing: inherit;
    display: flex;
    align-items: flex-start;
    .user-image {
      box-sizing: border-box;
      height: 10rem;
      width: 10rem;
      border-radius: 12.5rem;
      object-fit: contain;
      object-position: center;
      font-size: $font-size-48px;
    }
  }
  .user-fields {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
    column-gap: 2rem;

    .user-common-wrapper {
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;
      flex-grow: 1;
    }

    .user-field-wrapper {
      box-sizing: inherit;
      display: flex;
      align-items: stretch;
      gap: 1.5rem;
    }
    .disabled {
      box-sizing: border-box;
      max-height: 3rem;
      background: $gray-td-back;
      border: 0.0625rem solid $disabled-text-color;
      box-shadow: $box-shadow;
      border-radius: 0.5rem;
      cursor: not-allowed;
    }
  }
}

.user-detail-action-buttons {
  box-sizing: border-box;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  gap: 0.5rem;

  .action-buttons {
    box-sizing: border-box;
    height: 3rem;
    width: 7.5rem;
    border-radius: 0.5rem;
    padding: 0.75rem 1.5rem 0.75rem 1.5rem;
  }
  .action-buttons--cancel {
    background: $white;
    border: 0.0625rem solid $primary;
    box-shadow: $box-shadow;
    color: $primary;
    &:disabled {
      background: $white;
      border: 0.0625rem solid $text-color-light;
      color: $text-color-light !important;
    }
  }
  .action-buttons--save {
    box-shadow: $box-shadow;
    &:disabled {
      background: $text-color-light;
      border: 0.0625rem solid $text-color-light;
    }
  }
}

@media screen and (max-width: 53.125rem) {
  .user-detail-wrapper {
    flex-direction: column;
    .user-image-container {
      align-self: center;
    }
  }
}

@media screen and (max-width: 37.5rem) {
  .user-fields {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

.input-field {
  display: flex;
  font-family: $Roboto;
  background-color: inherit;
  line-height: 1.5rem;
  font-size: 1rem;
  padding-left: 0;
  border: none;
  color: $text-color-primary;
  &:focus {
    outline: none;
  }
}
.settings-column {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;

  .label {
    user-select: none;
    box-sizing: inherit;
    font-family: $Roboto;
    font-size: $font-size-16px;
    font-weight: $weight-400;
    color: $text-color-medium;
    height: 1.5rem;
  }
}
.name-row {
  font-size: $font-size-24px;
  font-family: $Montserrat;
  font-weight: $weight-600;
}
.recruiter-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.techStack-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.5rem;
}
.techStack-action-container {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .techStack-select {
    width: 26.75rem;
  }
}

.settings-techStack {
  position: relative;
  color: $primary;
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.settings-projects {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.techStack-name {
  position: relative;
  border-radius: 0.375rem;
  background-color: $primary-light;
  padding: 0.375rem 0.75rem;
  font-weight: $weight-400;
  font-size: 0.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: max-content;
  white-space: nowrap;
  height: max-content;
  line-height: 0.75rem;
  color: $text-color-1;
  height: 1.5rem;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}
.tooltip {
  font-family: $Roboto;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $text-color-1;
  background: $white;
  border-radius: 0.5rem;
  padding: 1rem;
  box-shadow: 0 0.25rem 0.25rem $gray-300;
  position: absolute;
  margin-top: -4.375rem;
  display: none;
  justify-content: center;
}
.tooltip-arrow {
  position: absolute;
  width: 0.75rem;
  height: 0.75rem;
  left: calc(50% - 0.375rem);
  bottom: -0.3125rem;
  background: $white;
  border-radius: 0.0625rem;
  transform: matrix(0.71, 0.71, -0.71, 0.71, 0, 0);
}
.techStack-name:hover + .tooltip {
  z-index: 1;
  display: flex;
  font-style: normal;
  font-weight: $weight-400;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: $text-color-1;
  background: $white;
  border-radius: 0.5rem;
  padding: 0.5rem;
}
.common-details-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.5rem;
}
.settings,
.projects {
  grid-column: span 3;
}
.project-bg-color {
  background-color: $chip-color-green;
}
.on-bench-settings {
  background-color: $warning-bg;
  font-weight: $weight-400;
  font-size: $font-size-12px;
  width: max-content;
  color: $text-color-1;
  display: flex;
  align-items: center;
  height: 1.5rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.375rem;
  box-sizing: border-box;
}
