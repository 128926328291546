@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';
.pop-up-modal {
  width: 100%;
}
.bottom-navigation {
  display: flex;
  bottom: 0;
  position: relative;
  z-index: 2;
  flex-direction: row;
  background-color: $white;
  justify-content: space-evenly;
  width: 100%;
  box-shadow: $box-shadow;
  @media screen and (min-width: 320px) and (max-width: 767px) {
    position: fixed;;
  }

  .bottom-nav-item {
    display: flex;
    flex: 0 0 33.333333%;
  }

  .link {
    text-decoration: none;
    border-radius: $border-radius-6;

    .bottom-navigation-item {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 10px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .nav-text {
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $Roboto;
        font-weight: $weight-400;
        color: $gray-900;
        text-align: center;
        margin-top: 0.2rem;
      }
    }

    &:hover {
      color: $nav-select-background;
      background-color: $nav-select-background;
    }
  }

  .selected {
    color: $nav-select-background;
    background-color: $nav-select-background;

    img {
      filter: invert(39%) sepia(86%) saturate(699%) hue-rotate(182deg) brightness(88%) contrast(97%);
    }

    .bottom-navigation-item {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 10px;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      .nav-text {
        font-size: 0.875rem;
        display: flex;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $Roboto;
        font-weight: $weight-500;
        color: $primary !important;
        text-align: center;
        margin-top: 0.2rem;
      }
    }
  }
}
