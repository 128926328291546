@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';

.collapsed {
  width: fit-content;
}
.collapsed-link {
  align-self: center;
}
.collapsed-manage-users {
  position: fixed;
  z-index: 9;
  top: 267px;
  left: 60px;
  background: $white;
  border-radius: 0.5rem;
}
.collapsed-item {
  padding-left: 0% !important;
}
.collapsed-mu-section {
  cursor: pointer;
}
.customLink {
  &:hover {
    color: $primary !important;
    background-color: $white !important;
  }
}

.navigation {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  overflow: auto;
  .link {
    text-decoration: none;
    margin-bottom: 0.25rem;

    border-radius: 6px;

    .nav-item {
      display: flex;
      flex: 1;
      cursor: pointer;
      min-height: 44px;
      img {
        margin: 0.625rem 0.9375rem;
      }

      .nav-text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $Roboto;
        font-weight: $weight-400;
        color: $gray-900;
      }
    }

    &:hover {
      color: $nav-select-background;
      background-color: $nav-select-background;
    }
  }

  .settings {
    position: absolute;
    bottom: 0;
    width: 100%;
  }

  .selected {
    color: $nav-select-background;
    background-color: $nav-select-background;
    font-weight: $weight-600;

    img {
      filter: invert(39%) sepia(86%) saturate(699%) hue-rotate(182deg) brightness(88%) contrast(97%);
    }

    .nav-text {
      color: $primary !important;
      font-weight: $weight-600 !important;
    }

    .text {
      font-weight: $weight-600;
    }
  }

  .accordion-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    .accordion-handler--radius {
      border-radius: 8px 8px 0px 0px !important;
    }
    .accordion-handler-wrapper {
      box-sizing: inherit;
      display: flex;
      flex-direction: row;
      background: $white;
      border: none;
      border-radius: 8px;
      padding-inline: 16px;
      min-height: 44px;
      align-items: center;

      .accordion-title {
        user-select: none;
        box-sizing: inherit;
        display: flex;
        align-items: center;
        flex-grow: 1;
        color: $gray-900;
        cursor: pointer;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
      }
      .accordion-arrow-image {
        user-select: none;
        box-sizing: inherit;
        object-position: center;
        object-fit: contain;
        padding-inline: 16px;
        cursor: pointer;
      }
    }
    .accordion-details-wrapper {
      box-sizing: inherit;
      background: $white;
      border-width: 0;
      min-height: 50px;
    }
  }

  @media screen and (max-width: $md-max) {
    display: none;
  }
}

.active-nav-acordian-link {
  display: flex;
  width: 100%;
  color: $primary;
  text-decoration: none;
  background-color: $nav-select-background;
  padding: 0.75rem 0 0.75rem 1rem;
  border-radius: 0.5rem;
}

.nav-acordian-link {
  display: flex;
  width: 100%;
  text-decoration: none;
  color: $gray-900;
  padding: 0.75rem 0 0.75rem 1rem;
  border-radius: 0.5rem;
  &:hover {
    background-color: $nav-select-background;
  }
}

.nav-accordian-title {
  display: flex;
}

.nav-acc-image {
  margin-right: 1rem;
  margin-left: -1px;
}

.nav-acc-link-wrapper {
  padding-left: 21%;
  padding-right: 1rem;
  margin-bottom: 4px;
  margin-top: 4px;
}
