@import '../../../../assets/styles/variable';
@import '../../../../assets/styles/theme';
@import '../../../../assets/styles/media';

.bottom-navigation-popup {
    display: flex;
    bottom: 0;
    margin-bottom: 60px;
    position: absolute;
    flex-wrap: wrap;
    z-index: 2;
    flex-wrap: wrap;
    flex-direction: row;
    background-color: $white;
    justify-content: space-evenly;
    width: 100%;

    .bottom-nav-item {
        display: flex;
        flex: 0 0 33.333333%;
    }

    .link {
        text-decoration: none;
        border-radius: 6px;

        .bottom-navigation-item {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 10px;
            justify-content: center;
            align-items: center;
            cursor: pointer;


            .nav-text {
                font-size: 0.875rem;
                display: flex;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $Roboto;
                font-weight: $weight-500;
                color: $gray-500;
                text-align: center;
                margin-top: 0.2rem;
            }
        }

        &:hover {
            color: $nav-select-background;
            background-color: $nav-select-background;
        }
    }

    .selected {
        color: $nav-select-background;
        background-color: $nav-select-background;

        img {
            filter: invert(39%) sepia(86%) saturate(699%) hue-rotate(182deg) brightness(88%) contrast(97%);
        }

        .bottom-navigation-item {
            display: flex;
            flex: 1;
            flex-direction: column;
            padding: 10px;
            justify-content: center;
            align-items: center;
            cursor: pointer;


            .nav-text {
                font-size: 0.875rem;
                display: flex;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $Roboto;
                font-weight: $weight-500;
                color: $primary !important;
                text-align: center;
                margin-top: 0.2rem;
            }
        }
    }


}