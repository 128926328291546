@import '../../../../../assets/styles/variable';
@import '../../../../../assets/styles/media';

.slot-carousel-card {
  display: flex;
  min-height: 7rem;
  flex-direction: column;
  gap: 0.5rem;

  .day-name {
    font-family: $Montserrat;
    font-weight: $weight-600;
    font-size: 1rem;
    line-height: 1.5rem;
    color: $gray-900;
    padding: 0 1.5rem;

    span {
      line-height: 1.3125rem;
      font-size: 0.875rem;
      margin-left: 0.5rem;
      color: $gray-500;
      font-weight: $weight-400;
      font-family: $Roboto;
    }
  }
  .cardRow {
    flex-direction: column !important;
    align-items: flex-start !important;
  }

  .panelName {
    color: $gray-500;
    letter-spacing: 0.025rem;
    max-width: 6.438rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .card {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    width: max-content;
    min-height: 2.5rem;
    box-sizing: border-box;
    height: 3.5rem;
    padding: 0.5rem;
    justify-content: center;
    background-color: $white;
    border: 0.0625rem solid $primary;
    box-shadow: $slot-card-box-shadow;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    line-height: 1.125rem;
    flex-direction: row;

    &:hover {
      cursor: pointer;
      box-shadow: $slot-card-hover-box-shadow;
    }

    .time {
      letter-spacing: 0.025rem;
      color: $gray-900;
      display: flex;
      align-items: center;
    }

    .name {
      color: $gray-500;
      letter-spacing: 0.025rem;
      max-width: 7.5rem;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    .count-tag {
      letter-spacing: 0.025rem;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      line-height: 1.125rem;
      width: 1.5rem;
      height: 1.5rem;
      box-sizing: border-box;
      border-radius: 50%;
      border: 0.0625rem solid $primary;
    }
  }
  .cardActive {
    box-shadow: $slot-card-hover-box-shadow;
  }
  .card-booked {
    flex-direction: column;
    gap: 0.25rem;
    align-items: flex-start;
    padding: 0.5rem;
    height: 3.5rem;
  }
}
.slot-booking-popover {
  background-color: $white;
  box-shadow: $modal-box-shadow;
  border-radius: 0.5rem;
  z-index: 7;
  box-sizing: border-box;
  margin-right: 0;

  @media screen and (max-width: $sm-min) {
    width: auto;
    transform: none !important;
    bottom: 0;
    right: 0;
  }
  .slot-request-card {
    border: none !important;
    margin: 0 !important;
    font-family: $Roboto;
    display: flex;
    flex-direction: column;

    .slot-request-header {
      padding: 2rem 1.5rem 0 1.5rem;

      .date-time {
        font-family: $Montserrat;
      }
    }

    .slot-btn {
      font-size: 0.875rem;
      letter-spacing: 0.0125rem;
      margin: 1.5rem !important;
    }
  }
}
#confirmation-container {
  z-index: 100;
}
