@import '../../../../../assets/styles/theme';
@import '../../../../../assets/styles/media';

.slot-request {
  flex: 1;
  position: relative;
  height: 574px;
  background: $white;
  padding: 1.5rem;
  box-sizing: border-box;
  box-shadow: $add-slot-card-box-shadow;
  border-radius: 0.5rem;

  button {
    font-weight: $weight-700;
    font-size: $font-size-16px;
    line-height: 24px;
    color: $primary;
  }

  &-title {
    font-family: $Montserrat;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .tech-select {
      min-width: 150px;
      box-shadow: none;
      border: none;
    }
  }

  &-container {
    margin: 1.5rem 0 0 0;
    height: calc(100% - 4.5rem);
    box-sizing: border-box;
    position: relative;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .loading-spinner {
      width: 56px;
      height: 56px;
      animation: spinner 1.5s linear infinite;
      margin: auto;
    }
    @keyframes spinner {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .no-slots-available {
      display: flex;
      height: calc(100% - 32px);
      flex-direction: column;
      gap: 0.5rem;
      justify-content: center;
      align-items: center;

      img {
        margin-bottom: 1.5rem;
      }
      .no-slot-message {
        color: $gray-500;
        text-align: center;
        font-weight: $weight-600;
        font-family: $Roboto;
      }
    }

    .slotrequest-card {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem;
      box-sizing: border-box;
      background: $white;
      border: $button-group-border;
      border-radius: $border-radius;

      .time-details {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        .time-label {
          font-family: $Roboto;
          font-weight: $weight-600;
          font-size: $font-size-16px;
          line-height: 24px;
          color: $gray-900;
        }

        .techstack-label {
          font-family: $Roboto;
          font-weight: $weight-600;
          font-size: $font-size-12px;
          line-height: 18px;
          color: $primary;
          background-color: $secondary;
          border-radius: $border-radius;
          padding: 0.3rem 0.5rem;
          width: fit-content;
        }
      }
    }
  }
}
