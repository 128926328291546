@import '../../../../assets/styles/variable';

.drawer-header-style {
  display: flex;
  flex: 1;
  margin: 1rem;
  justify-content: center;
  align-items: center;
  position: relative;
  padding-bottom: 1rem;

  .drawer-top-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .drawer-top-item {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    .drawer-profile-avatar {
      box-sizing: border-box;
      display: block;
      width: 140px;
      height: 140px;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
      margin-bottom: 1rem;
    }

    .no-image {
      display: flex;
      color: $white !important;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: $primary;
      box-sizing: border-box;
      font-size: 3rem;
      margin: 1rem;
    }

    .drawer-profile-name {
      box-sizing: border-box;
      font-family: $Roboto;
      font-weight: $weight-600;
      color: #101828;
      font-size: 1rem;
    }

    .drawer-profile-text {
      box-sizing: border-box;
      font-family: $Roboto;
      font-weight: $weight-400;
      color: #667085;
      font-size: 1rem;
    }
  }
}
